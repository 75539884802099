import { Trans } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import { Accordion } from 'src/features/JobForm/components/Accordion';
import { Actions } from 'src/features/JobForm/components/Actions';
import { CreateCopy } from 'src/features/JobForm/components/CreateCopy';
import { Flags } from 'src/features/JobForm/components/Flags';
import { RelatedJobs } from 'src/features/JobForm/components/RelatedJobs/RelatedJobs';
import { SaveButton } from 'src/features/JobForm/components/SaveButton';
import { Steps } from 'src/features/JobForm/components/Steps';
import { SubscriptionsList } from 'src/features/JobForm/components/SubscriptionsList';
import { Tasks } from 'src/features/JobForm/components/Tasks';
import { useJobForm } from 'src/features/JobForm/useJobForm';
import { WaveIcon } from 'src/features/WaveIcon';
import { useQueryParams } from 'src/utilities/hooks';

export function JobForm() {
  const {
    age,
    areAllFieldsDisabled,
    canEditJobType,
    fieldLayout,
    handleClickCancel,
    handleSubmit,
    isNewJob,
    jobType,
    visibilityOfSections,
  } = useJobForm();

  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const copyFromJobType = queryParams.get('copyFromJobType');
  const copyFromJobId = queryParams.get('copyFromJobId');

  function handleBackToProject() {
    navigate(`/jobs-job-pro/${copyFromJobId}/sub-jobs`);
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      role="tabpanel"
      sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'space-between',
          m: 2,
        }}
      >
        {!isNewJob ? <Tasks /> : null}

        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexWrap: 'wrap',
            gap: 1,
            justifyContent: 'end',
          }}
        >
          {!isNewJob ? <Flags /> : null}

          {age === 'job' && !isNewJob ? <SubscriptionsList /> : null}

          {jobType !== 'pro' && !isNewJob ? <RelatedJobs /> : null}

          {!isNewJob ? <Steps /> : null}

          {!isNewJob ? <CreateCopy /> : null}

          {!isNewJob ? <Actions /> : null}

          {isNewJob && copyFromJobType === 'pro' ? (
            <Button onClick={handleBackToProject} startIcon={<WaveIcon code="arrow-back" />}>
              <Trans i18nKey="job-back-to-pro">Back to Project</Trans>
            </Button>
          ) : null}

          {isNewJob ? (
            <Button
              color="warning"
              onClick={handleClickCancel}
              startIcon={<WaveIcon code="job-form-action-bar-cancel" />}
            >
              <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
            </Button>
          ) : null}

          {areAllFieldsDisabled || !canEditJobType ? null : <SaveButton />}
        </Box>
      </Box>

      <Box sx={{ overflow: 'auto', paddingBottom: 2, px: 2 }}>
        {
          //   TODO: Two column layout
          fieldLayout?.sections.map((section) =>
            visibilityOfSections[section.code] === 'show' ? (
              <Accordion key={section.code} section={section} />
            ) : null,
          )
        }
      </Box>
    </Box>
  );
}
