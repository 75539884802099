import { Stack } from '@mui/material';

import { Breadcrumbs } from 'src/features/BreadcrumbsBar/components/Breadcrumbs';
import { HeaderSwitch } from 'src/features/BreadcrumbsBar/components/HeaderSwitch';
import { usePreferencePrefix, withFullScreen } from 'src/utilities/hooks';

function BreadcrumbsBar() {
  const { jobType } = usePreferencePrefix();
  const paletteColor = jobType ?? 'primary';

  return (
    <Stack
      alignItems="center"
      bgcolor={`${paletteColor}.main`}
      color={`${paletteColor}.contrastText`}
      direction="row"
      id="breadcrumbs"
      justifyContent="space-between"
      minHeight={(theme) => theme.spacing(5)}
      px={1}
    >
      <Breadcrumbs />

      <HeaderSwitch />
    </Stack>
  );
}

export const VisibilityBreadcrumbsBar = withFullScreen(BreadcrumbsBar, {
  hideInFullScreen: true,
});
