import { LoadingButton } from '@mui/lab';
import { Box, Paper, Skeleton, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { Dropdown } from 'src/components/RHF/Dropdown';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { BreadcrumbsBar } from 'src/features/BreadcrumbsBar';
import { WaveIcon } from 'src/features/WaveIcon';
import { useUserSettings } from 'src/pages/UserSettings/useUserSettings';

const generalSettings = [
  'dalim.viewer',
  'status.redirect',
  'apl.redirect',
  'job.overview',
  'rcv.email',
];
const mainSettings = ['log.url', 'sys.lang', 'sys.timezone'];
const messageSettings = [
  'sys.msg.mt1',
  'sys.msg.mt2',
  'sys.msg.mt4',
  'sys.msg.mt8',
  'sys.msg.mt16',
  'sys.msg.ord',
];
const oneColumnSetting = ['user_settings_main_title', 'user_settings_tooltip_title'];
const tooltipSettings = ['job.btntips', 'job.feldtips', 'job.bartips'];

export function UserSettings() {
  const {
    changedFields,
    checkSettingPreference,
    control,
    fields,
    handleChange,
    handleUpdateUserSettings,
    isGetUserSettings,
    isLoading,
    translate,

    values,
  } = useUserSettings();

  function renderSettingField(setting: string) {
    return (
      <Dropdown
        control={control}
        defaultValue={values && values[setting]?.value}
        disabled={isLoading ? isLoading : fields?.[setting]?.is_disable ?? false}
        label={fields ? fields[setting]?.name : ''}
        name={setting}
        onChange={handleChange}
        options={
          fields
            ? Object.entries(fields[setting]?.field_data || {}).map((data) => ({
                label: data[1],
                value: data[0],
              }))
            : []
        }
        rules={{
          required: {
            message: `${translate('lib.required')}`,
            value: fields?.[setting]?.is_mandatory ?? false,
          },
        }}
      />
    );
  }

  return (
    <>
      <BreadcrumbsBar />

      <Box display="flex" justifyContent="end" m={2}>
        {isGetUserSettings ? (
          <Skeleton height={30} variant="rounded" width={77} />
        ) : (
          <WaveTooltip
            body={!Object.keys(changedFields).length ? 'Nothing to update.' : ''}
            component={
              <LoadingButton
                disabled={!Object.keys(changedFields).length}
                loading={isLoading}
                onClick={handleUpdateUserSettings}
                startIcon={<WaveIcon code="save" />}
                type="submit"
                variant="contained"
              >
                {translate('clip.save')}
              </LoadingButton>
            }
            type="simple"
          />
        )}
      </Box>

      {!isGetUserSettings ? (
        <>
          <Grid alignItems="stretch" container m={2} spacing={2}>
            {oneColumnSetting.map((column) => {
              const settings =
                column === 'user_settings_main_title' ? mainSettings : tooltipSettings;

              return checkSettingPreference(settings) ? (
                <Grid display="flex" key={column} xs>
                  <Paper elevation={3} sx={{ width: '100%' }}>
                    <Typography component="h5" padding={2} variant="h6">
                      {translate(column)}
                    </Typography>

                    {settings.map((setting) =>
                      Object.keys(fields || []).includes(setting) ? (
                        <Grid key={setting} padding={2}>
                          {renderSettingField(setting)}
                        </Grid>
                      ) : null,
                    )}
                  </Paper>
                </Grid>
              ) : null;
            })}

            {checkSettingPreference(messageSettings) ? (
              <Grid display="flex" xs={6}>
                <Paper elevation={3} sx={{ width: '100%' }}>
                  <Typography component="h5" padding={2} variant="h6">
                    {translate('user_settings_message_title')}
                  </Typography>

                  <Grid container spacing={1}>
                    {messageSettings.map((setting) =>
                      Object.keys(fields || []).includes(setting) ? (
                        <Grid key={setting} padding={2} xs={6}>
                          {renderSettingField(setting)}
                        </Grid>
                      ) : null,
                    )}
                  </Grid>
                </Paper>
              </Grid>
            ) : null}
          </Grid>

          {checkSettingPreference(generalSettings) ? (
            <Grid container m={2} spacing={2}>
              <Grid display="flex" xs>
                <Paper elevation={3} sx={{ width: '100%' }}>
                  <Typography component="h5" padding={2} variant="h6">
                    {translate('user_settings_general_title')}
                  </Typography>

                  <Grid container spacing={1}>
                    {generalSettings.map((setting) =>
                      Object.keys(fields || []).includes(setting) ? (
                        <Grid key={setting} padding={2} xs={6}>
                          {renderSettingField(setting)}
                        </Grid>
                      ) : null,
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          ) : null}
        </>
      ) : (
        <>
          <Grid alignItems="stretch" container m={2} spacing={2}>
            {[...Array(2)].map((_, index) => (
              <Grid display="flex" key={index} xs>
                <Skeleton height={300} width="100%" />
              </Grid>
            ))}
          </Grid>

          <Grid container m={2} spacing={2}>
            <Grid display="flex" xs>
              <Skeleton height={200} width="100%" />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
