import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { useUser } from 'src/utilities/hooks';

export function useUserProfile() {
  const {
    palette: {
      primary: { contrastText, main: primary },
    },
  } = useTheme();
  const { t: translate } = useTranslation();

  const userDetails = useUser();
  const { domain, employeeNumber } = userDetails ?? {};

  return { contrastText, domain, employeeNumber, primary, translate, userDetails };
}
