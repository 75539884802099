import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { HistoryTableRow } from 'src/features/History/components/HistoryTableRow';
import { useGetEmailListQuery } from 'src/features/History/History.service';
import { useRouteParams, useUser } from 'src/utilities/hooks';

import { HistoryItemId, HistoryItemType } from 'src/features/History/History.types';

const headerCells = [
  { key: '', label: null },
  { key: 'action', label: null },
  { key: 'action-type', label: 'Action Type' },
  { key: 'date-time', label: 'Date/Time' },
  { key: 'user', label: 'User' },
  { key: 'details', label: 'Details' },
];

export type HistoryTableProps = {
  expandedHistoryItemIds: HistoryItemId[];
  history: HistoryItemType[];
  onToggleExpand: (itemId: HistoryItemId) => void;
};

export function HistoryTable({
  expandedHistoryItemIds,
  history,
  onToggleExpand,
}: HistoryTableProps) {
  const { age, jobId, jobType } = useRouteParams();
  const userId = useUser()?.id;

  const { data: emailListData } = useGetEmailListQuery({
    age,
    id: userId,
    jobid: jobId,
    src: jobType,
  });

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: 2, boxShadow: '0px 5px 15px 1px rgba(0, 0, 0, 0.15)' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {headerCells.map((cell) =>
              !cell.label ? (
                <TableCell key={cell.key} />
              ) : (
                <TableCell key={cell.key}>{cell.label}</TableCell>
              ),
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {history.map((row) => (
            <HistoryTableRow
              emailListData={emailListData?.filter(
                ({ his_id: associatedHistoryId }) => associatedHistoryId == row.id,
              )}
              expandedHistoryItemIds={expandedHistoryItemIds}
              hasEmailList={row.email_list}
              jobType={jobType}
              key={row.id}
              onToggleExpand={onToggleExpand}
              row={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
