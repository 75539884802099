import { createApi } from '@reduxjs/toolkit/query/react';

import { Approval } from 'src/features/Approvals/Approvals.service';
import {
  CreateCopyButton,
  Preview,
  PreviewTransformed,
} from 'src/features/JobForm/JobForm.service';
import {
  SearchVariant,
  ViewType,
  ViewVariant,
} from 'src/features/JobsTable/components/ViewSearchItem/ViewSearchItem';
import {
  transformSubActionsRecursively,
  transformViewType,
} from 'src/features/JobsTable/utilities/helperFunctions';
import {
  Fields,
  FieldsTransformed,
  JobId,
  JobIdBackendRequired,
  JobIdRequired,
  JobIdValue,
} from 'src/pages/Job/Job.service';
import { transformFields } from 'src/pages/Job/utilities/helperFunctions';
import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ActiveFilter } from 'src/utilities/hooks/usePreferences';
import { Age, JobType } from 'src/utilities/hooks/useRouteParams';

type DeleteJobRequest = BaseRequest & JobId;
type DownloadJobsRequest = {
  shouldSendByEmail: boolean;
  fileType: DownloadJobsFileType;
} & BaseRequest &
  DashboardUserId;
type DownloadJobsFileType = 'csv' | 'xlsx';
type GetActionsRequest = {
  isAssignJobTable?: boolean;
} & BaseRequest &
  isSubJobsTable;
type GetCopyJobOptionsRequest = BaseRequest & JobId;
type GetJobsRequest = {
  endpoint: string;
  parentJobId?: JobIdValue;
  parentJobType?: string;
  shouldIncludeArchiveStatus: boolean;
} & BaseRequest &
  DashboardUserId;
type GetSavedViewsRequest = BaseRequest & Required<isSubJobsTable>;
type GetSavedSearchesRequest = GetSavedViewsRequest;
type CreateViewSearchRequest = CreateViewRequest | CreateSearchRequest;
type CreateViewRequest = { variant: ViewVariant['variant'] } & CreateViewSearchBaseRequest;
type CreateSearchRequest = { variant: SearchVariant['variant'] } & Filters &
  CreateViewSearchBaseRequest;
type CreateViewSearchBaseRequest = { viewType: ViewType } & Omit<UpdateViewSearchBaseRequest, 'id'>;
type UpdateViewSearchRequest = UpdateViewRequest | UpdateSearchRequest;
type UpdateViewRequest = { variant: ViewVariant['variant'] } & UpdateViewSearchBaseRequest;
type UpdateSearchRequest = { variant: SearchVariant['variant'] } & Filters &
  UpdateViewSearchBaseRequest;
type UpdateViewSearchBaseRequest = SharedViewSearch & BaseRequest & isSubJobsTable;
type SharedViewSearch = ViewSearchId & ViewSearchName;
type ViewSearchId = { id: number };
export type ViewSearchName = { name: string };
type BaseRequest = Age & JobType;
type DashboardUserId = { dashboardUserId?: number };
type GetFieldSelectorFieldsRequest = {
  type: 'column' | 'search';
} & isSubJobsTable &
  JobType;
type isSubJobsTable = { isSubJobsTable?: boolean };
type DeleteViewSearchRequest = {
  variant: ViewVariant['variant'] | SearchVariant['variant'];
} & ViewSearchId;

export type CreateCopyButtonTransformed = {
  id: Omit<CreateCopyButton['code'], 'new-job'>;
  label: CreateCopyButton['text'];
} & Pick<CreateCopyButton, 'data' | 'subItems'>;
export type ActionTransformed = Omit<Action, 'sub_actions'> & { subActions?: ActionTransformed[] };
export type Action = {
  code: string;
  text: string;
  sub_actions?: Action[];
};
export type JobsTransformed = Pick<Jobs, 'count' | 'deadlines'> & {
  columns: ColumnTransformed[];
  jobIdsWithSubJobs: JobIdsWithSubJobs;
  rows: RowTransformed[];
  tasks: DashboardTasksTransformed;
};
type Jobs = {
  columns: Column[];
  count: number;
  deadlines: Deadlines;
  // TODO: deadlines was an empty array
  // https://hubx.stage.wave30.5flow.net/api/jobs/all?age=job&archive=false&src=art
  rows: Row[];
  sub_jobs: JobIdsWithSubJobs;
  totals?: DashboardTasks;
};
type Column = { field: string; title: string };
export type ColumnTransformed = { fieldAlias: string; fieldName: string };
export type Deadlines = Record<string, Deadline | undefined>;
export type Deadline = Record<string, DeadlineValue | undefined>;
export type DeadlineValue = 'complete' | 'due' | 'late';
export type RowTransformed = Pick<Row, 'apl'> &
  Record<
    string,
    Approval | FlagTransformed[] | PreviewTransformed | Task[] | number | string | undefined | {}
  > & {
    flags?: FlagTransformed[];
    jobType: string;
    preview?: PreviewTransformed;
    status: number;
    tasks?: Task[];
  } & JobIdRequired;
type Row = Record<string, Approval | Flags | Preview | Task | number | string | undefined | {}> & {
  apl?: Approval | {};
  flags?: Flags | {};
  preview?: Preview;
  src: string;
  tasks?: Record<string, Task>;
  webstatus: number;
} & JobIdBackendRequired;
type Flags = Record<'cancel' | 'onhold', Flag>;
export type FlagTransformed = Omit<Flag, 'code'> & { code: 'cancel' | 'onHold' };
type Flag = { code: 'cancel' | 'onhold'; comment: string; name: string; reason: string };
export type Task = { comment: string; id: number; name: string; value: 'active' | 'confirmed' };
export type JobIdsWithSubJobs = number[];
type DashboardTasks = {
  approval_tasks?: number[];
  job_tasks?: number[];
  status_change_tasks?: number[];
};
type DashboardTasksTransformed = {
  approvalTasks?: number[];
  jobTasks?: number[];
  statusChangeTasks?: number[];
};
export type SearchTransformed = Omit<Search, 'ser'> & Filters;
type Search = { ser: ActiveFilter[] } & SharedViewSearch;
type Filters = { filters: ActiveFilter[] };
export type ViewsTransformed = { globalViews: ViewTransformed[]; userViews: ViewTransformed[] };
export type ViewTransformed = Pick<View, 'id' | 'name'> & {
  columnIds: string;
  orderByColumnAlias: string;
  idsOfFilterFields: string;
  rowsPerPage: string;
};
export type Views = { global_views: View[]; user_views: View[] };
export type View = {
  cols: string;
  lpp: number;
  ord: string;
  sfie: string;
} & SharedViewSearch;

export const jobsTableApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    createViewSearch: build.mutation<unknown, CreateViewSearchRequest>({
      invalidatesTags: (result, error) => (error ? [] : ['Searches', 'Views']),
      query(params) {
        const { age, isSubJobsTable, jobType, name, variant } = params;

        return {
          method: 'POST',
          params: {
            age,
            name,
            src: jobType,
            sub_job: isSubJobsTable,
            ...('filters' in params && params.filters.length
              ? { search: params.filters, search_type: 'usr' }
              : { is_global: params.viewType === 'globalView' ? 1 : 0 }),
          },
          url: `/${variant === 'search' ? variant : 'views'}/create`,
        };
      },
    }),
    deleteJob: build.mutation<{ message: string }, DeleteJobRequest>({
      invalidatesTags: (result, error) => (error ? [] : ['Jobs']),
      query({ age, jobId, jobType }) {
        return {
          method: 'DELETE',
          params: { age, jobid: jobId, src: jobType },
          url: '/jobs/delete',
        };
      },
    }),
    deleteViewSearch: build.mutation<unknown, DeleteViewSearchRequest>({
      invalidatesTags: (result, error) => (error ? [] : ['Searches', 'Views']),
      query({ id, variant }) {
        return {
          method: 'DELETE',
          params: {
            ...(variant === 'search' ? { search_id: id } : { view_id: id }),
          },
          url: `/${variant === 'search' ? variant : 'views'}/delete`,
        };
      },
    }),
    downloadJobs: build.query<{ url: string }, DownloadJobsRequest>({
      query({ age, dashboardUserId, fileType, jobType, shouldSendByEmail }) {
        return {
          method: 'GET',
          params: {
            age,
            dashboard_user_id: dashboardUserId,
            send_by_email: shouldSendByEmail ? 1 : 0,
            src: jobType,
            type: fileType,
          },
          url: '/jobs/download',
        };
      },
    }),
    getActions: build.query<ActionTransformed[], GetActionsRequest>({
      providesTags: ['Actions'],
      query({ age, isAssignJobTable, isSubJobsTable, jobType }) {
        return {
          method: 'GET',
          params: { age, assign_job: isAssignJobTable, src: jobType, sub_jobs: isSubJobsTable },
          url: '/jobs/actionbar',
        };
      },
      transformResponse(actions: Action[]) {
        return actions.map((action) => transformSubActionsRecursively(action));
      },
    }),
    getCopyJobOptions: build.query<CreateCopyButtonTransformed[], GetCopyJobOptionsRequest>({
      providesTags: ['Copy'],
      query({ age, jobId, jobType }) {
        return {
          method: 'GET',
          params: { age, jobid: jobId, src: jobType },
          url: '/jobs/actions/new-copy',
        };
      },
      transformResponse(options: CreateCopyButton[]) {
        const transformedOptions = options.reduce((transformedOptions, { code, text, ...rest }) => {
          if (code !== 'new-job') transformedOptions.push({ id: code, label: text, ...rest });

          return transformedOptions;
        }, [] as CreateCopyButtonTransformed[]);

        return transformedOptions;
      },
    }),
    getFieldSelectorFields: build.query<FieldsTransformed, GetFieldSelectorFieldsRequest>({
      query({ isSubJobsTable, jobType, type }) {
        return {
          method: 'GET',
          params: {
            filter: type,
            load_data: type === 'search' ? 1 : 0,
            src: jobType,
            sub_jobs: isSubJobsTable,
          },
          url: '/fields/all',
        };
      },
      transformResponse(fields: Fields) {
        return transformFields(fields);
      },
    }),
    getJobs: build.query<JobsTransformed, GetJobsRequest>({
      providesTags: ['Jobs'],
      query({
        age,
        dashboardUserId,
        endpoint,
        jobType,
        parentJobId,
        parentJobType,
        shouldIncludeArchiveStatus,
      }) {
        return {
          method: 'GET',
          params: {
            age,
            archive: shouldIncludeArchiveStatus,
            assign_jobid: parentJobId,
            assign_src: parentJobType,
            dashboard_user_id: dashboardUserId,
            src: jobType,
          },
          url: endpoint,
        };
      },
      transformResponse({
        columns,
        count,
        deadlines,
        rows,
        sub_jobs: jobIdsWithSubJobs,
        totals: tasks,
      }: Jobs) {
        const columnsTransformed = columns.map(({ field: fieldAlias, title: fieldName }) => ({
          fieldAlias,
          fieldName,
        }));
        const rowsTransformed = rows.map(
          ({
            apl,
            flags,
            jobid: jobId,
            preview,
            src: jobType,
            tasks,
            webstatus: status,
            ...rest
          }) => {
            const flagsTransformed = flags
              ? Object.values(flags).map(({ code, ...rest }) => ({
                  code: code === 'onhold' ? ('onHold' as const) : code,
                  ...rest,
                }))
              : undefined;
            const previewTransformed = preview
              ? { fileId: preview.file_id, sub: preview?.sub, url: preview.url }
              : undefined;
            const tasksTransformed = tasks ? Object.values(tasks) : undefined;

            return {
              apl,
              jobId,
              jobType,
              status,
              ...(flagsTransformed && { flags: flagsTransformed }),
              ...(previewTransformed && { preview: previewTransformed }),
              ...(tasksTransformed && { tasks: tasksTransformed }),
              ...rest,
            };
          },
        );

        const approvalTasks = tasks?.approval_tasks;
        const jobTasks = tasks?.job_tasks;
        const statusChangeTasks = tasks?.status_change_tasks;
        const tasksTransformed = { approvalTasks, jobTasks, statusChangeTasks };

        return {
          columns: columnsTransformed,
          count,
          deadlines,
          jobIdsWithSubJobs,
          rows: rowsTransformed,
          tasks: tasksTransformed,
        };
      },
    }),
    getSavedSearches: build.query<SearchTransformed[], GetSavedSearchesRequest>({
      providesTags: ['Searches'],
      query({ age, isSubJobsTable, jobType }) {
        return {
          method: 'GET',
          params: {
            age,
            src: jobType,
            sub_job: isSubJobsTable,
          },
          url: '/search/get',
        };
      },
      transformResponse(searches: Search[]) {
        return searches.map(({ id, name, ser: filters }) => ({ filters, id, name }));
      },
    }),
    getSavedViews: build.query<ViewsTransformed, GetSavedViewsRequest>({
      providesTags: ['Views'],
      query({ age, isSubJobsTable, jobType }) {
        return {
          method: 'GET',
          params: {
            age,
            src: jobType,
            sub_job: isSubJobsTable,
          },
          url: '/views/get',
        };
      },
      transformResponse({ global_views: globalViews, user_views: userViews }: Views) {
        return {
          globalViews: transformViewType(globalViews),
          userViews: transformViewType(userViews),
        };
      },
    }),
    updateViewSearch: build.mutation<unknown, UpdateViewSearchRequest>({
      invalidatesTags: (result, error) => (error ? [] : ['Searches', 'Views']),
      query(params) {
        const { age, id, isSubJobsTable, jobType, name, variant } = params;

        return {
          method: 'PUT',
          params: {
            age,
            name,
            src: jobType,
            sub_job: isSubJobsTable,
            ...(variant === 'search'
              ? { search: params.filters, search_id: id, search_type: 'usr' }
              : { view_id: id }),
          },
          url: `/${variant === 'search' ? variant : 'views'}/update`,
        };
      },
    }),
  }),
  reducerPath: 'jobsTableApi',
  tagTypes: ['Actions', 'Copy', 'Jobs', 'Searches', 'Views'],
});

export const {
  useCreateViewSearchMutation,
  useDeleteJobMutation,
  useDeleteViewSearchMutation,
  useGetActionsQuery,
  useGetCopyJobOptionsQuery,
  useGetFieldSelectorFieldsQuery,
  useGetJobsQuery,
  useGetSavedSearchesQuery,
  useGetSavedViewsQuery,
  useLazyDownloadJobsQuery,
  useUpdateViewSearchMutation,
} = jobsTableApi;
