import { Location } from 'react-router-dom';

import {
  DEFAULT_CODE,
  DEFAULT_LINK,
  DEFAULT_SUB_ITEMS,
} from 'src/features/BreadcrumbsBar/components/Breadcrumbs/utilities/constants';
import { MenuItemTransformed } from 'src/store/user.service';

type LocationState = {
  code: string;
  name: string;
};
export type ExtendedLocation = Location & { state: LocationState };

export function extractBreadcrumbs(
  menu: MenuItemTransformed[] | undefined,
  location: ExtendedLocation,
): MenuItemTransformed[] {
  const urlSegments = segmentUrl(location);

  const breadcrumbs = urlSegments.reduce<MenuItemTransformed[]>(
    (breadcrumbs, urlSegment, index) => {
      if (index === 0) {
        const menuItem = extractFirstBreadcrumb(menu, urlSegment, location);

        breadcrumbs.push(menuItem);
      }

      if (index === 1) {
        const menuItem = extractSecondBreadcrumb(urlSegment, urlSegments[0]);

        breadcrumbs.push(menuItem);
      }

      const menuItem = extractThirdBreadcrumb(urlSegment);

      if (menuItem) breadcrumbs.push(menuItem);

      return breadcrumbs;
    },
    [],
  );

  return breadcrumbs;
}

function segmentUrl(location: ExtendedLocation) {
  return location.pathname.substring(1).split('/');
}

// handles first menuitem from menu items from the backend or location for static
// frontend menu items
function extractFirstBreadcrumb(
  menu: MenuItemTransformed[] | undefined,
  urlSegment: string,
  location: ExtendedLocation,
): MenuItemTransformed {
  const menuItem = findMenuItem(menu, urlSegment);

  if (menuItem) {
    const { code, link, name, subItems } = menuItem;

    return { code, link, name, subItems };
  }

  return {
    code: location.state.code,
    link: location.pathname,
    name: location.state.name,
    subItems: [],
  };
}

// handles job number
function extractSecondBreadcrumb(urlSegment: string, firstUrlSegment: string): MenuItemTransformed {
  if (urlSegment === 'new') {
    return {
      code: 'lib.new',
      link: DEFAULT_LINK,
      name: 'New',
      subItems: DEFAULT_SUB_ITEMS,
    };
  }

  return {
    code: DEFAULT_CODE,
    link: `/${firstUrlSegment}/${urlSegment}`,
    name: urlSegment,
    subItems: DEFAULT_SUB_ITEMS,
  };
}

// Handles compare and assign views
function extractThirdBreadcrumb(urlSegment: string): MenuItemTransformed | undefined {
  const specialMenuItems: MenuItemTransformed[] = [
    { code: 'breadcrumb_assign', link: 'assign', name: 'Assign', subItems: DEFAULT_SUB_ITEMS },
    { code: 'breadcrumb_compare', link: 'compare', name: 'Compare', subItems: DEFAULT_SUB_ITEMS },
  ];
  const menuItem = specialMenuItems.find((menuItem) => menuItem.link === urlSegment);

  return menuItem;
}

function findMenuItem(
  menu: MenuItemTransformed[] | undefined,
  link: string,
): MenuItemTransformed | undefined {
  if (!menu) return menu;

  const matchingMenuItem = menu.find((menuItem) => menuItem.link.includes(link));

  if (matchingMenuItem) return matchingMenuItem;

  for (const menuItem of menu) {
    const matchingSubItem = menuItem.subItems.length
      ? findMenuItem(menuItem.subItems, link)
      : undefined;

    if (matchingSubItem) return matchingSubItem;
  }
}
