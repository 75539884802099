import i18n from 'i18next';

import { Badge, Box, Collapse, Skeleton } from '@mui/material';

import { StatusIndicator } from 'src/components/StatusIndicator';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { JobsTransformed } from 'src/features/JobsTable/JobsTable.service';
import { Deadline } from 'src/features/Workflow/components/Deadline';
import { useWorkflow } from 'src/features/Workflow/useWorkflow';
import { toBool } from 'src/utilities/helperFunctions';
import { usePreferences, withFullScreen } from 'src/utilities/hooks';

export type WorkflowProps = Partial<Pick<JobsTransformed, 'rows'>>;

type Props = WorkflowProps & { isOnJobForm?: boolean };

const STATUS_CONTAINER_HEIGHT = 68;
const STATUS_CONTAINER_WIDTH = 106;
const STATUS_DIMENSION = 40;

export function Workflow({ isOnJobForm, rows }: Props) {
  const {
    deadlines,
    displayHeaderPreference,
    hasJobId,
    isFetching,
    jobStatusValue,
    jobType,
    jobsPerStatus,
    workflow,
  } = useWorkflow({
    rows,
  });

  const statusContainerProps = {
    sx: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: STATUS_CONTAINER_WIDTH,
      ...(hasJobId && { height: STATUS_CONTAINER_HEIGHT }),
    },
  };

  const showStepTooltipPreference = usePreferences({ code: 'job.bartips', defaultValue: 'Y' });

  return (
    <Collapse in={toBool(displayHeaderPreference.value)} timeout="auto">
      <Box sx={{ columGap: 1, display: 'flex', flexWrap: 'wrap', mb: 1, rowGap: 2 }}>
        {!isFetching && workflow
          ? Object.values(workflow).map((status) => {
              const statusValue = status?.value;
              const jobCount = statusValue ? jobsPerStatus[statusValue] : 0;
              const deadlineDate = statusValue ? deadlines?.dates?.[statusValue]?.deadlineDate : '';
              const description = `desc_${i18n.language}` as keyof typeof status;
              const name = `name_${i18n.language}` as keyof typeof status;
              const isActive = jobStatusValue === statusValue;

              return (
                <Box key={status?.id} {...statusContainerProps}>
                  <Badge badgeContent={jobCount} color="secondary" invisible={!jobCount} max={9999}>
                    <WaveTooltip
                      body={status?.[description]}
                      component={
                        <StatusIndicator
                          isActive={isActive}
                          isOnJobForm={isOnJobForm}
                          jobType={jobType}
                          label={status?.displayValue.toString()}
                          size="large"
                        />
                      }
                      disabled={showStepTooltipPreference.value === 'N'}
                      header={status?.[name]}
                      type="simple"
                    />
                  </Badge>

                  {deadlineDate ? (
                    <Deadline deadline={deadlineDate} isCurrentStatus={isActive} />
                  ) : null}
                </Box>
              );
            })
          : [...Array(10)].map((_, index) => (
              <Box key={index} {...statusContainerProps}>
                <Skeleton height={STATUS_DIMENSION} variant="rounded" width={STATUS_DIMENSION} />
              </Box>
            ))}
      </Box>
    </Collapse>
  );
}

export const VisibilityWorkflow = withFullScreen(Workflow, {
  hideInFullScreen: true,
  hideInSemiFullScreen: true,
});
