import { initReactI18next } from 'react-i18next';

import { AxiosError } from 'axios';
import i18n from 'i18next';
import _ from 'lodash';

import { LONG_DATE_FORMAT } from 'src/components/RHF/DateField/utilities/constants';
import { DEFAULT_LANGUAGE } from 'src/language/utilities/constants';
import { API } from 'src/utilities/api';

export type Translation = {
  [key: string]: string;
};

type ResponseTranslation = {
  code: string;
  value: string;
};

export function getDateFormatsFromTranslation(translations: Translation): Translation {
  const dateCodes = [
    LONG_DATE_FORMAT,
    'lib.date.short',
    'lib.date.week',
    'lib.date.xxl',
    'lib.datetime.long',
    'lib.datetime.md',
    'lib.datetime.short',
  ];
  const dateFormats = _.pick(translations, dateCodes);

  const transformedFormats = _.mapValues(dateFormats, (value: string) =>
    transformDateFormat(value),
  );

  return transformedFormats;
}

function transformDateFormat(format: string) {
  // Define a mapping of PHP format characters to JavaScript format characters
  const formatMapping: { [key: string]: string } = {
    A: 'A',
    B: '',
    D: 'dd',
    F: 'MMMM',
    G: 'H',
    H: 'HH',
    I: '',
    L: '',
    M: 'MMM',
    N: 'E',
    O: 'ZZ',
    P: 'Z',
    S: 'o',
    T: '',
    W: 'W',
    Y: 'YYYY',
    Z: '',
    a: 'a',
    d: 'D',
    dd: 'DD',
    e: '',
    g: 'h',
    h: 'hh',
    i: 'mm',
    j: 'd',
    l: 'dddd',
    m: 'MM',
    n: 'M',
    o: 'YYYY',
    rok: 'YY',
    s: 'ss',
    t: '',
    u: 'SSS',
    w: 'e',
    y: 'YY',
    z: 'D',
  };

  return format.replace(/./g, (formatChar) => formatMapping[formatChar] || formatChar);
}

export function initializeLanguage(language: string | null = null): Promise<Translation> {
  const userLanguage = language || localStorage.getItem('userLanguage') || DEFAULT_LANGUAGE;
  const isDebugMode = import.meta.env.VITE_I18N_DEBUG_MODE === true;

  const cacheKey = `translations-${userLanguage}-${isDebugMode ? 'development' : 'production'}`;

  return new Promise((resolve, reject) => {
    i18n
      .use({
        read: function (
          language: string,
          namespace: string,
          callback: (error: AxiosError | null, data?: Translation) => void,
        ): void {
          const currentTime = Date.now();
          const expiryTime = 24 * 60 * 60 * 1000; // 24 hours

          const cachedData = localStorage.getItem(cacheKey);

          if (cachedData) {
            const { expiry, translations } = JSON.parse(cachedData);

            if (expiry > currentTime) {
              resolve(translations);
              callback(null, translations);

              return;
            }
          }

          API.get('/language-files', {
            params: {
              code: [],
            },
          })
            .then((response) => {
              const translations: Translation = {};

              if (isDebugMode) {
                response.data.data.forEach((translation: ResponseTranslation) => {
                  translations[translation.code] = 'xxxxxxx';
                });
              } else {
                response.data.data.forEach((translation: ResponseTranslation) => {
                  translations[translation.code] = translation.value;
                });
              }

              const dataToCache = {
                expiry: currentTime + expiryTime,
                translations,
              };

              resolve(translations);
              localStorage.setItem(cacheKey, JSON.stringify(dataToCache));
              callback(null, translations);
            })
            .catch((err) => {
              callback(err);
              reject(err);
            });
        },

        type: 'backend',
      })
      .use(initReactI18next)
      .init({
        debug: isDebugMode,
        interpolation: {
          escapeValue: false,
        },
        lng: userLanguage,
        missingKeyHandler: (language, ns, key, fallbackValue) => {
          if (isDebugMode) {
            const cachedData = localStorage.getItem(cacheKey);
            const translations = cachedData ? JSON.parse(cachedData).translations : {};

            translations[key] = 'xxxxxxx';

            const dataToCache = {
              expiry: Date.now() + 24 * 60 * 60 * 1000, // 24 hours
              translations,
            };

            localStorage.setItem(cacheKey, JSON.stringify(dataToCache));
          }

          return isDebugMode ? 'xxxxxxx' : fallbackValue || key;
        },
        saveMissing: isDebugMode,
      });
  });
}
