import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldMapperProps } from 'src/components/FieldMapper/FieldMapper';
import { useJobForm } from 'src/features/JobForm/useJobForm';

type useFieldMapperProps = Required<
  Pick<FieldMapperProps, 'isRequired' | 'shouldUseIntersectionObserver'>
>;

export function useFieldMapper({ isRequired, shouldUseIntersectionObserver }: useFieldMapperProps) {
  const { t: translate } = useTranslation();
  const { control } = useFormContext();
  const { isNewJob } = useJobForm();

  const componentRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(!shouldUseIntersectionObserver);

  const requiredRule = isRequired
    ? {
        required: {
          message: `${translate('lib.required')}`,
          value: isRequired,
          // This value isn't working.
          // Even if you set it to false,
          // it still shows that the field is required.
          // For that reason, I used a ternary operator
          // and return an empty object.
        },
      }
    : {};

  useEffect(() => {
    if (!shouldUseIntersectionObserver) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (componentRef.current) observer.observe(componentRef.current);

    return () => observer.disconnect();
  }, [shouldUseIntersectionObserver]);

  return { componentRef, control, isNewJob, isVisible, requiredRule };
}
