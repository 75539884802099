import { Trans } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { ChangePasswordDialog } from 'src/features/UserDetails/components/ChangePasswordDialog';
import { useUserDetails } from 'src/features/UserDetails/useUserDetails';
import { WaveIcon } from 'src/features/WaveIcon';

const fieldGap = 2;

export function UserDetails() {
  const {
    control,
    handleClickChangePassword,
    handleClickReset,
    handleClosePasswordDialog,
    handleSubmit,
    isDirty,
    isPasswordDialogOpen,
    isSubmitting,
    isValid,
    requiredRule,
    translate,
    userDetails,
  } = useUserDetails();

  return (
    <>
      <Stack component="form" direction="row" onSubmit={handleSubmit}>
        <Paper sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 3, p: 2 }}>
          <Stack alignItems="center" direction="row" gap={5} justifyContent="space-between">
            <Typography component="h2" variant="h4">
              <Trans i18nKey="user_details_title">User Details</Trans>
            </Typography>

            <Box>
              <Stack direction="row" gap={2}>
                <WaveTooltip
                  body={!isDirty ? 'Nothing has changed.' : ''}
                  component={
                    <Button color="warning" disabled={!isDirty} onClick={handleClickReset}>
                      {translate('lib.reset')}
                    </Button>
                  }
                  type="simple"
                />

                <Button
                  onClick={handleClickChangePassword}
                  startIcon={<WaveIcon code="user-profile-action-bar-change-password" />}
                >
                  {translate('hom.pwd.change')}
                </Button>

                <WaveTooltip
                  body={!isDirty ? 'Nothing to update.' : !isValid ? 'The form is invalid.' : ''}
                  component={
                    <LoadingButton
                      disabled={!isDirty || !isValid}
                      loading={isSubmitting}
                      startIcon={<WaveIcon code="user-profile-action-bar-save" />}
                      type="submit"
                      variant="contained"
                    >
                      {translate('clip.save')}
                    </LoadingButton>
                  }
                  type="simple"
                />
              </Stack>
            </Box>
          </Stack>

          <Stack gap={fieldGap}>
            <Stack direction="row" gap={fieldGap}>
              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={!userDetails}
                label={translate('lib.firstname')}
                name="firstName"
                rules={requiredRule}
              />

              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={!userDetails}
                label={translate('usr.name')}
                name="username"
                rules={requiredRule}
              />
            </Stack>

            <Stack direction="row" gap={fieldGap}>
              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={!userDetails}
                label={translate('lib.lastname')}
                name="lastName"
                rules={requiredRule}
              />

              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={!userDetails}
                label={translate('lib.company')}
                name="company"
                rules={requiredRule}
              />
            </Stack>

            <Stack direction="row" gap={fieldGap}>
              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={!userDetails}
                label={translate('lib.location')}
                name="location"
                rules={requiredRule}
              />

              <TextField
                control={control}
                disabled
                isFetching={!userDetails}
                label={translate('lib.email')}
                name="emailAddress"
                rules={{
                  pattern: {
                    message: 'Must be a valid email address.',
                    value:
                      /* eslint-disable-next-line max-len */
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  },
                }}
              />
            </Stack>

            <Stack direction="row" gap={fieldGap}>
              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={!userDetails}
                label={translate('usr.department')}
                name="department"
              />

              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={!userDetails}
                label={translate('user_details_phone_number_field', 'Phone number')}
                name="phoneNumber"
                rules={{
                  pattern: {
                    message: 'Must be a valid phone number.',
                    value:
                      /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g,
                  },
                }}
              />
            </Stack>
          </Stack>
        </Paper>
      </Stack>

      <ChangePasswordDialog isOpen={isPasswordDialogOpen} onClose={handleClosePasswordDialog} />
    </>
  );
}
