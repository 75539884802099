import { useEffect } from 'react';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { changeLanguage } from 'i18next';

import { ProjectAssign } from 'src/components/ProjectAssign';
import { SecureZone } from 'src/components/SecureZone';
import { Snackbar } from 'src/components/Snackbar';
import { WaveTheme } from 'src/components/WaveTheme';
import { ExternalRevisor } from 'src/features/ExternalRevisor';
import { useLanguageCodePreference } from 'src/language/utilities/useLanguageCodePreference';
import { AssignToProject } from 'src/pages/AssignToProject';
import { CompareFiles } from 'src/pages/CompareFiles';
import { Dashboard } from 'src/pages/Dashboard';
import { FileSearch } from 'src/pages/FileSearch';
import { GeneralFiles } from 'src/pages/GeneralFiles';
import { GeneralHelp } from 'src/pages/GeneralHelp';
import { GenericIFrame } from 'src/pages/GenericIFrame';
import { Job } from 'src/pages/Job';
import { Jobs } from 'src/pages/Jobs';
import { Login } from 'src/pages/Login';
import { NotFound } from 'src/pages/NotFound';
import { Reporting } from 'src/pages/Reporting';
import { UpdatePassword } from 'src/pages/UpdatePassword';
import { UserProfile } from 'src/pages/UserProfile';
import { UserSettings } from 'src/pages/UserSettings';
import { Viewer } from 'src/pages/Viewer';
import { useGetRightsQuery } from 'src/store/user.service';
import { setupResponseInterceptor } from 'src/utilities/api';
import { getAccessToken, logout } from 'src/utilities/auth';
import { useI18Initialized } from 'src/utilities/hooks';

export function App() {
  const isInitialized = useI18Initialized();
  const navigate = useNavigate();

  setupResponseInterceptor({
    onNotFound: () => navigate('404', { replace: true }),
    onUnauthorized: () => logout(),
  });

  const userLanguagePreference = useLanguageCodePreference();

  const path = useLocation().pathname.split('/');
  const [, age, jobType] = path[1].split('-');
  const jobId = path[2];
  const jobTab = path[3];
  const jobTabRight =
    jobTab === 'assign' ? 'pro-assign' : jobTab === 'sub-jobs' ? `${jobType}-sub` : jobTab;
  const menuAge = `menu-${age}`;

  let redirectPage = '';
  let missingRights = '';

  const accessToken = getAccessToken();
  const readRights = useGetRightsQuery(undefined, { skip: !accessToken }).data?.read;
  const canReadSer = !!readRights?.includes('ser');
  const canReadAge = !!readRights?.includes(age);
  const canReadMenuAge = !!readRights?.includes(menuAge);
  const canReadJobType = !!readRights?.includes(`job-${jobType}`);
  const canReadGenericJobTab = !!readRights?.includes(`job-${jobTabRight}`);
  const canReadJobTypeSpecificJobTab = !!readRights?.includes(`job-${jobType}-${jobTabRight}`);

  function checkAgeRights(): boolean {
    const isPublicAge = ['hom', 'job'].includes(age);

    if (isPublicAge) return isPublicAge;

    if (age === 'global') {
      if (!canReadSer) missingRights = 'ser';

      return canReadSer;
    }

    if (!canReadAge) {
      redirectPage = '/jobs-hom-dash';
      missingRights = age;
    }

    return canReadAge;
  }

  function checkJobTypeRights(): boolean {
    const canReadAge = checkAgeRights();

    if (!canReadAge) return canReadAge;

    const isPublicJobType = ['dash', 'search'].includes(jobType);

    if (!isPublicJobType && !canReadJobType) {
      redirectPage = '/jobs-hom-dash';
      missingRights = `job-${jobType}`;
    }

    return isPublicJobType || canReadJobType;
  }

  function checkJobTabRights(): boolean {
    const canReadJobType = checkJobTypeRights();

    if (!canReadJobType) return canReadJobType;

    const isPublicJobTab = ['job', 'det'].includes(jobTab);

    if (!isPublicJobTab && !canReadGenericJobTab && !canReadJobTypeSpecificJobTab) {
      redirectPage = `/jobs-${age}-${jobType}/${jobId}/job`;
      missingRights = `job-${jobTabRight}`;
    }

    return isPublicJobTab || canReadGenericJobTab || canReadJobTypeSpecificJobTab;
  }

  useEffect(() => {
    if (isInitialized) {
      void changeLanguage(userLanguagePreference.value);
      localStorage.setItem('userLanguage', userLanguagePreference.value as string);
    }
  }, [isInitialized]);

  if (!isInitialized) return null;

  return (
    <WaveTheme>
      <Snackbar />

      <Routes>
        <Route element={<SecureZone />}>
          <Route
            element={
              checkJobTypeRights() ? (
                age === 'hom' ? (
                  <Dashboard />
                ) : (
                  <Jobs />
                )
              ) : (
                <Navigate replace state={{ missingRights: missingRights }} to={redirectPage} />
              )
            }
            path="/jobs-:age-:jobType"
          />

          <Route
            element={<Navigate replace to={`/jobs-${age}-${jobType}/${jobId}/job`} />}
            path="/jobs-:age-:jobType/:jobId"
          />

          <Route
            element={
              jobTab === 'compare' ? (
                <CompareFiles />
              ) : checkJobTabRights() ? (
                jobTab === 'assign' && jobId !== 'project-bulk-edit' ? (
                  <AssignToProject />
                ) : jobId === 'project-bulk-edit' ? (
                  <ProjectAssign />
                ) : (
                  <Job />
                )
              ) : jobTab === 'viewer' ? (
                <Viewer />
              ) : (
                <Navigate replace state={{ missingRights }} to={redirectPage} />
              )
            }
            path="/jobs-:age-:jobType/:jobId/:tab"
          />

          <Route
            element={
              checkJobTabRights() ? (
                <Job />
              ) : (
                <Navigate replace state={{ missingRights }} to={redirectPage} />
              )
            }
            path="/jobs-:age-:jobType/:jobId/:tab/:type"
          />

          <Route
            element={
              canReadMenuAge ? (
                <GenericIFrame />
              ) : (
                <Navigate replace state={{ missingRights: menuAge }} to="/jobs-hom-dash" />
              )
            }
            path="/additional-:tabCode"
          />

          <Route element={<Reporting />} path="/reporting" />

          <Route element={<GeneralHelp />} path="/general-help" />

          <Route element={<GeneralFiles />} path="/general-files" />

          <Route element={<UserProfile />} path="/user-profile" />

          <Route element={<UserSettings />} path="/user-settings" />

          <Route element={<FileSearch />} path="/file-search" />
        </Route>

        <Route element={<Navigate replace to="/jobs-hom-dash" />} path="/" />

        <Route element={<Login />} path="/login" />

        <Route element={<UpdatePassword />} path="/update-password/:secretKey" />

        <Route
          element={<ExternalRevisor />}
          path="external-revisor/:token/jobs-:age-:jobType/:jobId"
        />

        <Route element={<NotFound />} path="/*" />
      </Routes>
    </WaveTheme>
  );
}
