import dayjs, { isDayjs } from 'dayjs';

import { isZeroFormatDate } from 'src/components/RHF/DateField/utilities/helperFunctions';
import { isNumber, isString } from 'src/utilities/helperFunctions2';
import { Translation } from '../../language/utilities/helperFunctions';

let dateFormatsStorage: Translation = {};

// TODO: This must be redone. It is not a hook for starters.

export function useDateFormat() {
  function formatDate(type: string, dateValue: string | number) {
    const format = dateFormatsStorage[type] || 'MM/DD/YYYY';
    let dateString = dateValue;

    if (isString(dateString) && isZeroFormatDate(dateString)) {
      return format.replace(/[His]/g, '00').replace(/[A-Za-z]/g, '0');
    }

    if (isNumber(dateValue)) {
      const date = new Date(dateValue * 1000);

      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      dateString = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
    }

    const dateObj = dayjs(dateString, ['YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY', 'YYYY-MM-DD'], true);

    if (!isDayjs(dateObj)) {
      return null;
    }

    return dateObj.format(format);
  }

  function setDateFormats(dateFormats: Translation) {
    dateFormatsStorage = dateFormats;
  }

  return { formatDate, setDateFormats };
}
