import { Trans } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Dialog as MuiDialog, DialogActions, DialogContent, Typography } from '@mui/material';

import { Button } from 'src/components/Button';
import { UploadButton } from 'src/components/RHF/UploadButton';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { EditableAvatar } from 'src/features/EditableAvatar';
import { useDialog } from 'src/features/EditableAvatar/components/Dialog/useDialog';
import { WaveIcon } from 'src/features/WaveIcon';
import { isObject } from 'src/utilities/helperFunctions2';

export type DialogProps = {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
};

export function Dialog({ isDialogOpen, onCloseDialog }: DialogProps) {
  const {
    avatar,
    contrastText,
    control,
    error,
    handleClickCancel,
    handleClickDeleteIcon,
    handleSubmit,
    isDirty,
    isSubmitting,
    shouldDeleteAvatar,
    translate,
  } = useDialog({
    onCloseDialog,
  });

  return (
    <MuiDialog
      open={isDialogOpen}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <WaveDialogTitle
        onClose={handleClickCancel}
        title={translate('edit_avatar_title', 'Edit Avatar')}
      />

      <Box bgcolor="white" component="form" onSubmit={handleSubmit}>
        <DialogContent
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <EditableAvatar
            backgroundColor={error}
            iconCode="user-profile-avatar-dialog-delete"
            iconColor={contrastText}
            isSubmitting={isSubmitting}
            onClick={handleClickDeleteIcon}
            shouldDeleteAvatar={shouldDeleteAvatar}
            {...(isObject(avatar) && { newAvatar: URL.createObjectURL(avatar) })}
          />

          <UploadButton
            control={control}
            disabled={isSubmitting}
            fileType="image/*"
            label={
              <>
                <WaveIcon code="user-profile-upload-file" />

                <Typography color="text.secondary" sx={{ textDecoration: 'underline' }}>
                  Click to Upload
                </Typography>

                <Typography color="text.secondary">SVG, PNG, or JPG (max. 3MB)</Typography>
              </>
            }
            name="avatar"
            parentComponent="box"
            sx={{
              '&:hover': {
                backgroundColor: 'filler.main',
              },
              alignItems: 'center',
              border: '1px dashed #0000001F',
              borderRadius: '4px',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              height: 152,
              justifyContent: 'center',
              width: 396,
            }}
          />
        </DialogContent>

        <DialogActions sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
          <Button
            color="warning"
            onClick={handleClickCancel}
            sx={{ bgcolor: `${isSubmitting ? 'warning.light' : ''}` }}
          >
            {isSubmitting ? 'lib.close' : 'lib.cancel'}
          </Button>

          <WaveTooltip
            component={
              <LoadingButton
                disabled={!isDirty && !shouldDeleteAvatar}
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                <Trans i18nKey="lib.confirm">Confirm</Trans>
              </LoadingButton>
            }
            type="simple"
            {...(!isDirty && !shouldDeleteAvatar && { body: 'Nothing to update.' })}
          />
        </DialogActions>
      </Box>
    </MuiDialog>
  );
}
