import { Trans } from 'react-i18next';

import { Paper, Skeleton, Stack, Typography } from '@mui/material';

import { BreadcrumbsBar } from 'src/features/BreadcrumbsBar';
import { EditableAvatar } from 'src/features/EditableAvatar';
import { UserDetails } from 'src/features/UserDetails';
import { useUserProfile } from 'src/pages/UserProfile/useUserProfile';

export function UserProfile() {
  const { contrastText, domain, employeeNumber, primary, translate, userDetails } =
    useUserProfile();

  return (
    <>
      <BreadcrumbsBar />

      <Stack direction="row" gap={1} m={2}>
        <Paper
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', px: 8, py: 4 }}
        >
          <EditableAvatar
            backgroundColor={primary}
            iconCode="user-profile-avatar-edit"
            iconColor={contrastText}
          />

          <Stack alignItems="center" gap={2} mt={4}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">{`${translate('lib.domain')}:`}</Typography>

              {!userDetails ? (
                <Skeleton variant="rounded" width={80} />
              ) : (
                <Typography>{domain}</Typography>
              )}
            </Stack>

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">
                <Trans i18nKey="user_profile_employee_number">Employee Number</Trans>:
              </Typography>

              {!userDetails ? (
                <Skeleton variant="rounded" width={80} />
              ) : (
                <Typography>{employeeNumber}</Typography>
              )}
            </Stack>
          </Stack>
        </Paper>

        <UserDetails />
      </Stack>
    </>
  );
}
