import { Button } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { Dialog } from 'src/features/UploadFiles/components/Dialog';
import { useUploadFiles } from 'src/features/UploadFiles/useUploadFiles';
import { WaveIcon } from 'src/features/WaveIcon';

type BasicProps = {
  alias?: string;
  destination: string;
  isMultipleFiles: boolean;
  label: string;
};
type FileExplorer = {
  isDisabled?: never;
  isNewJob?: never;
  variant: 'fileExplorer';
};
type JobForm = {
  isDisabled: boolean;
  isNewJob: boolean;
  variant: 'jobForm';
};
type UploadFilesProps = BasicProps & (FileExplorer | JobForm);

export function UploadFiles({
  alias,
  destination,
  isDisabled,
  isMultipleFiles,
  isNewJob,
  label,
  variant,
}: UploadFilesProps) {
  const {
    files,
    handleCloseDialog,
    handleOpenDialog,
    handleResetTargetValue,
    isDialogOpen,
    translate,
  } = useUploadFiles();

  return (
    <>
      <WaveTooltip
        body={isDisabled && isNewJob ? 'Disabled until the job is created.' : ''}
        component={
          <Button
            component="label"
            disabled={isDisabled}
            startIcon={<WaveIcon code="cloud-upload" />}
          >
            <input
              disabled={isDisabled}
              hidden
              multiple={isMultipleFiles}
              onChange={handleOpenDialog}
              onClick={handleResetTargetValue}
              type="file"
            />

            {translate(label)}
          </Button>
        }
        placement="top"
        type="simple"
      />

      <Dialog
        alias={alias}
        destination={destination}
        files={files}
        isOpen={isDialogOpen}
        onCloseDialog={handleCloseDialog}
        variant={variant}
      />
    </>
  );
}
