import { DEFAULT_LANGUAGE } from 'src/language/utilities/constants';
import { usePreferences } from 'src/utilities/hooks';

type UseLanguageCodePreferenceProps = { skip?: boolean };

export function useLanguageCodePreference(props?: UseLanguageCodePreferenceProps) {
  const languageCodePreference = usePreferences({
    code: 'sys.lang',
    defaultValue: DEFAULT_LANGUAGE,
    skip: !!props?.skip,
  });

  return {
    isUpdating: languageCodePreference.isUpdating,
    set: languageCodePreference.set,
    value: languageCodePreference.value as string,
  };
}
