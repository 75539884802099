import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ViewType } from 'src/features/JobsTable/components/ViewSearchItem/ViewSearchItem';
import { ViewSearchNewItemFormProps } from 'src/features/JobsTable/components/ViewSearchNewItemForm/ViewSearchNewItemForm';
import { useCreateViewSearchMutation } from 'src/features/JobsTable/JobsTable.service';
import { usePreferencePrefix, usePreferences } from 'src/utilities/hooks';
import { ActiveFilter } from 'src/utilities/hooks/usePreferences';
import { showErrorMessage, showSuccessMessage } from 'src/utilities/notificationsService';

const defaultValues: { name: string; viewType: ViewType } = {
  name: '',
  viewType: 'userView',
};

type UseViewSearchNewItemForm = Omit<ViewSearchNewItemFormProps, 'newLabel'>;

export function useViewSearchNewItemForm({
  isSavedViews,
  onCloseNewItemForm,
}: UseViewSearchNewItemForm) {
  const { t: translate } = useTranslation();
  const { age, isSubJobsTable, jobType, preferencePrefix } = usePreferencePrefix();

  const activeFiltersPreference = usePreferences({
    code: `${preferencePrefix}.search`,
    defaultValue: [],
  });

  const {
    control,
    formState: { isDirty },
    handleSubmit: reactHookFormHandleSubmit,
  } = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const [createViewSearch] = useCreateViewSearchMutation();

  async function handleSubmit({ name, viewType }: typeof defaultValues) {
    if (!activeFiltersPreference.value.length && !isSavedViews) {
      showErrorMessage('Please select at least 1 filter first');

      return;
    }

    await createViewSearch({
      age,
      filters: activeFiltersPreference.value as ActiveFilter[],
      isSubJobsTable,
      jobType,
      name,
      variant: isSavedViews ? viewType : 'search',
      viewType,
    })
      .unwrap()
      .then(() => {
        onCloseNewItemForm();
        showSuccessMessage(`${name} was successfully saved`);
      });
  }

  return { control, isDirty, onSubmit: reactHookFormHandleSubmit(handleSubmit), translate };
}
