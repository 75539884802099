import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosBaseQuery } from '../../utilities/baseQuery';

export type FileSearchSortOptions = Array<Record<string, string | number>>;

export type JobDataType = {
  jobid: number;
  stichw: string;
  projekt_name: string;
  sku_zzbrand: string;
  sku_zzsubbr: string;
  sku_zzvarnt: string;
  zlp_mth_matcode: string;
  sku_zzcatgy: string;
  search_printer: string;
  zlp_mth_printer_spec_name: string;
  db: string;
};

export type FileDataType = {
  DatelastChange: string;
  ToGv: string;
  ToWec: string;
  category: string;
  core_version: number;
  fileid: string;
  filename: string;
  id: number;
  job: JobDataType;
  jobid: number;
  lock_delete: string;
  mand: number;
  pathname: string;
  size: number;
  src: string;
  sub: string;
  thumb: string;
  thumbnail: string;
  txt: null | string;
  type?: string;
  uid: number;
  version: number;
};

export type FileSearchItem = {
  _index: string;
  _type: string;
  _id: string;
  _score: number;
  _source: FileDataType;
  sort: Array<number | string>;
};

type FileSearchResponse = {
  took: number;
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
    failures?: Array<{
      shard: number;
      index: string;
      node: string;
      reason: {
        type: string;
        reason: string;
        index_uuid: string;
        index: string;
        caused_by: {
          type: string;
          reason: string;
        };
      };
    }>;
  };
  hits: {
    total: {
      value: number;
      relation: string;
    };
    max_score: number;
    hits: Array<FileSearchItem>;
  };
};

type FileSearchParams = {
  id?: number;
  mand?: number;
  searchAfter: Array<number | string> | null;
  query?: string;
  resultsPerPage?: number;
  sort?: FileSearchSortOptions;
};

export const fileSearchApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    fileSearch: builder.query<FileSearchResponse, FileSearchParams>({
      providesTags: ['FileSearch'],
      query: ({ id, mand, query, resultsPerPage, searchAfter, sort }) => {
        const params = {
          id,
          mand,
          query,
          size: resultsPerPage,
          sort,
          ...(searchAfter ? { search_after: searchAfter } : {}),
        };

        return {
          method: 'POST',
          params,
          url: '/job-files/search',
        };
      },
    }),
  }),
  reducerPath: 'fileSearchApi',
  tagTypes: ['FileSearch'],
});

export const { useFileSearchQuery } = fileSearchApi;
