import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ViewSearchItemProps } from 'src/features/JobsTable/components/ViewSearchItem/ViewSearchItem';
import {
  useDeleteViewSearchMutation,
  useUpdateViewSearchMutation,
  ViewSearchName,
} from 'src/features/JobsTable/JobsTable.service';
import { useGetRightsQuery } from 'src/store/user.service';
import { useFirstRender, usePreferencePrefix, usePreferences } from 'src/utilities/hooks';
import { ActiveFilter } from 'src/utilities/hooks/usePreferences';
import { showSuccessMessage } from 'src/utilities/notificationsService';

type UseViewSearchItemProps = Omit<ViewSearchItemProps, 'isNewItemFormOpen'>;

export function useViewSearchItem({ item, onCloseDialog, variant }: UseViewSearchItemProps) {
  const { t: translate } = useTranslation();
  const isFirstRender = useFirstRender();
  const { age, isSubJobsTable, jobType, preferencePrefix } = usePreferencePrefix();

  const [action, setAction] = useState<'edit' | 'delete' | undefined>();

  const activeFiltersPreference = usePreferences({
    code: `${preferencePrefix}.search`,
    defaultValue: [],
  });
  const columnsPreference = usePreferences({
    code: `${preferencePrefix}.columns`,
    defaultValue: '',
  });
  const orderByColumnAliasPreference = usePreferences({
    code: `${preferencePrefix}.order`,
    defaultValue: 'jobid',
  });
  const pagePreference = usePreferences({
    code: `${preferencePrefix}.listPage`,
    defaultValue: '0',
  });
  const rowsPerPagePreference = usePreferences({
    code: `${preferencePrefix}.rowsPerPage`,
    defaultValue: '25',
  });
  const searchFiltersPreference = usePreferences({
    code: `${preferencePrefix}.filterFields`,
    defaultValue: '',
  });

  const canEditView = !!useGetRightsQuery().data?.edit.includes('vie');
  const canDeleteView = !!useGetRightsQuery().data?.delete.includes('vie');

  const [deleteItem] = useDeleteViewSearchMutation();
  const [updateItem] = useUpdateViewSearchMutation();

  const { id, name } = item;

  const {
    control,
    handleSubmit: reactHookFormHandleSubmit,
    reset,
  } = useForm({
    defaultValues: { name },
    mode: 'onChange',
  });

  function handleClickApply() {
    if ('filters' in item) activeFiltersPreference.set(item.filters);
    else {
      const { columnIds, idsOfFilterFields, orderByColumnAlias, rowsPerPage } = item;

      columnsPreference.set(columnIds);
      orderByColumnAliasPreference.set(orderByColumnAlias);
      pagePreference.set('0');
      rowsPerPagePreference.set(rowsPerPage);
      searchFiltersPreference.set(idsOfFilterFields);
    }
  }

  function handleClickEdit() {
    setAction('edit');
  }

  function handleClickDelete() {
    setAction('delete');
  }

  function handleClickCancel() {
    setAction(undefined);
    reset();
  }

  async function handleClickConfirm() {
    if (action === 'delete') {
      await deleteItem({ id, variant })
        .unwrap()
        .then(() => {
          onCloseDialog();
        });
    }
  }

  async function handleSubmit({ name }: ViewSearchName) {
    await updateItem({
      age,
      filters: activeFiltersPreference.value as ActiveFilter[],
      id,
      isSubJobsTable,
      jobType,
      name,
      variant,
    })
      .unwrap()
      .then(() => {
        onCloseDialog();
        showSuccessMessage(`${name} was successfully updated.`);
      });
  }

  useEffect(() => {
    if (!isFirstRender) onCloseDialog();
  }, [
    activeFiltersPreference.value,
    columnsPreference.value,
    orderByColumnAliasPreference.value,
    rowsPerPagePreference.value,
    searchFiltersPreference.value,
  ]);

  return {
    action,
    canDeleteView,
    canEditView,
    control,
    handleClickApply,
    handleClickCancel,
    handleClickConfirm,
    handleClickDelete,
    handleClickEdit,
    onSubmit: reactHookFormHandleSubmit(handleSubmit),
    translate,
  };
}
