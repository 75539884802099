import { isCheckbox, isSelectField } from 'src/components/FieldMapper/utilities/helperFunctions';
import { DATE_SEPARATOR } from 'src/components/RHF/DateField/utilities/constants';
import { BOOLEAN_DISPLAY_VALUE } from 'src/features/ActiveFilters/components/ActiveFilter/utilities/constants';
import {
  isNotADate,
  isTwoDates,
} from 'src/features/ActiveFilters/components/ActiveFilter/utilities/helperFunctions';
import {
  FieldTransformed,
  FieldType,
  FieldValue,
  FieldValues,
  Options,
} from 'src/pages/Job/Job.service';
import { computeDefaultFieldValueByType } from 'src/pages/Job/utilities/helperFunctions';
import { isString, sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions2';
import { ActiveFilter } from 'src/utilities/hooks/usePreferences';

export function computeDefaultValues(
  savedSelectedFields: FieldTransformed[],
  activeFilters?: ActiveFilter[],
) {
  return savedSelectedFields.reduce((defaultValues, { alias, type }) => {
    const savedValue = extractValueFromActiveFilterByAlias(alias, activeFilters);
    const formattedValue = formatValue(savedValue, type);

    defaultValues[alias] = formattedValue ?? computeDefaultFieldValueByType(type);

    return defaultValues;
  }, {} as FieldValues);
}

function extractValueFromActiveFilterByAlias(alias: string, activeFilters?: ActiveFilter[]) {
  return activeFilters
    ? activeFilters.reduce<string | undefined>((value, activeFilter) => {
        const doesActiveFilterMatchAlias = Object.keys(activeFilter).includes(alias);

        if (doesActiveFilterMatchAlias) {
          const activeFilterValue = activeFilter[alias];

          value = !value ? activeFilterValue.toString() : `${value},${activeFilterValue}`;
        }

        return value;
      }, undefined)
    : undefined;
}

export function formatValue(value: string | undefined, type: FieldType | undefined) {
  if (isCheckbox(type)) return Boolean(value);

  if (isSelectField(type) && !value) return null;

  if (value === undefined || isNotADate(value)) return value;

  if (isTwoDates(value)) return value.split(DATE_SEPARATOR);

  return value;
}

export function extractFieldDataByAlias(alias: string, fields: FieldTransformed[]) {
  const field = fields.find((field) => field.alias === alias);
  const fieldData = field?.fieldData;
  const name = field?.name;
  const type = field?.type;

  return { fieldData, name, type };
}

export function computeChangedFormFilters(
  alias: string,
  label: string,
  options: Options,
  type: FieldType,
  value: FieldValue,
): ActiveFilter[] {
  if (!value) return [];

  if (isString(value)) {
    const commaSeparatedValues = transformToCommaSeparatedValues(value);

    return sortArrayOfObjectsByKey(
      commaSeparatedValues.map((value) => buildFormFilter(alias, label, options, type, value)),
      'textValue',
    );
  }

  if (value === true) {
    return [buildFormFilter(alias, label, options, type, BOOLEAN_DISPLAY_VALUE)];
  }

  const isDateRangeFilter = Array.isArray(value);

  if (isDateRangeFilter) {
    return [buildFormFilter(alias, label, options, type, value.join(DATE_SEPARATOR))];
  }

  return [];
}

function buildFormFilter(
  alias: string,
  label: string,
  options: Options,
  type: FieldType,
  value: string,
) {
  return {
    [alias]: value,
    label,
    textValue: computeTextValue(label, options, type, value),
  };
}

function computeTextValue(label: string, options: Options, type: FieldType, value: string) {
  return isSelectField(type) ? extractOptionLabel(label, options, value) : value;
}

function extractOptionLabel(label: string, options: Options, value: string) {
  return options.reduce(
    (textValue, option) => (option.value.toString() === value ? option.label : textValue),
    '',
  );
}

export function filterFormFiltersByAlias(formFilters: ActiveFilter[], alias: string) {
  return formFilters.filter((formFilter) => {
    const formFiltersAlias = Object.keys(formFilter)[0];

    return formFiltersAlias !== alias;
  });
}

export function filterValueFromCommaSeparatedString(
  deleteValue: string,
  commaSeparatedString: string,
) {
  const commaSeparatedValues = transformToCommaSeparatedValues(commaSeparatedString);

  return commaSeparatedValues.filter((value) => value !== deleteValue).join();
}

function transformToCommaSeparatedValues(commaSeparatedString: string) {
  const commaSeparatedValues = commaSeparatedString
    .split(',')
    .reduce<string[]>((commaSeparatedValues, value) => {
      const trimmedValue = value.trim();

      if (trimmedValue) commaSeparatedValues.push(trimmedValue);

      return commaSeparatedValues;
    }, []);

  return commaSeparatedValues;
}
