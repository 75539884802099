import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LANGUAGE } from 'src/language/utilities/constants';
import {
  getDateFormatsFromTranslation,
  initializeLanguage,
} from 'src/language/utilities/helperFunctions';
import {
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
} from 'src/pages/UserSettings/UserSettings.service';
import { userApi } from 'src/store/user.service';
import { useAppDispatch, useDateFormat, useUser } from 'src/utilities/hooks';
import { showSuccessMessage } from 'src/utilities/notificationsService';

export function useUserSettings() {
  const dispatch = useAppDispatch();
  const { control } = useForm();
  const userId = useUser()?.id;

  const { data: { fields, values } = {}, isFetching: isGetUserSettings } = useGetUserSettingsQuery(
    { id: userId },
    { refetchOnMountOrArgChange: true },
  );
  const { setDateFormats } = useDateFormat();

  const [updateUserSettings, { isLoading }] = useUpdateUserSettingsMutation();
  const [changedFields, setChangedFields] = useState<Record<string, string>>({});
  const { t: translate } = useTranslation();

  function checkSettingPreference(settings: Array<string>) {
    return Object.keys(fields || []).find((field) => settings.includes(field));
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (values && values[e.target.name]?.value !== e.target.value) {
      setChangedFields({ ...changedFields, [e.target.name]: e.target.value });
    } else {
      const _changedFields = { ...changedFields };

      delete _changedFields[e.target.name];

      setChangedFields(_changedFields);
    }
  }

  function handleUpdateUserSettings() {
    updateUserSettings({
      id: userId,
      val: changedFields,
    })
      .unwrap()
      .then(async ({ message }) => {
        dispatch(userApi.util.invalidateTags(['menu', 'preferences']));

        const userLanguage = localStorage.getItem('userLanguage') || DEFAULT_LANGUAGE;

        initializeLanguage(userLanguage).then((data) => {
          setDateFormats(getDateFormatsFromTranslation(data));
        });
        showSuccessMessage(message);
      });
  }

  return {
    changedFields,
    checkSettingPreference,
    control,
    fields,
    handleChange,
    handleUpdateUserSettings,
    isGetUserSettings,
    isLoading,
    translate,
    values,
  };
}
