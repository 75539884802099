import { usePreferenceAsNumber } from 'src/utilities/hooks';

const defaultPagePreferenceValue = '0';
const defaultRowsPerPagePreferenceValue = '25';

type UsePagePreferenceProps = { preferencePrefix: string; totalRowCount: number };

export function usePagePreference({ preferencePrefix, totalRowCount }: UsePagePreferenceProps) {
  const pagePreference = usePreferenceAsNumber({
    code: `${preferencePrefix}.listPage`,
    defaultValue: defaultPagePreferenceValue,
  });
  const rowsPerPagePreference = usePreferenceAsNumber({
    code: `${preferencePrefix}.rowsPerPage`,
    defaultValue: defaultRowsPerPagePreferenceValue,
  });

  const page =
    totalRowCount &&
    pagePreference.value &&
    totalRowCount > rowsPerPagePreference.value * pagePreference.value
      ? pagePreference.value
      : 0;

  return { isUpdating: pagePreference.isUpdating, set: pagePreference.set, value: page };
}
