import { Dispatch, SetStateAction } from 'react';

import { Collapse, Paper } from '@mui/material';

import { useActiveFiltersOfJobs } from 'src/components/ActiveFiltersOfJobs/useActiveFiltersOfJobs';
import { ActiveFilters } from 'src/features/ActiveFilters';

export type PropsOfActiveFiltersOfJobs = {
  areActiveFiltersVisible: boolean;
  setAreActiveFiltersVisible: Dispatch<SetStateAction<boolean>>;
};

export function ActiveFiltersOfJobs({
  areActiveFiltersVisible,
  setAreActiveFiltersVisible,
}: PropsOfActiveFiltersOfJobs) {
  const { activeFiltersPreference, handleDeleteActiveFilter, handleDeleteAllActiveFilters } =
    useActiveFiltersOfJobs({ setAreActiveFiltersVisible });

  return (
    <Collapse in={areActiveFiltersVisible} sx={{ overflow: 'visible' }}>
      {activeFiltersPreference.value.length ? (
        <Paper elevation={8} sx={{ borderRadius: 3, mx: 1, my: 2, px: 2, py: 1 }}>
          <ActiveFilters
            activeFilters={activeFiltersPreference.value}
            areActiveFiltersLoading={activeFiltersPreference.isUpdating}
            onDeleteActiveFilter={handleDeleteActiveFilter}
            onDeleteAllActiveFilters={handleDeleteAllActiveFilters}
          />
        </Paper>
      ) : null}
    </Collapse>
  );
}
