import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { App } from 'src/App';
import { store } from 'src/store/store';
import { ErrorBoundary } from './features/ErrorBoundary';
import { LanguageInitializer } from './language/LanguageInitializer';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'material-icons/iconfont/material-icons.css';
import 'src/css/utils.css';
import 'src/css/style.css';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_KEY,
  enabled:
    window.location.hostname !== 'localhost' &&
    window.location.hostname !== 'wave.local' &&
    Boolean(import.meta.env.VITE_SENTRY_KEY),
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false,
      networkDetailAllowUrls: [import.meta.env.VITE_API || ''],
    }),
  ],

  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  tracePropagationTargets: [import.meta.env.VITE_API || ''],
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <LanguageInitializer>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </LocalizationProvider>
    </LanguageInitializer>
  </Provider>,
);
