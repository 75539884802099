import { MouseEvent, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Badge } from '@mui/material';

import { StyledIconButton, StyledSubMenu } from 'src/features/Frame/components/styled';
import { WaveIcon } from 'src/features/WaveIcon';
import { useGetRightsQuery } from 'src/store/user.service';
import { usePreferences } from 'src/utilities/hooks';
import { useAddRecentJobMutation, useGetRecentJobsQuery } from './RecentJobs.service';

export function RecentJobs() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const clientIdPreference = usePreferences({ code: 'sys.mid', defaultValue: '' });

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const path = location.pathname.split('/');
  const age = path[1].split('-')[1];
  const jobType = path[1].split('-')[2];
  const jobId = path[2];
  const tab = path[3];

  const canReadJobType = !!useGetRightsQuery().data?.read.includes(`job-${jobType}`);

  const { data: recentJobs = [], refetch } = useGetRecentJobsQuery();
  const [addRecentJob] = useAddRecentJobMutation();

  function handleClickRecentJobs(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
    setIsSubMenuOpen(true);
  }

  function handleCloseRecentJobs() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  useEffect(() => {
    refetch();
  }, [clientIdPreference.value]);

  useEffect(() => {
    if (
      (age === 'job' || age === 'arc') &&
      jobId &&
      jobId !== 'new' &&
      canReadJobType &&
      tab !== 'assign'
    ) {
      addRecentJob({ age, jobid: jobId, src: jobType });
    }
  }, [jobId]);

  if (recentJobs.length === 0) return null;

  return (
    <>
      <StyledIconButton isSubMenuOpen={isSubMenuOpen} onClick={handleClickRecentJobs} size="large">
        <Badge badgeContent={recentJobs.length} color="info">
          <WaveIcon code="history" />
        </Badge>
      </StyledIconButton>

      {isSubMenuOpen && (
        <StyledSubMenu
          anchorEl={anchorEl}
          items={recentJobs}
          onClose={handleCloseRecentJobs}
          open={isSubMenuOpen}
        />
      )}
    </>
  );
}
