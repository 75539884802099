import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldTransformed } from 'src/pages/Job/Job.service';
import { UserProfileDetails, useUpdateUserProfileDetailsMutation } from 'src/store/user.service';
import { filterFormData } from 'src/utilities/helperFunctions';
import { useUser } from 'src/utilities/hooks';
import { showSuccessMessage } from 'src/utilities/notificationsService';

const required = { isRequired: true };
const fields: Record<string, Pick<FieldTransformed, 'isRequired'> | undefined> = {
  company: required,
  firstName: required,
  lastName: required,
  location: required,
  username: required,
};

export function useUserDetails() {
  const { t: translate } = useTranslation();
  const requiredRule = { required: { message: `${translate('lib.required')}`, value: true } };

  const userDetails = useUser();
  const userId = userDetails?.id;

  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);

  const {
    control,
    formState: { dirtyFields, isDirty, isSubmitting, isValid },
    handleSubmit: reactHookFormHandleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      company: '',
      department: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      location: '',
      phoneNumber: '',
      username: '',
    },
    mode: 'onChange',
  });

  const [updateUserProfileDetails] = useUpdateUserProfileDetailsMutation();

  async function onSubmit(formData: UserProfileDetails) {
    await updateUserProfileDetails({
      userId,
      ...(filterFormData(dirtyFields, fields, formData) as UserProfileDetails),
    })
      .unwrap()
      .then(({ message }) => {
        showSuccessMessage(message);
      });
  }

  function handleClickReset() {
    reset();
  }

  function handleClickChangePassword() {
    setIsPasswordDialogOpen(true);
  }

  function handleClosePasswordDialog() {
    setIsPasswordDialogOpen(false);
  }

  useEffect(() => {
    if (userDetails) reset(userDetails);
  }, [userDetails]);

  return {
    control,
    handleClickChangePassword,
    handleClickReset,
    handleClosePasswordDialog,
    handleSubmit: reactHookFormHandleSubmit(onSubmit),
    isDirty,
    isPasswordDialogOpen,
    isSubmitting,
    isValid,
    requiredRule,
    translate,
    userDetails,
  };
}
