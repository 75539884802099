import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs, { Dayjs } from 'dayjs';

import {
  Recipient,
  useGetEmailTemplateQuery,
  useLazyGetPotentialRecipientsQuery,
  useSendEmailFromCartMutation,
  useSendEmailFromJobMutation,
} from 'src/features/SendEmailDialog/Email.service';
import { SendEmailDialogProps } from 'src/features/SendEmailDialog/SendEmailDialog';
import {
  computeIsDisabled,
  extractIdsOfAdditionalRecipients,
  extractRecipientInformation,
  overwriteHistoryTab,
} from 'src/features/SendEmailDialog/utilities/helperFunctions';
import { Option } from 'src/pages/Job/Job.service';
import { useDebounce, useRouteParams, useUser } from 'src/utilities/hooks';
import { showSuccessMessage } from 'src/utilities/notificationsService';

type UseSendEmailDialogProps = Required<Omit<SendEmailDialogProps, 'title'>>;

export function useSendEmailDialog({ isOpen, isShoppingCart, onClose }: UseSendEmailDialogProps) {
  const { t: translate } = useTranslation();

  const { age, jobId, jobType, tab } = useRouteParams();
  const modifiedHistoryTab = overwriteHistoryTab(tab);
  const userId = useUser()?.id;

  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [additionalRecipients, setAdditionalRecipients] = useState<Option[]>([]);
  const [potentialRecipients, setPotentialRecipients] = useState<Option[]>([]);
  const [additionalRecipientsSearchTerm, setAdditionalRecipientsSearchTerm] = useState('');
  const debouncedValue = useDebounce(additionalRecipientsSearchTerm);
  const [getPotentialRecipients, { isFetching: isFetchingPotentialRecipients }] =
    useLazyGetPotentialRecipientsQuery();

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [linkExpirationDate, setLinkExpirationDate] = useState<Dayjs | null>(null);

  const [sendEmailFromCart] = useSendEmailFromCartMutation();
  const [sendEmailFromJob] = useSendEmailFromJobMutation();
  const { data: emailTemplate } = useGetEmailTemplateQuery(
    {
      age,
      jobId,
      jobType,
      tab: isShoppingCart ? 'cart' : modifiedHistoryTab,
    },
    { skip: !isOpen },
  );

  const isDisabled = computeIsDisabled(isShoppingCart, recipients, additionalRecipients);

  function handleClickRecipientCheckbox(selectedRole: string, selectedId: number) {
    setRecipients(
      recipients.map((role) =>
        role.role === selectedRole && role.id === selectedId
          ? { ...role, checked: !role.checked }
          : role,
      ),
    );
  }

  function handleChangeAdditionalRecipients(
    event: SyntheticEvent<Element, Event>,
    newValue: Option[],
  ) {
    setAdditionalRecipients(newValue);
  }

  function handleChangeAdditionalRecipientsSearchTerm(
    event: SyntheticEvent<Element, Event>,
    searchTerm: string,
  ) {
    setAdditionalRecipientsSearchTerm(searchTerm);
    setPotentialRecipients([]);
  }

  function handleChangeSubject({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) {
    setSubject(value);
  }

  function handleChangeMessage({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setMessage(value);
  }

  function handleSendEmail() {
    const idsOfAdditionalRecipients = extractIdsOfAdditionalRecipients(additionalRecipients);
    const commonParameters = { message, subject };

    if (isShoppingCart) {
      sendEmailFromCart({
        idsOfRecipients: idsOfAdditionalRecipients,
        senderId: userId,
        ...commonParameters,
      })
        .unwrap()
        .then(handleEmailResponse);
    } else {
      const { idsOfRecipients, typesOfRecipients } = extractRecipientInformation(recipients);

      sendEmailFromJob({
        age,
        idsOfAdditionalRecipients,
        idsOfRecipients,
        jobId,
        jobType,
        tab: modifiedHistoryTab,
        typesOfRecipients,
        ...commonParameters,
      })
        .unwrap()
        .then(handleEmailResponse);
    }
  }

  function handleEmailResponse(message: string) {
    showSuccessMessage(message);
    onClose();
  }

  useEffect(() => {
    if (!debouncedValue) return;

    getPotentialRecipients({ searchTerm: debouncedValue })
      .unwrap()
      .then((potentialRecipients) => {
        setPotentialRecipients(potentialRecipients);
      });
  }, [debouncedValue]);

  useEffect(() => {
    if (!emailTemplate) return;

    const { expirationDate, message, recipients, subject } = emailTemplate;

    setLinkExpirationDate(dayjs(expirationDate));
    setRecipients(recipients);
    setSubject(subject);
    setMessage(message);
  }, [emailTemplate]);

  return {
    additionalRecipients,
    emailTemplate,
    handleChangeAdditionalRecipients,
    handleChangeAdditionalRecipientsSearchTerm,
    handleChangeMessage,
    handleChangeSubject,
    handleClickRecipientCheckbox,
    handleSendEmail,
    isDisabled,
    isFetchingPotentialRecipients,
    linkExpirationDate,
    message,
    potentialRecipients,
    recipients,
    setLinkExpirationDate,
    subject,
    translate,
  };
}
