import {
  ActiveFilter,
  LegacyActiveFilters,
  PreferenceValue,
  TransformedPreferenceValue,
} from 'src/utilities/hooks/usePreferences';

export function unixTimestampInSeconds() {
  return Math.floor(Date.now() / 1000);
}

export function convertPreferenceValueToNumber(
  value: TransformedPreferenceValue,
  defaultValue: string,
) {
  if (isNumber(value)) return value;

  if (isString(value)) return parseInt(value);

  return parseInt(defaultValue);
}

export function transformPreference(
  code: string,
  savedPreferenceValue: PreferenceValue | undefined,
  defaultValue: TransformedPreferenceValue,
): TransformedPreferenceValue {
  const doesCodeStartWithJob = code.slice(3) === 'job';
  const doesCodeEndWithSer = code.slice(-3) === 'search';
  const isPreferenceLegacyActiveFilters =
    doesCodeStartWithJob &&
    doesCodeEndWithSer &&
    !!savedPreferenceValue &&
    !isString(savedPreferenceValue) &&
    !Array.isArray(savedPreferenceValue);

  if (isPreferenceLegacyActiveFilters) {
    return mutateLegacyActiveFilters(savedPreferenceValue as LegacyActiveFilters);
  }

  if (savedPreferenceValue !== undefined) return savedPreferenceValue;

  return defaultValue;
}

function mutateLegacyActiveFilters(legacyActiveFilters: LegacyActiveFilters): ActiveFilter[] {
  // Mutate from object to array of objects
  return Object.keys(legacyActiveFilters).map((jobFieldAlias) => ({
    [jobFieldAlias]: legacyActiveFilters[jobFieldAlias],
    label: jobFieldAlias,
    textValue: jobFieldAlias,
  }));
}

export function sortArrayOfObjectsByKey<Object extends Record<Key, unknown>, Key extends string>(
  array: Object[],
  key: Key,
): Object[] {
  return array.slice().sort((firstEl, secondEl) => {
    const firstValue = firstEl[key];
    const secondValue = secondEl[key];

    if (!isString(firstValue) || !isString(secondValue)) return -1;

    return naturalSort(firstValue, secondValue);
  });
}

export function naturalSort(firstElement: string, secondElement: string) {
  return firstElement.localeCompare(secondElement, navigator.languages[0] || navigator.language, {
    ignorePunctuation: true,
    numeric: true,
  });
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isObject(value: unknown): value is object {
  return typeof value === 'object';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}
