import { useTranslation } from 'react-i18next';

import { NavLink } from 'react-router-dom';

import { Typography } from '@mui/material';

import { MenuItemTransformed } from 'src/store/user.service';

type BreadcrumbProps = { breadcrumb: Omit<MenuItemTransformed, 'subItems'>; isLink: boolean };

export function Breadcrumb({ breadcrumb: { code, link, name }, isLink }: BreadcrumbProps) {
  const { t } = useTranslation();

  const translation = code ? t(code, name) : name;

  return (
    <>
      {isLink && link.length ? (
        <NavLink to={link}>{translation}</NavLink>
      ) : (
        <Typography color="primary.contrastText">{translation}</Typography>
      )}
    </>
  );
}
