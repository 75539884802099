import { useRouteParams } from 'src/utilities/hooks';
import { BasicAge } from 'src/utilities/hooks/useRouteParams';

type PropsOfUseJobTableVariants = { forcedAge?: BasicAge };

export function useJobTableVariants(parameters?: PropsOfUseJobTableVariants) {
  const forcedAge = parameters?.forcedAge;

  const routeParam = useRouteParams();

  const { age, jobId, jobType, tab } = useRouteParams(
    routeParam.age === 'global' ? undefined : { forcedAge },
  );

  return {
    age,
    isAssignJobTable: tab === 'assign',
    isSubJobsTable: tab === 'sub-jobs',
    jobId,
    jobType,
    tab,
  };
}
