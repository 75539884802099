import { Container, Stack } from '@mui/material';

import { ActiveFiltersOfJobs } from 'src/components/ActiveFiltersOfJobs';
import { ConfirmationDialog } from 'src/components/ConfirmationDialog';
import { FilterDrawer } from 'src/components/FilterDrawer';
import { BreadcrumbsBar } from 'src/features/BreadcrumbsBar';
import { BulkEditJobFields } from 'src/features/BulkEditJobFields';
import { JobsTable } from 'src/features/JobsTable';
import { ActionBar } from 'src/features/JobsTable/components/ActionBar';
import { useJobs } from 'src/features/JobsTable/utilities/hooks';
import { Workflow } from 'src/features/Workflow';
import { CONTAINER_STYLES, STACK_STYLES } from 'src/utilities/stylesOfJobs';

export function Jobs() {
  const {
    areActiveFiltersVisible,
    areAllSubJobsExpanded,
    areJobsFetching,
    bulkEditType,
    columns,
    deadlines,
    deleteJobId,
    handleBulkEditSuccess,
    handleCancelBulkEdit,
    handleChangePageSearch,
    handleClickRowCheckbox,
    handleCloseConfirmationDialog,
    handleDeleteJob,
    handleOpenConfirmationDialog,
    handleToggleActiveFiltersVisibility,
    handleToggleAreAllSubJobsExpanded,
    handleToggleSelectAllRows,
    idsOfExpandedJobs,
    isConfirmationDialogOpen,
    isJobBeingDeleted,
    jobIdsWithSubJobs,
    jobType,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setAreAllSubJobsExpanded,
    setBulkEditType,
    setIdsOfExpandedJobs,
    setPageHistory,
    setSelectedRows,
    totalRowCount,
  } = useJobs();

  if (bulkEditType === 'fields') {
    return (
      <BulkEditJobFields
        onBulkEditSuccess={handleBulkEditSuccess}
        onCancelBulkEdit={handleCancelBulkEdit}
        selectedRows={selectedRows}
      />
    );
  }

  if (bulkEditType === 'flags' || bulkEditType === 'status') return <div>{bulkEditType}</div>;

  return (
    <>
      <BreadcrumbsBar />

      <Container disableGutters maxWidth={false} sx={CONTAINER_STYLES}>
        {jobType !== 'all' && jobType !== 'search' && <Workflow rows={rows} />}

        <ActionBar
          areAllSubJobsExpanded={areAllSubJobsExpanded}
          idsOfExpandedJobs={idsOfExpandedJobs}
          jobIdsWithSubJobs={jobIdsWithSubJobs}
          onChangePageSearch={handleChangePageSearch}
          onToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          onToggleAreAllSubJobsExpanded={handleToggleAreAllSubJobsExpanded}
          searchTerm={searchTerm}
          selectedRows={selectedRows}
          setAreAllSubJobsExpanded={setAreAllSubJobsExpanded}
          setBulkEditType={setBulkEditType}
        />

        <ActiveFiltersOfJobs
          areActiveFiltersVisible={areActiveFiltersVisible}
          setAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <Stack {...STACK_STYLES}>
        <FilterDrawer
          areActiveFiltersVisible={areActiveFiltersVisible}
          handleToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          parentLocation="jobs"
        />

        <JobsTable
          areActiveFiltersVisible={areActiveFiltersVisible}
          areJobsFetching={areJobsFetching}
          columns={columns}
          deadlines={deadlines}
          idsOfExpandedJobs={idsOfExpandedJobs}
          jobIdsWithSubJobs={jobIdsWithSubJobs}
          onClickRowCheckbox={handleClickRowCheckbox}
          onOpenConfirmationDialog={handleOpenConfirmationDialog}
          onToggleSelectAllRows={handleToggleSelectAllRows}
          rows={rows}
          selectedRows={selectedRows}
          setIdsOfExpandedJobs={setIdsOfExpandedJobs}
          setPageHistory={setPageHistory}
          setSelectedRows={setSelectedRows}
          totalRowCount={totalRowCount}
        />
      </Stack>

      <ConfirmationDialog
        isDisabled={isJobBeingDeleted}
        isOpen={isConfirmationDialogOpen}
        message={`Are you sure you want to delete ${deleteJobId}?`}
        onConfirmation={handleDeleteJob}
        onDialogClose={handleCloseConfirmationDialog}
      />
    </>
  );
}
