import { BreadcrumbsBar } from 'src/features/BreadcrumbsBar';
import { FileExplorer } from 'src/features/FileExplorer';

export function GeneralFiles() {
  return (
    <>
      <BreadcrumbsBar />

      <FileExplorer />
    </>
  );
}
