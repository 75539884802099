import React, { useMemo } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteValue,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';

import {
  getIsOption,
  getOptionLabel,
  getOptionValue,
  getValueLabel,
} from 'src/components/RHF/Autocomplete/utils';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { Option } from 'src/pages/Job/Job.service';
import { usePreferences } from 'src/utilities/hooks';
import { ListboxComponent } from './VirtualList';

import { AutocompleteProps } from './types';

// Needed to provide correct height for empty fields.
const EMPTY_STRING_FALLBACK = '\u00A0';

export function SimpleAutocomplete<
  T extends string | Option,
  P extends FieldValues,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  control,
  description,
  disabled,
  fieldValueIndex,
  isError = false,
  isFetching = false,
  isRawText = false,
  isReadOnly = false,
  label,
  name,
  onChange,
  onChangeLogicBuilderField,
  onChangeSteeredField,
  options,
  rules,
  shouldShowHelperText,
  shouldVirtualize,
  ...rest
}: Omit<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'defaultValue' | 'renderInput'
> &
  Omit<UseControllerProps<P>, 'defaultValue'> &
  AutocompleteProps<T>) {
  const showJobFieldTooltipPreference = usePreferences({ code: 'job.feldtips', defaultValue: 'Y' });

  const {
    field: { onChange: onFieldChange, ref, value: reactHookFormValue, ...field },
    fieldState: { error },
  } = useController<P>({
    control,
    name,
    rules,
  });

  const fieldValue =
    fieldValueIndex !== undefined
      ? reactHookFormValue?.[fieldValueIndex] || ''
      : reactHookFormValue;

  const memoizedOptions = useMemo(() => {
    if (
      fieldValue !== null &&
      fieldValue !== undefined &&
      fieldValue !== 0 &&
      !options.some(
        (option) =>
          getIsOption(option) &&
          (option.value === (fieldValue as T).toString() ||
            option.value === (fieldValue as Option).value),
      )
    ) {
      return [
        {
          ...(!getIsOption(fieldValue)
            ? {
                label: (fieldValue as T).toString(),
                value: (fieldValue as T).toString(),
              }
            : fieldValue),
        },
        ...options,
      ].filter((option) => (option as Option).value !== '') as T[];
    }

    return (options || []).filter((option) => (option as Option).value !== '') as T[];
  }, [options]);

  const value = getValueMapper(fieldValue);

  function getValueMapper(value: string | number | Option | string[] | null): Option | null {
    if (value === null || value === undefined || value === 0 || value === '') {
      return null;
    }

    if (getIsOption(value)) {
      return value;
    }

    return {
      label: getValueLabel(value.toString(), memoizedOptions)?.label || value.toString(),
      value: value.toString(),
    };
  }

  const valueLabel =
    getValueLabel(reactHookFormValue?.toString() ?? '', memoizedOptions)?.label || '';

  if (isReadOnly) {
    return (
      <Box>
        <Typography color="text.secondary" variant="caption">
          {label}
        </Typography>

        <Typography>{valueLabel || EMPTY_STRING_FALLBACK}</Typography>
      </Box>
    );
  }

  if (isRawText) {
    return <Typography>{valueLabel}</Typography>;
  }

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', flex: 1 }}>
      <MuiAutocomplete
        disabled={disabled || isFetching}
        disableListWrap
        fullWidth
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => getOptionValue(option) === getOptionValue(value)}
        onChange={(e, value, reason, details) => {
          const newValue = value;

          if (fieldValueIndex !== undefined && Array.isArray(reactHookFormValue)) {
            const updatedValues = [...reactHookFormValue];

            updatedValues[fieldValueIndex] = getOptionValue(newValue);

            onFieldChange(updatedValues);
          } else {
            onFieldChange(newValue);
          }

          if (onChange) {
            onChange(e, value, reason, details);
          }

          if (onChangeLogicBuilderField) onChangeLogicBuilderField(name);

          if (onChangeSteeredField) onChangeSteeredField(name);
        }}
        options={memoizedOptions}
        {...(shouldVirtualize && {
          ListboxComponent: ListboxComponent,
          renderOption: (props, option, state) => [props, option, state.index] as React.ReactNode,
        })}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error || isError}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...params.inputProps,
              required: !!rules?.required && (value as unknown as string)?.length === 0,
            }}
            inputRef={ref}
            label={label}
            required={!!rules?.required}
            // https://mui.com/material-ui/react-text-field/#helper-text
            {...(shouldShowHelperText && { helperText: (error?.message && error.message) ?? ' ' })}
          />
        )}
        size="small"
        value={
          value as unknown as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo> | undefined
        }
        {...(isFetching && { popupIcon: <CircularProgress size={24} /> })}
        {...field}
        {...rest}
      />

      {showJobFieldTooltipPreference.value === 'Y' && description ? (
        <WaveTooltip
          body={description}
          component={<WaveIcon code="input-field-information" />}
          placement="top"
          type="simple"
        />
      ) : null}
    </Box>
  );
}
