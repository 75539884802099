import { Trans } from 'react-i18next';

import { NavLink, useLocation } from 'react-router-dom';

import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

import { Breadcrumb } from 'src/features/BreadcrumbsBar/components/Breadcrumb/BreadCrumb';
import {
  ExtendedLocation,
  extractBreadcrumbs,
} from 'src/features/BreadcrumbsBar/components/Breadcrumbs/utilities/helperFunctions';
import { useGetMenuQuery } from 'src/store/user.service';

export function Breadcrumbs() {
  const location = useLocation() as ExtendedLocation;
  const { data: menu } = useGetMenuQuery();

  const breadcrumbs = extractBreadcrumbs(menu, location);

  return (
    <MuiBreadcrumbs
      sx={{
        '& .MuiBreadcrumbs-separator': {
          color: 'primary.contrastText',
        },
      }}
    >
      <NavLink to="/jobs-hom-dash">
        <Trans i18nKey={'hom-dash.menu'}>Dashboard</Trans>
      </NavLink>

      {breadcrumbs.map((breadcrumb, index) => {
        const isLastItem = index === breadcrumbs.length - 1;

        return <Breadcrumb breadcrumb={breadcrumb} isLink={!isLastItem} key={breadcrumb.code} />;
      })}
    </MuiBreadcrumbs>
  );
}
