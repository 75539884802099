import { useTranslation } from 'react-i18next';

import { Switch } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { toBool } from 'src/utilities/helperFunctions';
import { usePreferencePrefix, usePreferences, withFullScreen } from 'src/utilities/hooks';

export function HeaderSwitch() {
  const { age, jobId, jobType, preferencePrefix, tab } = usePreferencePrefix();
  const { t } = useTranslation();
  const body = t('breadcrumb_toggle', 'Toggle the workflow display On/Off');
  const isAssignedProjectPage = tab === 'assign';
  const isComparePage = tab === 'compare';
  const pathName = location.pathname.substring(1);
  const isJobHeader = age && jobType;
  const displayHeaderPreferenceCode = isJobHeader
    ? `${preferencePrefix}.header`
    : `${pathName}.header`;
  const displayHeaderPreference = usePreferences({
    code: displayHeaderPreferenceCode,
    defaultValue: 'true',
  });

  const shouldDisplayHeader = toBool(displayHeaderPreference.value);

  function handleToggleHeader() {
    displayHeaderPreference.set(displayHeaderPreference.value === 'true' ? 'false' : 'true');
  }

  function shouldShowHeaderSwitch(): boolean {
    const isNewJob = jobId === 'new';
    const isJobTypeAllOrSearch = jobType === 'all' || jobType === 'search';
    const isInFilesPath = location.pathname.includes('files');
    const isUserSettingsOrProfilePath =
      location.pathname === '/user-settings' || location.pathname === '/user-profile';
    const isFileSearchPath = location.pathname === '/file-search';
    const isViewerPage = tab === 'viewer';

    return (
      !isAssignedProjectPage &&
      !isComparePage &&
      !isNewJob &&
      !isJobTypeAllOrSearch &&
      !isInFilesPath &&
      !isUserSettingsOrProfilePath &&
      !isFileSearchPath &&
      !isViewerPage
    );
  }

  if (!shouldShowHeaderSwitch()) return null;

  return (
    <WaveTooltip
      body={body}
      component={
        <Switch checked={shouldDisplayHeader} color="primary" onChange={handleToggleHeader} />
      }
      placement="right"
      type="simple"
    />
  );
}

export const VisibilityHeaderSwitch = withFullScreen(HeaderSwitch, { hideInSemiFullScreen: true });
