import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { PreferenceValue, TransformedPreferenceValue } from 'src/utilities/hooks/usePreferences';

type CreateAvatarRequest = { formData: FormData } & AvatarBaseParams;
type AvatarBaseParams = { id?: number };
type Clients = Record<string, Client>;
type Client = { code: string; id: number; name_en: string };
type ClientsTransformed = Record<string, string>;
type MenuItem = { code: string; link: string; name: string; subitems?: MenuItem[] };
export type MenuItemTransformed = { subItems: MenuItemTransformed[] } & Omit<MenuItem, 'subitems'>;
type MessageResponse = { message: string };
type Preferences = Record<string, PreferenceValue>;
type Rights = { delete: string[]; edit: string[]; insert: string[]; read: string[] };
type User = {
  avatar_url: string;
  company: string;
  department: string;
  domain: string;
  email: string;
  firstname: string;
  id: number;
  lastname: string;
  location: string;
  phone: string;
  pnr: string;
  user: string;
};
type UserTransformed = Omit<
  User,
  'avatar_url' | 'email' | 'firstname' | 'lastname' | 'phone' | 'pnr' | 'user'
> & {
  avatarUrl: string;
  emailAddress: string;
  employeeNumber: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  username: string;
};
export type UserProfileDetails = Omit<
  UserTransformed,
  'avatarUrl' | 'domain' | 'employeeNumber' | 'id'
>;
type UpdateUserProfileParams = Omit<UserProfileDetails, 'emailAddress'> & UserId;
export type UserId = { userId?: number };
type UpdatePreferenceParams = BasePreferenceParams & { value: TransformedPreferenceValue };
type BasePreferenceParams = { code: string };

export const userApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    createAvatar: build.mutation<string, CreateAvatarRequest>({
      invalidatesTags: (result, error) => (error ? [] : ['user']),
      query({ formData, id }) {
        return {
          data: formData,
          method: 'POST',
          url: `/users/${id}/avatar`,
        };
      },
    }),
    deleteAvatar: build.mutation<string, AvatarBaseParams>({
      invalidatesTags: (result, error) => (error ? [] : ['user']),
      query({ id }) {
        return {
          method: 'DELETE',
          url: `/users/${id}/avatar`,
        };
      },
    }),
    deletePreference: build.mutation<unknown, BasePreferenceParams>({
      invalidatesTags: (result, error) => (error ? [] : ['preferences']),
      query(params) {
        return {
          method: 'DELETE',
          params,
          url: '/preferences/delete',
        };
      },
    }),
    getClients: build.query<ClientsTransformed, void>({
      providesTags: ['clients'],
      query(params) {
        return { method: 'GET', params, url: '/clients/getclients' };
      },
      transformResponse(clients: Clients) {
        const clientsTransformed = Object.values(clients).reduce(
          (clients, client) => ({
            ...clients,
            [client.id]: client.name_en,
          }),
          {},
        );

        return clientsTransformed;
      },
    }),
    getMenu: build.query<MenuItemTransformed[], void>({
      providesTags: ['menu'],
      query(params) {
        return { method: 'GET', params, url: '/users/menuitems' };
      },
      transformResponse(menu: MenuItem[]) {
        function transformSubItems(menu: MenuItem[]): MenuItemTransformed[] {
          return menu.map(({ subitems: subItems, ...restOfMenu }) => ({
            subItems: subItems ? transformSubItems(subItems) : [],
            ...restOfMenu,
          }));
        }

        return transformSubItems(menu);
      },
    }),
    getPreferences: build.query<Preferences, void>({
      providesTags: ['preferences'],
      query(params) {
        return { method: 'GET', params, url: '/preferences/all' };
      },
    }),
    getRights: build.query<Rights, void>({
      providesTags: ['rights'],
      query(params) {
        return { method: 'GET', params, url: '/rights/all' };
      },
    }),
    getUser: build.query<UserTransformed, void>({
      providesTags: ['user'],
      query(params) {
        return { method: 'GET', params, url: '/token-user' };
      },
      transformResponse({
        avatar_url: avatarUrl,
        email: emailAddress,
        firstname: firstName,
        lastname: lastName,
        phone: phoneNumber,
        pnr: employeeNumber,
        user: username,
        ...restOfUserDetails
      }: User) {
        return {
          avatarUrl: avatarUrl ? `${avatarUrl}#timestamp=${Date.now()}` : avatarUrl,
          emailAddress,
          employeeNumber,
          firstName,
          lastName,
          phoneNumber,
          username,
          ...restOfUserDetails,
        };
      },
    }),
    updatePreference: build.mutation<unknown, UpdatePreferenceParams>({
      invalidatesTags: (result, error) => (error ? [] : ['preferences']),
      query({ code, value }) {
        return {
          method: 'PUT',
          params: { code, val: value },
          url: '/preferences',
        };
      },
    }),
    updateUserProfileDetails: build.mutation<MessageResponse, UpdateUserProfileParams>({
      invalidatesTags: (result, error) => (error ? [] : ['user']),
      query({ company, department, firstName, lastName, location, phoneNumber, userId, username }) {
        return {
          method: 'PUT',
          params: {
            company,
            department,
            firstname: firstName,
            lastname: lastName,
            location,
            phone: phoneNumber,
            user: username,
          },
          url: `/users/${userId}/profile`,
        };
      },
    }),
  }),
  reducerPath: 'userApi',
  tagTypes: ['clients', 'menu', 'preferences', 'rights', 'user'],
});

export const {
  useCreateAvatarMutation,
  useDeleteAvatarMutation,
  useDeletePreferenceMutation,
  useGetClientsQuery,
  useGetMenuQuery,
  useGetPreferencesQuery,
  useGetRightsQuery,
  useGetUserQuery,
  useUpdatePreferenceMutation,
  useUpdateUserProfileDetailsMutation,
} = userApi;
