import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { useColorDialog } from 'src/features/JobForm/components/ColorDialog/useColorDialog';
import { LoadingHeaderAndBody } from 'src/features/JobsTable/components/LoadingHeaderAndBody';
import { PaginationActions } from 'src/features/JobsTable/components/PaginationActions';
import { WaveIcon } from 'src/features/WaveIcon';

export type ColorDialogProps = {
  fieldValueIndex?: number;
  isColorDialogOpen: boolean;
  onHandleChange: (value: string) => void;
  onSetIsColorDialogOpen: (isColorDialogOpen: boolean) => void;
};

export function ColorDialog({
  fieldValueIndex,
  isColorDialogOpen,
  onHandleChange,
  onSetIsColorDialogOpen,
}: ColorDialogProps) {
  const {
    bookPreference,
    books,
    columns,
    handleChangeBook,
    handleChangePage,
    handleChangeRowPerPage,
    handleChangeSearchColor,
    handleClickRadio,
    handleCloseDialog,
    handleConfirm,
    isFetchingColors,
    labelDisplayedRows,
    pagePreference,
    rows,
    rowsPerPagePreference,
    searchColor,
    selectedRow,
    totalRowCount,
    translate,
  } = useColorDialog({
    fieldValueIndex,
    onHandleChange,
    onSetIsColorDialogOpen,
  });

  return (
    <Dialog fullWidth maxWidth={'md'} onClose={handleCloseDialog} open={isColorDialogOpen}>
      <WaveDialogTitle
        onClose={handleCloseDialog}
        title={translate('add_color_template', 'Add Color Template')}
      />

      <DialogContent>
        <Grid2 container marginBottom={1} spacing={1}>
          <Grid2 xs>
            <Autocomplete
              disablePortal
              onChange={(event, value) => handleChangeBook(event, value)}
              options={books || []}
              renderInput={(params) => <TextField {...params} label="Books" />}
              size="small"
              value={bookPreference.value as string}
            />
          </Grid2>

          <Grid2 xs>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WaveIcon code="search" />
                  </InputAdornment>
                ),
              }}
              label={translate('search_colors', 'Search Colors')}
              onChange={handleChangeSearchColor}
              size="small"
              value={searchColor}
            />
          </Grid2>
        </Grid2>

        <TableContainer component={Paper} elevation={1}>
          <Table size="small">
            {isFetchingColors ? (
              <LoadingHeaderAndBody totalColumns={3} totalRows={rowsPerPagePreference.value} />
            ) : (
              <>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.field} sx={{ color: '' }}>
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Radio
                          checked={selectedRow?.index === index}
                          onClick={(event) =>
                            handleClickRadio(event, { book: data.book, color: data.name, index })
                          }
                          size="small"
                          sx={{ marginRight: 1, padding: 0 }}
                          value={data.name}
                        />

                        {data.name}
                      </TableCell>

                      <TableCell>{data.book}</TableCell>

                      <TableCell>{data.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          ActionsComponent={PaginationActions}
          component="div"
          count={totalRowCount}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={translate('file-ser.rows_per_page', 'Rows per page')}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowPerPage}
          page={pagePreference.value}
          rowsPerPage={rowsPerPagePreference.value}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          sx={{ background: grey[100], marginTop: 1 }}
        />
      </DialogContent>

      <Box component={DialogActions} justifyContent="space-between">
        <Button color="warning" onClick={handleCloseDialog}>
          {translate('close_button', 'Close')}
        </Button>

        <Button color="primary" onClick={handleConfirm}>
          {translate('confirm_button', 'Confirm')}
        </Button>
      </Box>
    </Dialog>
  );
}
