import { useState } from 'react';

import { Box, List, ListItem, Paper, Skeleton } from '@mui/material';

import { useExpand } from 'src/utilities/hooks';
import { parseArrayToLogicalObject } from 'src/utilities/parsers';
import { Files } from './components/Files';
import { Folders } from './components/Folders';
import { useGetFoldersQuery } from './FileExplorer.service';

export function FileExplorer() {
  const {
    data: folders = [],
    isFetching,
    refetch: fetchFolders,
  } = useGetFoldersQuery({}, { refetchOnMountOrArgChange: true });
  const { expandedItems: expandedFolders, toggleExpandItem: toggleExpandFolder } = useExpand(
    parseArrayToLogicalObject(folders, 'code'),
  );
  const [selectedFolder, setSelectedFolder] = useState('');

  const containerMargin = 16;

  return (
    <Box
      display="flex"
      height={`calc(100% - ${document.getElementById('breadcrumbs')?.offsetHeight}px - ${
        containerMargin * 2
      }px)`}
      m={containerMargin / 8}
    >
      <Box
        borderRadius="4px 0 0 4px"
        component={Paper}
        elevation={3}
        flexShrink={0}
        overflow="hidden"
      >
        <Box component={List} dense height="100%" overflow="auto" p={0}>
          {isFetching ? (
            <>
              {[...Array(2)].map((_, index) => (
                <Box component={ListItem} key={index} mb={1} p={0}>
                  <Skeleton height={50} variant="rounded" width={300} />
                </Box>
              ))}
            </>
          ) : (
            <Folders
              expandedFolders={expandedFolders}
              folders={folders}
              hasSubFoldersInTree={folders.some(({ subItems }) => subItems.length)}
              onToggleExpandFolder={toggleExpandFolder}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
          )}
        </Box>
      </Box>

      <Files fetchFolders={fetchFolders} selectedFolder={selectedFolder} />
    </Box>
  );
}
