import { memo, SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'react-photoswipe-gallery';

import { useNavigate } from 'react-router-dom';

import { useSelection } from '@viselect/react';
import { isEqual } from 'lodash';

import { Box, TableRow } from '@mui/material';

import { useLazyGetDownloadUrlQuery } from 'src/features/JobFiles/JobFiles.service';
import { FileSearchDropdownButton } from 'src/pages/FileSearch/FileSearchDropdownButton';
import {
  getBytesToMegabytes,
  getFormattedDate,
} from 'src/pages/FileSearch/FileSearchGridItem/utils';
import { MemoizedCheckBox } from 'src/pages/FileSearch/FileSearchListView/FileSearchListView';
import {
  StyledAvatar,
  StyledSearchResultNumberCell,
  StyledTableCell,
} from 'src/pages/FileSearch/FileSearchListView/styled';
import { getNavigationUrl } from 'src/pages/FileSearch/utils';
import { useImageDimensions, useImageSource, useUser } from 'src/utilities/hooks';
import { showSuccessMessage } from 'src/utilities/notificationsService';

import defaultThumbnail from 'src/images/thumbnails/default_thumbnail.png';

let clickTimeout: NodeJS.Timeout | null = null;

type FileSearchListItemProps = {
  page: number;
  row: Record<string, any>;
  rowsPerPage: number;
  isSelected: boolean;
  index: number;
  jobId: string;
  src: string;
  tableName: string;
  type: string;
};

function FileSearchListItem({
  index,
  isSelected,
  jobId,
  page,
  row,
  rowsPerPage,
  src,
  tableName,
  type,
}: FileSearchListItemProps) {
  const { t } = useTranslation();
  const [downloadFiles] = useLazyGetDownloadUrlQuery();
  const userId = useUser()?.id;

  const selection = useSelection();
  const openRef = useRef<any>(null);
  const navigate = useNavigate();
  const searchResultNumber = index + 1 + page * rowsPerPage;
  const sizeInMegaBytes = getBytesToMegabytes(row.FILE_SIZE);
  const formattedLastUpdated = getFormattedDate(row.LAST_UPDATED);
  const { imageSource } = useImageSource(row.THUMBNAIL, type);
  const thumbnail = imageSource as string;
  const { dimensions } = useImageDimensions(thumbnail);

  function handleDoubleClick() {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      clickTimeout = null;

      openRef.current(index);
    } else {
      clickTimeout = setTimeout(() => {
        clickTimeout = null;
      }, 160);
    }
  }

  function handleOpenPhotoSwipe() {
    openRef.current(index);
  }

  function handleAddToGroupSelection() {
    const element = document.querySelector(`[data-key="${row.ID}"]`);

    if (element) {
      selection?.select(element);
    }
  }

  function handleOpenJobFilesTab() {
    const url = getNavigationUrl({ jobId, src, tableName });

    navigate(url);
  }

  function handleDownload() {
    downloadFiles({
      fileIds: [row.ID],
      userId,
    })
      .unwrap()
      .then(({ url }) => {
        window.open(`${import.meta.env.VITE_API}${url}`);

        showSuccessMessage(t('download_successful_content', 'Download was successful'));
      });
  }

  function handleImageError(event: SyntheticEvent<HTMLImageElement, Event>) {
    const target = event.target as HTMLImageElement;

    target.src = defaultThumbnail;
  }

  return (
    <TableRow key={row.JOB_ID} onClick={handleDoubleClick}>
      <StyledSearchResultNumberCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        {searchResultNumber}
      </StyledSearchResultNumberCell>

      <StyledTableCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        <MemoizedCheckBox checked={isSelected} />
      </StyledTableCell>

      <StyledTableCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        {row.JOB_ID}
      </StyledTableCell>

      <StyledTableCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        <Item
          cropped
          height={dimensions?.height || 500}
          original={thumbnail || defaultThumbnail}
          thumbnail={thumbnail || defaultThumbnail}
          width={dimensions?.width || 500}
        >
          {({ open, ref }) => {
            openRef.current = open;

            return (
              <Box paddingLeft={2.5} ref={ref}>
                <StyledAvatar
                  imgProps={{ onError: handleImageError }}
                  src={thumbnail || defaultThumbnail}
                  variant="square"
                />
              </Box>
            );
          }}
        </Item>
      </StyledTableCell>

      <StyledTableCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        {row.FILE_NAME}
      </StyledTableCell>

      <StyledTableCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        {formattedLastUpdated}
      </StyledTableCell>

      <StyledTableCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        {sizeInMegaBytes}MB
      </StyledTableCell>

      <StyledTableCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        {row.KEYWORDS}
      </StyledTableCell>

      <StyledTableCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        {row.PROJECT_NAME}
      </StyledTableCell>

      <StyledTableCell
        className="selectable"
        data-jobid={row.JOB_ID}
        data-key={row.ID}
        data-thumb={thumbnail}
      >
        {row.VERSION}
      </StyledTableCell>

      <StyledTableCell>
        <FileSearchDropdownButton
          isListView
          onAddToGroupSelection={handleAddToGroupSelection}
          onDownload={handleDownload}
          onOpenJobFilesTab={handleOpenJobFilesTab}
          onOpenPhotoSwipe={handleOpenPhotoSwipe}
        />
      </StyledTableCell>
    </TableRow>
  );
}

export const MemoizedFileSearchListItem = memo(FileSearchListItem, isEqual);

export { MemoizedFileSearchListItem as FileSearchListItem };
