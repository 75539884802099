import { AppBar, Box, Container, Divider, Tab, Tabs } from '@mui/material';

import { JobHeader } from 'src/components/JobHeader';
import { SubJobs } from 'src/components/SubJobs';
import { Approvals } from 'src/features/Approvals';
import { BreadcrumbsBar } from 'src/features/BreadcrumbsBar';
import { Content } from 'src/features/Content';
import { History } from 'src/features/History';
import { JobFiles } from 'src/features/JobFiles';
import { JobForm } from 'src/features/JobForm';
import { Workflow } from 'src/features/Workflow';
import { GenericIFrame } from 'src/pages/GenericIFrame';
import { useJob } from 'src/pages/Job/useJob';
import { JobDataProvider } from './JobContext';

export function Job() {
  const { isExternalRevisorView, isNewJob, onChangeTab, tab, tabs } = useJob();

  return (
    <JobDataProvider>
      {!isExternalRevisorView ? <BreadcrumbsBar /> : null}

      {!isNewJob ? (
        <>
          <Container maxWidth={false} sx={{ mb: 1, mt: 1.5 }}>
            <Workflow isOnJobForm />

            <JobHeader />
          </Container>

          <Divider />
        </>
      ) : null}

      {!isExternalRevisorView ? (
        <AppBar color="transparent" elevation={0} sx={{ position: 'static' }}>
          <Tabs onChange={onChangeTab} value={tab} variant="scrollable">
            {tabs?.map(({ code, disabled, name }) => (
              <Tab disabled={disabled} key={name} label={name} value={code} />
            ))}
          </Tabs>

          <Divider />
        </AppBar>
      ) : null}

      {!isExternalRevisorView ? (
        tabs?.map(({ code, link, name }) => {
          if (code !== tab) return null;

          if (code === 'apl') return <Approvals key={code} />;

          if (code === 'cms') {
            return (
              <Box height="100%" key={code} overflow="auto" role="tabpanel">
                <Content />
              </Box>
            );
          }

          if (code === 'fil') return <JobFiles key={code} />;

          if (code === 'his') return <History key={code} />;

          if (code === 'job' || code === 'det' || link.includes('/form-')) {
            return <JobForm key={code} />;
          }

          if (code === 'sub-jobs') return <SubJobs key={code} />;

          if (link.includes('/iframe-')) {
            return (
              <Box height="100%" key={code} role="tabpanel">
                <GenericIFrame />
              </Box>
            );
          }

          return (
            <Box key={code} role="tabpanel">
              {`${code}: ${name}`}
            </Box>
          );
        })
      ) : (
        <Approvals />
      )}
    </JobDataProvider>
  );
}
