import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from '@mui/material';

import { JobTask, StatusChangeTask } from 'src/features/JobsTable/utilities/hooks/useJobs';
import { AutoComplete } from 'src/features/Tasks/components/AutoComplete';
import { BarChart } from 'src/features/Tasks/components/BarChart';
import { PieChart } from 'src/features/Tasks/components/PieChart';
import { Switch } from 'src/features/Tasks/components/Switch';
import { TeamMembers } from 'src/pages/Dashboard/Dashboard.service';
import { usePreferencePrefix, usePreferences } from 'src/utilities/hooks';

function calculateColumnSizes(...params: any) {
  const numberOfCharts = params.filter(Boolean).length;
  const barChartWeight = 1.5;
  const pieChartWeight = 0.75;
  const totalColumns = 12;

  function calculateSizeFromWeight(weight: number) {
    return (totalColumns / numberOfCharts) * (numberOfCharts === 3 ? weight : 1);
  }

  return {
    approvalTasksColumns: calculateSizeFromWeight(pieChartWeight),
    jobTasksColumns: calculateSizeFromWeight(barChartWeight),
    statusChangeTasksColumns: calculateSizeFromWeight(pieChartWeight),
  };
}

type TasksProps = {
  approvalTasks: JobTask[];
  isLoadingUsers: boolean;
  jobTasks: JobTask[];
  isLoading: boolean;
  onSetTeammateID: Dispatch<SetStateAction<number>>;
  statusChangeTasks: StatusChangeTask[];
  teammateID: number;
  users: TeamMembers;
};

export function Tasks({
  approvalTasks,
  isLoading,
  isLoadingUsers,
  jobTasks,
  onSetTeammateID,
  statusChangeTasks,
  teammateID,
  users,
}: TasksProps) {
  const { t: translate } = useTranslation();

  const { preferencePrefix } = usePreferencePrefix();
  const approvalTaskPreference = usePreferences({
    code: `${preferencePrefix}.show-approval-tasks`,
    defaultValue: '1',
  });
  const jobTaskPreference = usePreferences({
    code: `${preferencePrefix}.show-job-tasks`,
    defaultValue: '1',
  });
  const statusChangeTasksPreference = usePreferences({
    code: `${preferencePrefix}.show-status-change-tasks`,
    defaultValue: '1',
  });

  const approvalTasksId = 'approvalTasks';
  const approvalTasksLabel = translate('lib.approvals_task', 'Approval Tasks');
  const shouldShowApprovalTasks = approvalTaskPreference.value === '1';
  const jobTasksId = 'jobTasks';
  const jobTasksLabel = translate('tasks_job_label', 'Job Tasks');
  const shouldShowJobTasks = jobTaskPreference.value === '1';
  const statusChangeTasksId = 'statusChangeTasks';
  const statusChangeTasksLabel = translate('tasks_status_change_label', 'Status Change Tasks');
  const shouldShowStatusChangeTasks = statusChangeTasksPreference.value === '1';

  const { approvalTasksColumns, jobTasksColumns, statusChangeTasksColumns } = calculateColumnSizes(
    shouldShowApprovalTasks,
    shouldShowJobTasks,
    shouldShowStatusChangeTasks,
  );

  function handleSwitchChange({ target: { checked, id: switchId } }: { target: any }) {
    const value = checked === true ? '1' : '0';

    if (switchId === approvalTasksId) approvalTaskPreference.set(value);
    else if (switchId === jobTasksId) jobTaskPreference.set(value);
    else if (switchId === statusChangeTasksId) statusChangeTasksPreference.set(value);
  }

  function handleUserChange({ target: { value } }: { target: any }) {
    onSetTeammateID(parseInt(value));
  }

  return (
    <Card>
      <CardHeader
        classes={{ content: 'mx-10' }}
        className="p-5 primary-bg white-txt"
        title={<Typography component="p">{translate('lib.tasks', 'Tasks')}</Typography>}
      />

      <CardContent>
        <Grid alignItems="center" container margin={0} spacing={1}>
          <Grid item position="relative" xs={3}>
            <AutoComplete
              disabled={isLoadingUsers}
              // eslint-disable-next-line quotes
              label={translate('tasks_teammates_field', "View Teammate's Tasks")}
              onChange={handleUserChange}
              options={users}
              value={teammateID}
            />

            {isLoadingUsers ? <CircularProgress className="buttonProgress" size={24} /> : null}
          </Grid>

          <Grid container item justifyContent="space-around" xs={9}>
            <Grid item position="relative">
              <Switch
                disabled={approvalTaskPreference.isUpdating}
                id="approvalTasks"
                label={approvalTasksLabel}
                onChange={handleSwitchChange}
                value={shouldShowApprovalTasks}
              />

              {approvalTaskPreference.isUpdating ? (
                <CircularProgress className="buttonProgress" size={24} />
              ) : null}
            </Grid>

            <Grid item position="relative">
              <Switch
                disabled={jobTaskPreference.isUpdating}
                id="jobTasks"
                label={jobTasksLabel}
                onChange={handleSwitchChange}
                value={shouldShowJobTasks}
              />

              {jobTaskPreference.isUpdating ? (
                <CircularProgress className="buttonProgress" size={24} />
              ) : null}
            </Grid>

            <Grid item position="relative">
              <Switch
                disabled={statusChangeTasksPreference.isUpdating}
                id="statusChangeTasks"
                label={statusChangeTasksLabel}
                onChange={handleSwitchChange}
                value={shouldShowStatusChangeTasks}
              />

              {statusChangeTasksPreference.isUpdating ? (
                <CircularProgress className="buttonProgress" size={24} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid container marginTop="10px">
          {shouldShowApprovalTasks ? (
            <Grid container direction="column" item xs={approvalTasksColumns}>
              <PieChart isLoading={isLoading} pieces={approvalTasks} title={approvalTasksLabel} />
            </Grid>
          ) : null}

          {shouldShowJobTasks ? (
            <Grid container direction="column" item xs={jobTasksColumns}>
              <PieChart isLoading={isLoading} pieces={jobTasks} title={jobTasksLabel} />
            </Grid>
          ) : null}

          {shouldShowStatusChangeTasks ? (
            <Grid container direction="column" item xs={statusChangeTasksColumns}>
              <BarChart
                bars={statusChangeTasks}
                hoverBarLabel={translate('total_tasks', 'Total Tasks')}
                isLoading={isLoading}
                title={statusChangeTasksLabel}
              />
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
}
