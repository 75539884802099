import axios, { InternalAxiosRequestConfig } from 'axios';

import { AsyncMutex } from 'src/utilities/asyncMutex';
import { unixTimestampInSeconds } from 'src/utilities/helperFunctions2';

const accessToken = 'accessToken';
const accessTokenExpirationTime = 'accessTokenExpirationTime';
const refreshToken = 'refreshToken';

export type TokensData = {
  expires_in: number;
  access_token: string;
  refresh_token: string;
};

const mutex = new AsyncMutex();

export async function handleTokens(request: InternalAxiosRequestConfig) {
  const release = await mutex.acquire();

  const accessTokenExpirationTime = getAccessTokenExpirationTime();

  if (!accessTokenExpirationTime) logout();

  if (parseInt(accessTokenExpirationTime) <= unixTimestampInSeconds()) await refreshAccessToken();

  const modifiedRequest = attachAuthorizationBearerToken(request);

  release();

  return modifiedRequest;
}

function getAccessTokenExpirationTime() {
  return localStorage.getItem(accessTokenExpirationTime) || '';
}

export function logout() {
  deleteTokens();
  redirectToLogin();
}

function deleteTokens() {
  localStorage.removeItem(accessToken);
  localStorage.removeItem(accessTokenExpirationTime);
  localStorage.removeItem(refreshToken);
}

function redirectToLogin() {
  window.location.href = '/login';
}

async function refreshAccessToken() {
  const refreshToken = getRefreshToken();
  const {
    data: { data: tokens },
  } = await axios.post<{ data: TokensData }>(`${import.meta.env.VITE_API}/tokens/refresh`, {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  });

  saveTokens(tokens);
}

function getRefreshToken() {
  return localStorage.getItem(refreshToken) || '';
}

export function saveTokens(tokens: TokensData) {
  localStorage.setItem(accessToken, tokens.access_token);
  localStorage.setItem(
    accessTokenExpirationTime,
    (unixTimestampInSeconds() + tokens.expires_in).toString(),
  );
  localStorage.setItem(refreshToken, tokens.refresh_token);
}

function attachAuthorizationBearerToken(request: InternalAxiosRequestConfig) {
  const accessToken = getAccessToken();

  request.headers.Authorization = `Bearer ${accessToken}`;

  return request;
}

export function getAccessToken() {
  return localStorage.getItem(accessToken) || '';
}
