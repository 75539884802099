import { MouseEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import { useUpdateActiveClientMutation } from 'src/features/Frame/components/UserMenu/UserMenu.service';
import { useGetClientsQuery } from 'src/store/user.service';
import { logout } from 'src/utilities/auth';
import { useAppDispatch, useLocalStorage, usePreferences, useUser } from 'src/utilities/hooks';

export function useUserMenu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorElWidth, setAnchorElWidth] = useState(0);

  const clientIdPreference = usePreferences({ code: 'sys.mid', defaultValue: '' });
  const clientIdPreferenceValue = clientIdPreference.value as string;

  const [, setClipboardData] = useLocalStorage('clipboard', []);

  const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  const [updateActiveClient] = useUpdateActiveClientMutation();
  const { data: clients = {}, isLoading } = useGetClientsQuery();
  const stringifiedClients = JSON.stringify(clients);

  const userDetails = useUser();
  const { avatarUrl, department, firstName, lastName } = userDetails ?? {};

  const {
    control,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      activeClient: {
        label: '',
        value: '',
      },
    },
  });

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setSubMenuOpen(false);
  }

  function handleLogout() {
    setClipboardData([]);
    logout();
  }

  function handleOpenSubMenu({ currentTarget }: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(currentTarget);
    setAnchorElWidth(currentTarget.offsetWidth);
    setSubMenuOpen(true);
  }

  useEffect(() => {
    if (!clientIdPreferenceValue || !clients) return;

    reset({
      activeClient: {
        label: clients[clientIdPreferenceValue],
        value: clientIdPreferenceValue,
      },
    });
  }, [clientIdPreferenceValue, stringifiedClients]);

  return {
    anchorEl,
    anchorElWidth,
    avatarUrl,
    clientIdPreferenceValue,
    clients,
    control,
    department,
    dispatch,
    firstName,
    handleCloseSubMenu,
    handleLogout,
    handleOpenSubMenu,
    isClientsLoading: isLoading,
    isDirty,
    isSubMenuOpen,
    lastName,
    navigate,
    reset,
    updateActiveClient,
  };
}
