import { Trans } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { useFieldSelector } from 'src/features/JobsTable/components/FieldSelector/useFieldSelector';
import { FieldSelectorFieldWell } from 'src/features/JobsTable/components/FieldSelectorFieldWell';
import { WaveIcon } from 'src/features/WaveIcon';
import { FieldTransformed } from 'src/pages/Job/Job.service';
import { Preference } from 'src/utilities/hooks/usePreferences';

export type FieldSelectorProps = {
  fieldPreference: Preference;
  isOpen: boolean;
  onApplyFieldSelector: (selectedFields?: FieldTransformed[]) => void;
  onCloseFieldSelector: () => void;
  savedAvailableFields?: FieldTransformed[];
  savedSelectedFields?: FieldTransformed[];
  type: string;
};

export function FieldSelector({
  fieldPreference,
  isOpen,
  onApplyFieldSelector,
  onCloseFieldSelector,
  savedAvailableFields,
  savedSelectedFields,
  type,
}: FieldSelectorProps) {
  const {
    areAvailableFieldsChecked,
    areSelectedFieldsChecked,
    availableFields,
    checkedAvailableFields,
    codePrefix,
    handleChangeSearchField,
    handleClickAdd,
    handleClickApply,
    handleClickDown,
    handleClickRemove,
    handleClickUp,
    handleClose,
    handleReset,
    haveSelectedFieldsChanged,
    indexesOfCheckedSelectedFields,
    searchValue,
    selectedFields,
    setCheckedAvailableFields,
    setIndexesOfCheckedSelectedFields,
    translate,
  } = useFieldSelector({
    fieldPreference,
    onApplyFieldSelector,
    onCloseFieldSelector,
    savedAvailableFields,
    savedSelectedFields,
  });

  return (
    <Dialog fullWidth={true} maxWidth="lg" onClose={handleClose} open={isOpen}>
      <WaveDialogTitle
        onClose={handleClose}
        title={`${type} ${translate('lib.field_selector', 'Field Selector')}`}
      />

      <DialogContent>
        <TextField
          disabled={fieldPreference.isUpdating}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WaveIcon code={`${codePrefix}-search`} />
              </InputAdornment>
            ),
          }}
          label={translate('search_fields_field', 'Search Fields')}
          onChange={handleChangeSearchField}
          size="small"
          type="search"
          value={searchValue}
        />

        <Box alignItems="center" display="flex" gap={4} mt={2}>
          <Box flexGrow={1}>
            <Typography fontWeight="bold" variant="subtitle2">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {translate('lib.available_fields_selected', {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                count: `${checkedAvailableFields.length}/${availableFields.length}`,
                defaultValue: 'Available fields: {{ count }} selected',
              })}
            </Typography>

            <FieldSelectorFieldWell
              checkedFields={checkedAvailableFields}
              fieldPreference={fieldPreference}
              fields={availableFields}
              setCheckedAvailableFields={setCheckedAvailableFields}
              type="available"
            />
          </Box>

          <Box display="flex" flexDirection="column" gap={2}>
            <Button
              disabled={!areAvailableFieldsChecked || fieldPreference.isUpdating}
              onClick={handleClickAdd}
            >
              <WaveIcon code={`${codePrefix}-right`} />
            </Button>

            <Button
              disabled={!areSelectedFieldsChecked || fieldPreference.isUpdating}
              onClick={handleClickRemove}
            >
              <WaveIcon code={`${codePrefix}-left`} />
            </Button>

            <Button
              disabled={
                !areSelectedFieldsChecked ||
                fieldPreference.isUpdating ||
                indexesOfCheckedSelectedFields.includes(0)
              }
              onClick={handleClickUp}
            >
              <WaveIcon code={`${codePrefix}-up`} />
            </Button>

            <Button
              disabled={
                !areSelectedFieldsChecked ||
                fieldPreference.isUpdating ||
                indexesOfCheckedSelectedFields.includes(selectedFields.length - 1)
              }
              onClick={handleClickDown}
            >
              <WaveIcon code={`${codePrefix}-down`} />
            </Button>
          </Box>

          <Box flexGrow={1}>
            <Typography fontWeight="bold" variant="subtitle2">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {translate('lib.available_fields_selected', {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                count: `${indexesOfCheckedSelectedFields.length}/${selectedFields.length}`,
                defaultValue: 'Selected fields: {{ count }} selected',
              })}
            </Typography>

            <FieldSelectorFieldWell
              checkedFields={indexesOfCheckedSelectedFields}
              fieldPreference={fieldPreference}
              fields={selectedFields}
              setIndexesOfCheckedSelectedFields={setIndexesOfCheckedSelectedFields}
              type="selected"
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button color="warning" disabled={fieldPreference.isUpdating} onClick={handleReset}>
          {translate('lib.reset')}
        </Button>

        <LoadingButton
          disabled={!haveSelectedFieldsChanged}
          loading={fieldPreference.isUpdating}
          onClick={handleClickApply}
          variant="contained"
        >
          <Trans i18next="apply_button">Apply</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
