import { useState } from 'react';

import { useTheme } from '@mui/material';

import { EditableAvatarProps } from 'src/features/EditableAvatar/EditableAvatar';
import { useUser } from 'src/utilities/hooks';

type UseEditableAvatarProps = Pick<EditableAvatarProps, 'iconCode' | 'onClick'>;

export function useEditableAvatar({ iconCode, onClick }: UseEditableAvatarProps) {
  const userDetails = useUser();
  const { avatarUrl, firstName, lastName } = userDetails ?? {};

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    palette: {
      filler: { contrastText, main: filler },
    },
  } = useTheme();

  const isDeleteIcon = iconCode.endsWith('delete');
  const userInitials =
    firstName && lastName
      ? `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
      : '';

  function handleClickAvatarIcon() {
    onClick ? onClick(!!avatarUrl) : handleOpenDialog();
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  function handleOpenDialog() {
    setIsDialogOpen(true);
  }

  return {
    avatarUrl,
    contrastText,
    filler,
    handleClickAvatarIcon,
    handleCloseDialog,
    isDeleteIcon,
    isDialogOpen,
    userInitials,
  };
}
