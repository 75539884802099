import { useCallback, useMemo } from 'react';

import {
  useDeletePreferenceMutation,
  useGetPreferencesQuery,
  useUpdatePreferenceMutation,
} from 'src/store/user.service';
import { getAccessToken } from 'src/utilities/auth';
import { transformPreference } from 'src/utilities/helperFunctions2';

export type Preference = {
  isUpdating: boolean;
  set: (preferenceValue: TransformedPreferenceValue) => void;
  value: TransformedPreferenceValue;
};
export type TransformedPreferenceValue = Exclude<PreferenceValue, LegacyActiveFilters>;
export type PreferenceValue =
  | string
  | string[]
  | ActiveFilter[]
  | LegacyActiveFilters
  | ViewMode
  | OtherPreferences;
export type ActiveFilter = { [alias: string]: string | number; label: string; textValue: string };
export type LegacyActiveFilters = Record<string, string>;
export type ViewMode = 'list' | 'grid' | 'compact';
type OtherPreferences = Record<string, boolean | number | string>;

type PropsOfUsePreferences = {
  code: string;
  defaultValue: TransformedPreferenceValue;
  skip?: boolean;
};

export function usePreferences({ code, defaultValue, skip = false }: PropsOfUsePreferences) {
  const accessToken = getAccessToken();
  const shouldSkip = skip || !accessToken;

  const { data: preferences, isFetching } = useGetPreferencesQuery(undefined, { skip: shouldSkip });
  const savedPreferenceValue = preferences?.[code];
  const preferenceValue = useMemo(
    () => transformPreference(code, savedPreferenceValue, defaultValue),
    [savedPreferenceValue],
  );

  const [deletePreference, { isLoading: isDeleting }] = useDeletePreferenceMutation();
  const [updatePreference, { isLoading: isUpdating }] = useUpdatePreferenceMutation();

  const setPreference = useCallback(
    (preferenceValue: TransformedPreferenceValue) => {
      const isPreferenceDifferent = preferenceValue !== savedPreferenceValue;

      if (!isPreferenceDifferent) return;

      const isPreferenceDeleted =
        (Array.isArray(preferenceValue) && preferenceValue.length === 0) || preferenceValue === '';

      if (isPreferenceDeleted) {
        if (savedPreferenceValue) deletePreference({ code });
      } else {
        updatePreference({
          code,
          value: preferenceValue,
        });
      }
    },
    [savedPreferenceValue],
  );

  return {
    isUpdating: isFetching || isDeleting || isUpdating,
    set: setPreference,
    value: preferenceValue,
  };
}
