import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { DialogProps } from 'src/features/EditableAvatar/components/Dialog/Dialog';
import { useCreateAvatarMutation, useDeleteAvatarMutation } from 'src/store/user.service';
import { isObject } from 'src/utilities/helperFunctions2';
import { useUser } from 'src/utilities/hooks';
import { showSuccessMessage } from 'src/utilities/notificationsService';

type UseDialogProps = Pick<DialogProps, 'onCloseDialog'>;

export function useDialog({ onCloseDialog }: UseDialogProps) {
  const { t: translate } = useTranslation();

  const userId = useUser()?.id;

  const [createAvatar] = useCreateAvatarMutation();
  const [deleteAvatar] = useDeleteAvatarMutation();

  const [shouldDeleteAvatar, setShouldDeleteAvatar] = useState(false);

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit: reactHookFormHandleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: { avatar: '' },
    mode: 'onChange',
  });
  const {
    palette: {
      error: { contrastText, main: error },
    },
  } = useTheme();

  const avatar = watch('avatar');

  function handleClickCancel() {
    onCloseDialog();
    reset();
    setShouldDeleteAvatar(false);
  }

  function handleClickDeleteIcon(isAvatarSaved: boolean) {
    if (isAvatarSaved) setShouldDeleteAvatar(true);

    reset();
  }

  async function onSubmit({ avatar }: { avatar: string }) {
    if (isObject(avatar)) {
      const formData = new FormData();

      formData.append('file', avatar);

      await createAvatar({ formData, id: userId })
        .unwrap()
        .then(async (message) => {
          showSuccessMessage(message);
          onCloseDialog();
          reset();
          setShouldDeleteAvatar(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (shouldDeleteAvatar) {
      await deleteAvatar({ id: userId })
        .unwrap()
        .then(async (message) => {
          showSuccessMessage(message);
          onCloseDialog();
          reset();
          setShouldDeleteAvatar(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    if (avatar) setShouldDeleteAvatar(false);
  }, [avatar]);

  return {
    avatar,
    contrastText,
    control,
    error,
    handleClickCancel,
    handleClickDeleteIcon,
    handleSubmit: reactHookFormHandleSubmit(onSubmit),
    isDirty,
    isSubmitting,
    shouldDeleteAvatar,
    translate,
  };
}
