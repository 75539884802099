import { Trans } from 'react-i18next';

import { Button, Stack } from '@mui/material';

import { ActiveFilter } from 'src/features/ActiveFilters/components/ActiveFilter';
import { isFiltersOfJobs } from 'src/features/ActiveFilters/utilities/helperFunctions';
import { WaveIcon } from 'src/features/WaveIcon';
import { ActiveFilter as ActiveFilterType } from 'src/utilities/hooks/usePreferences';

import { Filter } from 'src/features/History/History.types';

type CommonProps = { onDeleteAllActiveFilters: () => void };
export type BasicFilters = {
  activeFilters: Filter[];
  filterLabel?: string;
  onDeleteActiveFilter: (filter: Pick<BasicFilters, 'activeFilters'>['activeFilters'][0]) => void;
};
export type FiltersOfJobs = {
  activeFilters: ActiveFilterType[];
  areActiveFiltersLoading: boolean;
  onDeleteActiveFilter: (filter: Pick<FiltersOfJobs, 'activeFilters'>['activeFilters'][0]) => void;
};
export type ActiveFiltersProps = (BasicFilters | FiltersOfJobs) & CommonProps;

export function ActiveFilters({ onDeleteAllActiveFilters, ...restOfProps }: ActiveFiltersProps) {
  if (!restOfProps.activeFilters.length) return null;

  return (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1} my={2}>
      {restOfProps.activeFilters.length > 1 ? (
        <Button
          disabled={isFiltersOfJobs(restOfProps) ? restOfProps.areActiveFiltersLoading : false}
          onClick={onDeleteAllActiveFilters}
          size="small"
          startIcon={<WaveIcon code="close" />}
          variant="outlined"
        >
          <Trans i18nKey="remove_all_button">Remove all</Trans>
        </Button>
      ) : null}

      {isFiltersOfJobs(restOfProps)
        ? restOfProps.activeFilters.map((filter) => (
            <ActiveFilter
              areActiveFiltersLoading={restOfProps.areActiveFiltersLoading}
              filter={filter}
              key={filter.textValue}
              onDeleteActiveFilter={restOfProps.onDeleteActiveFilter}
            />
          ))
        : restOfProps.activeFilters.map((filter) => (
            <ActiveFilter
              filter={filter}
              filterLabel={restOfProps.filterLabel}
              key={filter.text}
              onDeleteActiveFilter={restOfProps.onDeleteActiveFilter}
            />
          ))}
    </Stack>
  );
}
