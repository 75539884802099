import { useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { FileIds } from 'src/features/JobFiles/JobFiles';
import { FolderCode, RawFiles, useGetActionsQuery } from 'src/features/JobFiles/JobFiles.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { useRouteParams, useUser, useViewer } from 'src/utilities/hooks';

type UseCompareButtonProps = {
  files: RawFiles;
  onToggleSelectAll: (isSelected?: boolean) => void;
  selectedFileIds: FileIds;
  selectedFolderCode: FolderCode;
};

export function useCompareButton({
  files,
  onToggleSelectAll,
  selectedFileIds,
  selectedFolderCode,
}: UseCompareButtonProps) {
  const { age, jobId, jobType } = useRouteParams();

  const compareButton = useRef<HTMLButtonElement | null>(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const navigate = useNavigate();
  const userId = useUser()?.id;

  const { data: actions } = useGetActionsQuery(
    { age, folderCode: selectedFolderCode, jobId, jobType, userId },
    { skip: !selectedFolderCode },
  );

  const selectedFiles = files.filter((file) => selectedFileIds.includes(file.id));
  const type = selectedFiles.length ? selectedFiles[0].type : 'cloudflow';

  const { openViewerPage } = useViewer();

  const compareButtonData = useMemo(() => {
    const compareButton = actions?.main_actions.find(({ code }) => code === 'compare-files');

    return compareButton
      ? {
          code: compareButton.code,
          compareButtons:
            compareButton.subItems?.map(({ code, text }) => {
              const isCompareOtherFilesDisabled = selectedFileIds.length < 1;
              const isCompareSelectedFiles = code === 'compare-selected-files';
              const isCompareSelectedFilesDisabled = selectedFileIds.length <= 1;

              return {
                code,
                disabled: isCompareSelectedFiles
                  ? isCompareSelectedFilesDisabled
                  : isCompareOtherFilesDisabled,
                icon: (
                  <WaveIcon
                    code={`job-files-action-bar-${code}`}
                    color="primary"
                    fontSize="small"
                  />
                ),
                onClick: () => handleActions(code),
                text,
                tooltipBody:
                  isCompareSelectedFiles && isCompareSelectedFilesDisabled
                    ? 'At least two files need to be selected.'
                    : isCompareOtherFilesDisabled
                    ? 'At least one file needs to be selected.'
                    : '',
              };
            }) ?? [],
          text: compareButton.text,
        }
      : { compareButtons: [] };
  }, [actions, selectedFileIds.length]);

  function handleActions(code: string) {
    if (code === 'compare-selected-files') handleCompareSelectedFiles();

    if (code === 'compare-other-files') handleCompareOtherFiles();

    handleCloseSubMenu();
  }

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function handleCompareOtherFiles() {
    navigate(`/jobs-job-${jobType}/${jobId}/compare`, {
      state: { files: files.filter(({ id }) => selectedFileIds.includes(id)), type },
    });
  }

  function handleCompareSelectedFiles() {
    openViewerPage({
      compareFile: true,
      fileId: selectedFileIds,
      fileJobID: jobId,
      type,
    });
    onToggleSelectAll(false);
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  return {
    code: compareButtonData.code,
    compareButton,
    compareButtons: compareButtonData.compareButtons,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isSubMenuOpen,
    text: compareButtonData.text,
  };
}
