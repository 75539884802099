import { FormProvider } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Drawer, IconButton, Paper, Stack } from '@mui/material';

import { Button } from 'src/components/Button';
import { FieldMapper } from 'src/components/FieldMapper';
import { useFilterDrawer } from 'src/components/FilterDrawer/useFilterDrawer';
import { FILTER_DRAWER_WIDTH } from 'src/components/FilterDrawer/utilities/styles';
import { Typography } from 'src/components/Typography';
import { ActiveFilters } from 'src/features/ActiveFilters';
import { FieldSelector } from 'src/features/JobsTable/components/FieldSelector';
import { WaveIcon } from 'src/features/WaveIcon';
import { DRAWER_TRANSITION } from 'src/utilities/drawerStyles';
import { PAPER_BORDER_RADIUS } from 'src/utilities/stylesOfJobs';

export type FilterDrawerProps = {
  areActiveFiltersVisible: boolean;
  handleToggleActiveFiltersVisibility: () => void;
  parentLocation: string;
};

export function FilterDrawer({
  areActiveFiltersVisible,
  handleToggleActiveFiltersVisibility,
  parentLocation,
}: FilterDrawerProps) {
  const {
    areActiveFiltersUpdating,
    formFilters,
    handleApplyFieldSelector,
    handleCloseFieldSelector,
    handleDeleteAllFormFilters,
    handleDeleteFormFilter,
    handleOpenFieldSelector,
    handleSubmitForm,
    isDirty,
    isFieldSelectorOpen,
    location,
    savedAvailableFields,
    savedSelectedFields,
    searchFiltersPreference,
    translate,
    useFormReturn,
  } = useFilterDrawer({ areActiveFiltersVisible, parentLocation });

  return (
    <Drawer
      onClose={handleToggleActiveFiltersVisibility}
      open={!areActiveFiltersVisible}
      PaperProps={{
        elevation: 8,
        sx: { borderRadius: PAPER_BORDER_RADIUS, gap: 2, position: 'static' },
      }}
      sx={{
        width: !areActiveFiltersVisible ? FILTER_DRAWER_WIDTH : 0,
        zIndex: 1,
        ...DRAWER_TRANSITION,
      }}
      variant="persistent"
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        ml={2}
        mr={1}
        mt={2}
      >
        <Typography variant="h6">Filter Selection</Typography>

        <IconButton onClick={handleToggleActiveFiltersVisibility}>
          <WaveIcon code="close" />
        </IconButton>
      </Stack>

      <FormProvider {...useFormReturn}>
        <Stack component="form" gap={2} mb={4} ml={2} mr={1} onSubmit={handleSubmitForm}>
          <Stack direction="row" justifyContent="space-between">
            <Button
              disabled={!savedAvailableFields?.length}
              onClick={handleOpenFieldSelector}
              size="small"
              startIcon={<WaveIcon code={`${location}-select-filters`} />}
              variant="contained"
            >
              <Trans i18nKey="select_filter_fields_button">Select Filters</Trans>
            </Button>

            <LoadingButton
              color="secondary"
              disabled={!isDirty}
              loading={areActiveFiltersUpdating}
              size="small"
              startIcon={<WaveIcon code={`${location}-submit`} />}
              type="submit"
              variant="contained"
            >
              <Trans i18nKey="apply_filters_button">Apply</Trans>
            </LoadingButton>
          </Stack>

          {formFilters.length ? (
            <Paper elevation={8} sx={{ mb: 2, px: 2 }}>
              <ActiveFilters
                activeFilters={formFilters}
                areActiveFiltersLoading={areActiveFiltersUpdating}
                onDeleteActiveFilter={handleDeleteFormFilter}
                onDeleteAllActiveFilters={handleDeleteAllFormFilters}
              />
            </Paper>
          ) : null}

          {savedSelectedFields?.map(
            ({ alias, description, fieldData, id, isDisabled, name, type }) => (
              <FieldMapper
                alias={alias}
                description={description}
                fieldData={fieldData}
                isDisabled={isDisabled}
                isRange
                key={id}
                name={name}
                shouldUseIntersectionObserver={false}
                type={type}
              />
            ),
          )}
        </Stack>

        {isFieldSelectorOpen ? (
          <FieldSelector
            fieldPreference={searchFiltersPreference}
            isOpen={isFieldSelectorOpen}
            onApplyFieldSelector={handleApplyFieldSelector}
            onCloseFieldSelector={handleCloseFieldSelector}
            savedAvailableFields={savedAvailableFields}
            savedSelectedFields={savedSelectedFields}
            type={translate('filter_button', 'Filter')}
          />
        ) : null}
      </FormProvider>
    </Drawer>
  );
}
