import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Skeleton, Stack } from '@mui/material';

import { ContentSelection } from 'src/features/Content/components/ContentSelection';
import { CreationHeader } from 'src/features/Content/components/CreationHeader';
import { TemplateSelection } from 'src/features/Content/components/TemplateSelection';
import {
  useCreateContentMutation,
  useGetActionBarQuery,
  useGetCategoriesQuery,
  useGetContentListQuery,
  useUpdateContentMutation,
} from 'src/features/Content/content.service';
import { setContentFormInitialValues } from 'src/features/Content/content.utils';
import { useRouteParams, useUser } from 'src/utilities/hooks';
import { showErrorMessage, showSuccessMessage } from 'src/utilities/notificationsService';

import type {
  CategoryItem,
  CreationStep,
  JobContentList,
  PhraseContent,
} from 'src/features/Content/Content.types';

export function ContentForm() {
  const { age, jobId, jobType, tab } = useRouteParams();
  const navigate = useNavigate();
  const location = useLocation();
  const userId = useUser()?.id;

  const { data: contentList, isFetching: isContentFetching } = useGetContentListQuery<{
    data: JobContentList | undefined;
    isFetching: boolean;
  }>({
    age,
    jobId,
    src: jobType,
  });

  if (contentList) {
    const path = location.pathname;

    if (
      (!contentList.length && path.includes('/edit')) ||
      (contentList.length && path.includes('/new'))
    ) {
      navigate(`/jobs-${age}-${jobType}/${jobId}/${tab}`);
    }
  }

  const [step, setStep] = useState<CreationStep>('TEMPLATE_SELECTION');
  const [selectedCategory, setSelectedCategory] = useState('0_0');
  const [createContent, { isLoading: isCreatingContent }] = useCreateContentMutation();
  const [updateContent, { isLoading: isUpdatingContent }] = useUpdateContentMutation();

  const { data: categoriesList = [] } = useGetCategoriesQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );
  const { data: actionBar, isFetching: isActionBarFetching } = useGetActionBarQuery(
    {
      age,
      jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );
  const translations = actionBar
    ?.find((item) => item.code === 'translations')
    ?.subItems?.map((translation) => translation.code);

  const methods = useForm({
    defaultValues: setContentFormInitialValues(contentList, translations, categoriesList),
    mode: 'onChange',
  });

  useEffect(() => {
    if (contentList && categoriesList) {
      const defaultValues = setContentFormInitialValues(contentList, translations, categoriesList);

      methods.reset(defaultValues);
    }
  }, [contentList, categoriesList, methods.reset]);

  const categories = useFieldArray({
    control: methods.control,
    name: 'categories',
  });

  async function handleChangeStep(step: CreationStep) {
    if (step === 'CONTENT_SELECTION') {
      if (await methods.trigger()) {
        setStep(step);
      }
    } else {
      setStep(step);
    }
  }

  function handleSaveContent() {
    methods.handleSubmit((data) => {
      let isValid = true;
      const contents: Array<PhraseContent> = [];

      data.categories.forEach((category, categoryIndex) => {
        const currentCategory = categoriesList.find((x) => x.code === category.categoryId);

        if (category.items && currentCategory) {
          (category.items as CategoryItem[]).forEach((item, itemIndex) => {
            if (item.result) {
              const nonRequiredTranslations =
                item.translations && Object.keys(item.translations)
                  ? Object.keys(item.translations).filter(
                      (translation) => !item.translations[translation],
                    )
                  : undefined;

              const packTypes =
                item.packTypes && Object.keys(item.packTypes)
                  ? Object.keys(item.packTypes).filter((packType) => item.packTypes[packType])
                  : undefined;

              contents.push({
                category_id: currentCategory.id,
                content: (item as CategoryItem).result || '',
                layout: category.layout,
                position: [categoryIndex + 1, itemIndex + 1, 1],
                status: (item as CategoryItem).status,
                ...((nonRequiredTranslations || packTypes) && {
                  features: {
                    ntn: nonRequiredTranslations?.length ? nonRequiredTranslations : [],
                    packtypes: packTypes?.length ? packTypes : [],
                  },
                }),
              });
            } else {
              if (currentCategory.required) {
                isValid = false;
                methods.setError(`categories.${categoryIndex}.items.${itemIndex}`, {
                  message: 'error',
                });

                showErrorMessage('Please fill required categories');
              }
            }
          });
        }
      });

      if (isValid) {
        const isEditContent: boolean = location.pathname.includes('/edit');
        const payload = {
          age,
          contents,
          id: userId,
          jobid: jobId,
          src: jobType,
          template_id: 2,
        };

        const action = isEditContent ? updateContent(payload) : createContent(payload);

        action.unwrap().then(() => {
          navigate(`/jobs-${age}-${jobType}/${jobId}/cms`);
          showSuccessMessage(`Content ${isEditContent ? 'updated' : 'added'} successfully`);
        });
      }
    })();
  }

  if (isContentFetching || isActionBarFetching) {
    return (
      <Box display="flex" flexDirection="column" height="100%" pt={1}>
        <Box display="flex" justifyContent="flex-end" pt={1} px={3}>
          <Skeleton height={31} variant="rounded" width={300} />
        </Box>

        <Stack gap={1.5} m={0} pt={3} px={3}>
          <Skeleton height={30} variant="rounded" />

          <Skeleton height={30} variant="rounded" />

          <Skeleton height={30} variant="rounded" />

          <Skeleton height={30} variant="rounded" />
        </Stack>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" pt={1}>
      <CreationHeader
        currentStep={step}
        isSubmitDisabled={isCreatingContent || isUpdatingContent}
        onCategoryChange={setSelectedCategory}
        onContentSave={handleSaveContent}
        onStepChange={handleChangeStep}
      />

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <FormProvider {...methods} fieldArrays={{ categories }}>
        {step === 'TEMPLATE_SELECTION' ? (
          <TemplateSelection />
        ) : (
          <ContentSelection
            onCategoryChange={setSelectedCategory}
            selectedCategory={selectedCategory}
          />
        )}
      </FormProvider>
    </Box>
  );
}
