import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColorDialogProps } from 'src/features/JobForm/components/ColorDialog/ColorDialog';
import {
  ColorsColumn,
  useGetBooksQuery,
  useLazyGetColorsQuery,
} from 'src/features/JobForm/JobForm.service';
import {
  useDebounce,
  useFirstRender,
  usePagePreference,
  usePreferenceAsNumber,
  usePreferences,
} from 'src/utilities/hooks';

type Row = {
  book: string;
  description: string;
  name: string;
};
type SelectedRow = {
  book: string;
  color: string;
  index: number;
};
type UseColorDialogProps = Omit<ColorDialogProps, 'isColorDialogOpen'>;

export function useColorDialog({
  fieldValueIndex,
  onHandleChange,
  onSetIsColorDialogOpen,
}: UseColorDialogProps) {
  const isFirstRender = useFirstRender();
  const { t: translate } = useTranslation();

  const [columns, setColumns] = useState<ColorsColumn[]>([]);
  const [rows, setRows] = useState<Row[]>([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState<SelectedRow | null>(null);
  const [searchColor, setSearchColor] = useState('');
  const debouncedSearchColor = useDebounce(searchColor);

  const bookPreference = usePreferences({ code: 'colorbook.book', defaultValue: '' });
  const pagePreference = usePagePreference({
    preferencePrefix: 'colorbook.listPage',
    totalRowCount: totalRowCount,
  });
  const rowsPerPagePreference = usePreferenceAsNumber({
    code: 'colorbook.rowsPerPage',
    defaultValue: '25',
  });
  const searchPreference = usePreferences({ code: 'colorbook.search', defaultValue: '' });

  const { getValues, setValue } = useFormContext();

  const { data: books } = useGetBooksQuery();

  const [getColors, { isFetching: isFetchingColors }] = useLazyGetColorsQuery();

  async function getColorsData() {
    await getColors()
      .unwrap()
      .then(({ columns, count, rows }) => {
        setTotalRowCount(count);
        setColumns(columns);
        setRows(rows);
      });
  }

  function handleChangeBook(event: SyntheticEvent<Element, Event>, value: string | null) {
    bookPreference.set(value ?? '');
    pagePreference.set('0');
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, page: number) {
    pagePreference.set(page.toString());
  }

  function handleChangeRowPerPage({
    target: { value: rowsPerPage },
  }: ChangeEvent<HTMLInputElement>) {
    rowsPerPagePreference.set(rowsPerPage.toString());
    pagePreference.set('0');
  }

  function handleClickRadio(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: SelectedRow,
  ) {
    event.stopPropagation();
    event.preventDefault();
    setSelectedRow(value);
  }

  function handleCloseDialog() {
    onSetIsColorDialogOpen(false);
  }

  function handleConfirm() {
    if (selectedRow && fieldValueIndex) {
      const currentValues = getValues('zlp_ag_col_book_01') || [];
      const updatedValues = Array.isArray(currentValues) ? currentValues : [];

      updatedValues[fieldValueIndex] = selectedRow.book;

      setValue('zlp_ag_col_book_01', updatedValues);
      onHandleChange(selectedRow.color);
    }

    handleCloseDialog();
  }

  function handleChangeSearchColor({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setSearchColor(value);
  }

  function labelDisplayedRows() {
    return null;
  }

  useEffect(() => {
    pagePreference.set('0');
    searchPreference.set(debouncedSearchColor);
  }, [debouncedSearchColor]);

  useEffect(() => {
    getColorsData();
  }, [
    bookPreference.value,
    pagePreference.value,
    rowsPerPagePreference.value,
    searchPreference.value,
  ]);

  useEffect(() => {
    if (!isFirstRender && (!bookPreference.value || !searchPreference.value)) {
      getColorsData();
    }
  }, [bookPreference.value, isFirstRender, searchPreference.value]);

  return {
    bookPreference,
    books,
    columns,
    handleChangeBook,
    handleChangePage,
    handleChangeRowPerPage,
    handleChangeSearchColor,
    handleClickRadio,
    handleCloseDialog,
    handleConfirm,
    isFetchingColors,
    labelDisplayedRows,
    pagePreference,
    rows,
    rowsPerPagePreference,
    searchColor,
    selectedRow,
    totalRowCount,
    translate,
  };
}
