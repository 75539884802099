import { TextField as MuiTextField } from '@mui/material';

import { FieldMapper } from 'src/components/FieldMapper';
import { FieldTransformed } from 'src/pages/Job/Job.service';

type FieldRendererProps = {
  jobField?: FieldTransformed;
  language: string;
  alias: string;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  onChangeSteeredField?: (fieldName: string) => void;
  fieldValueIndex?: number;
  isFirstBlockRow?: boolean;
  isReadOnly?: boolean;
};

export function FieldRenderer({
  alias,
  fieldValueIndex,
  isFirstBlockRow,
  isReadOnly,
  jobField,
  language,
  onChangeLogicBuilderField,
  onChangeSteeredField,
}: FieldRendererProps) {
  const translatedNameField = `name_${language}`;
  const translatedDescriptionField = `desc_${language}`;

  if (jobField?.isHidden) return null;

  if (!jobField)
    return <MuiTextField disabled fullWidth name={alias} placeholder={alias} size="small" />;

  const {
    attributes,
    description: defaultDescription,
    fieldData,
    isDisabled,
    isError,
    isLabelledText,
    isRawText,
    isRequired,
    param,
    steer,
    type,
    [translatedDescriptionField as any]: description,
    [translatedNameField as any]: name,
  } = jobField;

  const isLogicBuilderField = attributes?.classes?.includes('bc-lb');
  const shouldSteerChildren = steer.children;
  const isRequiredBasedOnRowPosition =
    isFirstBlockRow === undefined || isFirstBlockRow ? isRequired : false;

  return (
    <FieldMapper
      alias={alias}
      description={description || defaultDescription || ''}
      fieldData={fieldData}
      fieldValueIndex={fieldValueIndex}
      isDisabled={isDisabled}
      isError={isError}
      isLabelledText={isLabelledText}
      isRawText={isRawText}
      isReadOnly={isReadOnly}
      isRequired={isRequiredBasedOnRowPosition}
      name={name}
      onChangeLogicBuilderField={isLogicBuilderField ? onChangeLogicBuilderField : undefined}
      onChangeSteeredField={shouldSteerChildren ? onChangeSteeredField : undefined}
      param={param}
      type={type}
    />
  );
}
