import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Unstable_Grid2 as Grid } from '@mui/material';

import { BreadcrumbsBar } from 'src/features/BreadcrumbsBar';
import { RawFiles } from 'src/features/JobFiles/JobFiles.service';
import { ChooseFilesStep } from 'src/pages/CompareFiles/components/ChooseFilesStep';
import { ConfirmationStep } from 'src/pages/CompareFiles/components/ConfirmationStep';
import { SelectedItems } from 'src/pages/CompareFiles/components/SelectedItems';
import { Wizard } from 'src/pages/CompareFiles/components/Wizard';
import { useRouteParams, useViewer } from 'src/utilities/hooks';
import { parseArrayToLogicalObject } from 'src/utilities/parsers';

export type CompareStep = 'CHOOSE_FILES' | 'CONFIRMATION';

type LocationState = {
  files: RawFiles;
};

export function CompareFiles() {
  const { jobId, jobType } = useRouteParams();
  const location = useLocation();
  const [filesToCompare, setFilesToCompare] = useState(
    (location.state as LocationState).files || [],
  );
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<Record<number, boolean>>([]);
  const [selectedJobItems, setSelectedJobItems] = useState(
    parseArrayToLogicalObject(filesToCompare, 'id', true),
  );
  const [step, setStep] = useState<CompareStep>('CHOOSE_FILES');

  const filesForConfirmation = filesToCompare.filter((file) =>
    Object.keys({ ...selectedJobItems, ...selectedItems }).some(
      (selectedItem) =>
        (selectedItems[+selectedItem] && +selectedItem === file.id) ||
        (selectedJobItems[+selectedItem] && +selectedItem === file.id),
    ),
  );

  const { openViewerPage } = useViewer();

  function handleClickConfirm() {
    const type = filesToCompare.length ? filesToCompare[0].type : 'cloudflow';

    navigate(`/jobs-job-${jobType}/${jobId}/fil`);
    openViewerPage({
      compareFile: true,
      fileId: filesForConfirmation.map((file) => file.id),
      fileJobID: jobId,
      type,
    });
  }

  function handleToggleFiles(files: RawFiles, selected?: boolean) {
    let resultFiles = filesToCompare;
    let resultSelectedItems = selectedItems;

    files.forEach((file) => {
      if (selected && !filesToCompare.some(({ id }) => file.id === id)) {
        resultFiles = [...resultFiles, file];
      }

      resultSelectedItems = {
        ...resultSelectedItems,
        [file.id]: selected || false,
      };
    });

    setFilesToCompare(resultFiles);
    setSelectedItems(resultSelectedItems);
  }

  function handleToggleFileToCompare(fileId: number) {
    setSelectedItems({
      ...selectedItems,
      [fileId]: !selectedItems[fileId] || false,
    });
  }

  function handleToggleSelectedJob(fileId: number) {
    setSelectedJobItems({
      ...selectedJobItems,
      [fileId]: !selectedJobItems[fileId] || false,
    });
  }

  useEffect(() => {
    if (!filesToCompare.length) {
      navigate(`/jobs-job-${jobType}/${jobId}/fil`);
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <BreadcrumbsBar />

      <Wizard
        currentStep={step}
        isConfirmationDisabled={filesForConfirmation.length < 2}
        onConfirm={handleClickConfirm}
        onStepChange={setStep}
      />

      <Grid container xs>
        <Grid borderRight="1px solid rgba(0, 0, 0, 0.12)">
          <SelectedItems
            filesToCompare={filesToCompare}
            header="Selected Job"
            onToggleSelectedItem={handleToggleSelectedJob}
            selectedItems={selectedJobItems}
          />

          <SelectedItems
            filesToCompare={filesToCompare}
            header="Files to compare"
            onToggleSelectedItem={handleToggleFileToCompare}
            selectedItems={selectedItems}
          />
        </Grid>

        {step === 'CHOOSE_FILES' ? (
          <ChooseFilesStep
            onToggleFiles={handleToggleFiles}
            selectedItemsToCompare={selectedItems}
          />
        ) : (
          <ConfirmationStep filesToCompare={filesForConfirmation} />
        )}
      </Grid>
    </Box>
  );
}
