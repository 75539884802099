import { useFullScreen } from 'src/utilities/hooks';

type WithFullScreenProps = {
  hideInFullScreen?: boolean;
  hideInSemiFullScreen?: boolean;
};

export function withFullScreen<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  option: WithFullScreenProps,
) {
  return function FullScreenComponent(props: P) {
    const { isFullScreen, isSemiFullScreen } = useFullScreen();

    if (
      (option.hideInFullScreen && isFullScreen) ||
      (option.hideInSemiFullScreen && isSemiFullScreen)
    ) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}
