import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ForgotPassword } from 'src/components/ForgotPassword';
import { TextField } from 'src/components/RHF/TextField';
import fiveFlow from 'src/images/5Flow.svg';
import { LoginParams, useLoginMutation } from 'src/pages/Login/Login.service';
import { saveTokens } from 'src/utilities/auth';

import waveLogoSlogan from 'src/images/waveLogoSlogan.png';

export function Login() {
  const { installationLogo } = useTheme();
  const { control, handleSubmit } = useForm<LoginParams>({
    mode: 'onBlur',
  });
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();

  async function handleLogin({ password, user }: LoginParams) {
    const { redirectUrl, ...tokens } = await login({ password, user }).unwrap();

    saveTokens(tokens);
    navigate(`/jobs-${redirectUrl}`);
  }

  return (
    <Stack className="public-background" height="100vh">
      <Box ml={2} mt={2} position="absolute">
        <Box alt="5Flow Logo" component="img" src={fiveFlow} width="100px" />
      </Box>

      <Box alignItems="center" alignSelf="center" display="flex" flexGrow={1}>
        <Box display="flex">
          <Stack
            sx={{
              backgroundColor: 'primary.main',
              borderBottomLeftRadius: 2,
              borderTopLeftRadius: 2,
            }}
            width="200px"
          >
            <Box m={0.5}>
              <Box alt="Wave Logo Slogan" component="img" src={waveLogoSlogan} width="100px" />
            </Box>

            <Box alignSelf="center" display="flex" flexGrow={1}>
              {installationLogo ? (
                <Box
                  alt="Installation Logo"
                  component="img"
                  p={1}
                  src={installationLogo}
                  sx={{ objectFit: 'contain' }}
                  width="100%"
                />
              ) : null}
            </Box>
          </Stack>

          <Stack
            alignItems="center"
            py={4}
            sx={{
              backgroundColor: 'filler.main',
              borderBottomRightRadius: 2,
              borderTopRightRadius: 2,
            }}
            width="300px"
          >
            <Stack alignItems="center" component="form" onSubmit={handleSubmit(handleLogin)}>
              <TextField
                autoFocus
                control={control}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Username"
                name="user"
                size="small"
                type="text"
              />

              <TextField
                control={control}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Password"
                name="password"
                size="small"
                type="password"
              />

              <LoadingButton fullWidth loading={isLoading} type="submit">
                <Trans i18nKey="login_sign_in_button">Sign In</Trans>
              </LoadingButton>
            </Stack>

            <ForgotPassword />
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}
