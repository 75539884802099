import { useEffect, useState } from 'react';

import { useGetUserQuery } from 'src/store/user.service';

type UseUserProps = { skip: boolean } | undefined;
export function useUser({ skip }: UseUserProps = { skip: false }) {
  const { data: user } = useGetUserQuery(undefined, { skip });

  const [cachedUser, setCachedUser] = useState(user);

  const stringifiedUser = JSON.stringify(user);

  useEffect(() => {
    if (user) setCachedUser(user);
  }, [stringifiedUser]);

  return cachedUser ?? user;
}
