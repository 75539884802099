import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';

import type {
  Action,
  ContentCategoriesList,
  ContentFormInputs,
  ContentList,
  ContentTemplate,
  Languages,
  NoContentPlaceholder,
  PackagingTypes,
  SearchOption,
  SearchResult,
  TemplateItem,
} from 'src/features/Content/Content.types';

type ExtendedParams = {
  age?: ExpandedAges;
  jobId?: number | string;
  src?: string;
};

type SearchResultParams = {
  category?: string;
  category_id?: number;
  content?: string;
  fields?: Record<string, string>;
  languages?: string[];
  status?: string[];
  id?: number;
};

type CreateTemplateRequest = {
  id?: number;
  type?: ExpandedAges;
  name: string;
  items: Array<TemplateItem> | [];
};

type UpdateTemplateRequest = {
  type: string;
  name: string;
  description?: string;
  template_id: number;
  items: Array<TemplateItem>;
};

export const contentApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    createContent: build.mutation<void, ContentFormInputs>({
      invalidatesTags: ['ContentList'],
      query(data) {
        return {
          data,
          method: 'POST',
          url: `/jobs/${data.jobid}/contents?id=${data.id}`,
        };
      },
    }),
    createTemplate: build.mutation<ContentTemplate, CreateTemplateRequest>({
      invalidatesTags: ['Templates'],
      query(data) {
        return {
          data,
          method: 'POST',
          url: '/content-templates',
        };
      },
    }),
    getActionBar: build.query<Array<Action>, ExtendedParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${params.jobId}/actionbar`,
        };
      },
    }),
    getCategories: build.query<Array<ContentCategoriesList>, {}>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/categories',
        };
      },
    }),
    getContentList: build.query<Record<string, ContentList>, ExtendedParams>({
      providesTags: ['ContentList'],
      query({ jobId, ...params }) {
        return {
          method: 'GET',
          params,
          url: `/jobs/${jobId}/contents`,
        };
      },
    }),
    getLanguages: build.query<Record<string, string>, ExtendedParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/copyhub/languages',
        };
      },
    }),
    getLayouts: build.query<Record<string, string>, {}>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/copyhub/layouts',
        };
      },
    }),
    getNoContentPlaceholder: build.query<NoContentPlaceholder, {}>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/copyhub/placeholder',
        };
      },
    }),
    getPackagingTypes: build.query<PackagingTypes, ExtendedParams>({
      query({ jobId, ...params }) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${jobId}/packaging-types`,
        };
      },
    }),
    getSearchOptions: build.query<Array<SearchOption>, ExtendedParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${params.jobId}/search-options`,
        };
      },
    }),
    getSearchResults: build.query<Array<SearchResult>, SearchResultParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/contents',
        };
      },
    }),
    getTemplate: build.query<string, ExtendedParams>({
      query({ jobId, ...params }) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${jobId}/template`,
        };
      },
    }),
    getTemplates: build.query<Array<ContentTemplate>, null>({
      providesTags: ['Templates'],
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/content-templates',
        };
      },
    }),
    getTranslations: build.query<Languages, ExtendedParams>({
      query({ jobId, ...params }) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${jobId}/translations`,
        };
      },
    }),
    updateContent: build.mutation<void, ContentFormInputs>({
      invalidatesTags: ['ContentList'],
      query(data) {
        return {
          data: {
            ...data,
            ignoreCheckingEmptyValue: true,
          },
          method: 'PUT',
          url: `/jobs/${data.jobid}/contents?id=${data.id}`,
        };
      },
    }),
    updateTemplate: build.mutation<void, UpdateTemplateRequest>({
      invalidatesTags: ['Templates'],
      query(data) {
        return {
          data,
          method: 'PUT',
          url: `/content-templates/${data.template_id}`,
        };
      },
    }),
  }),
  reducerPath: 'contentApi',
  tagTypes: ['ContentList', 'Templates'],
});

export const {
  useCreateContentMutation,
  useCreateTemplateMutation,
  useGetActionBarQuery,
  useGetCategoriesQuery,
  useGetContentListQuery,
  useGetLanguagesQuery,
  useGetLayoutsQuery,
  useGetNoContentPlaceholderQuery,
  useGetPackagingTypesQuery,
  useGetSearchOptionsQuery,
  useGetTemplateQuery,
  useGetTemplatesQuery,
  useGetTranslationsQuery,
  useLazyGetSearchResultsQuery,
  useUpdateContentMutation,
  useUpdateTemplateMutation,
} = contentApi;
