import { useMemo } from 'react';

import { convertPreferenceValueToNumber } from 'src/utilities/helperFunctions2';
import { usePreferences } from 'src/utilities/hooks';

type UsePreferenceAsNumberProps = { code: string; defaultValue: string };

export function usePreferenceAsNumber({ code, defaultValue }: UsePreferenceAsNumberProps) {
  const preference = usePreferences({ code, defaultValue });

  const preferenceValue = useMemo(
    () => convertPreferenceValueToNumber(preference.value, defaultValue),
    [preference.value],
  );

  return {
    isUpdating: preference.isUpdating,
    set: preference.set,
    value: preferenceValue,
  };
}
