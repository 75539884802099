import { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';

import { useQueryParams, useUser } from 'src/utilities/hooks';
import { useGetReportsQuery } from './Reporting.service';

export function Reporting() {
  const tableauView = useRef<HTMLIFrameElement | null>(null);
  const [isTableauScriptLoaded, setIsTableauScriptLoaded] = useState(false);
  const userId = useUser()?.id;

  const queryParams = useQueryParams();
  const reportId = queryParams.get('id');

  const { data } = useGetReportsQuery({ id: userId, reportId });

  const tableauUrl =
    data?.authentication && data?.view
      ? `${data.trusted}${data.authentication.trustedToken}/t/${data.site}/views/${data.view.value}`
      : null;

  useEffect(() => {
    function checkTableauViz() {
      if (customElements.get('tableau-viz')) {
        setIsTableauScriptLoaded(true);
      }
    }

    if (!customElements.get('tableau-viz')) {
      document.addEventListener('DOMContentLoaded', checkTableauViz);
    } else {
      setIsTableauScriptLoaded(true);
    }

    return () => document.removeEventListener('DOMContentLoaded', checkTableauViz);
  }, []);

  useEffect(() => {
    if (!isTableauScriptLoaded || !tableauUrl || !tableauView.current) return;

    const vizElement = tableauView.current;

    vizElement?.setAttribute('src', tableauUrl);
    vizElement?.setAttribute('width', '100%');
    vizElement?.setAttribute('height', 'calc(100vh - 64px)');
    vizElement?.setAttribute('toolbar', 'top');
    vizElement?.setAttribute('hide-tabs', 'false');
    vizElement?.setAttribute('hide-toolbar', 'false');
  }, [isTableauScriptLoaded, tableauUrl]);

  return (
    <Box>
      <tableau-viz ref={tableauView}></tableau-viz>
    </Box>
  );
}
