import { Outlet } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { Stack } from '@mui/material';

import { Frame } from 'src/features/Frame';
import { useGetMenuQuery, useGetRightsQuery } from 'src/store/user.service';
import { usePreferences, useUser } from 'src/utilities/hooks';
import { FullScreenProvider } from 'src/utilities/hooks/FullScreenContext';

export function SecureZone() {
  const { data: menu } = useGetMenuQuery();
  const clientId = usePreferences({ code: 'sys.mid', defaultValue: '' });
  const readRights = useGetRightsQuery().data?.read;
  const username = useUser()?.username;

  const isLoading = !menu || !clientId || !readRights || !username;

  if (username) Sentry.setUser({ username });

  if (isLoading) return null;

  return (
    <FullScreenProvider>
      <Stack height="100vh">
        <Frame>
          <Stack component="main" overflow="auto" width="100%">
            <Outlet />
          </Stack>
        </Frame>
      </Stack>
    </FullScreenProvider>
  );
}
