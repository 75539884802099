import React, { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';
import { useGetMenuQuery } from 'src/store/user.service';

type MainMenuProps = { isOpen: boolean; onToggleDrawer: () => void };

export function MainMenu({ isOpen, onToggleDrawer }: MainMenuProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState<Record<string, boolean>>({});
  const { data: menu } = useGetMenuQuery();

  const currentPage = window.location.origin + location.pathname;

  function handleMenuClick(code: string) {
    if (!isOpen) {
      // If navSidebar is collapsed, expand and expand tiered menu.
      setMenuOpen({ [code]: true });
      onToggleDrawer();
    } else {
      // Toggle tiered menu expansion
      setMenuOpen({ [code]: !menuOpen[code] });
    }
  }

  return (
    <List sx={{ py: 0 }}>
      {menu?.map(({ code, link, name, subItems }) => {
        let backgroundColor = !isOpen
          ? 'primary.main'
          : link.length && currentPage.includes(link)
          ? 'secondary.main'
          : 'filler.light';

        if (subItems.length > 0) {
          for (const subItem of subItems) {
            if (
              subItem.link.length &&
              currentPage.includes(window.location.origin + subItem.link) &&
              isOpen
            ) {
              backgroundColor = 'secondary.main';
            }
          }
        }

        return (
          <React.Fragment key={code}>
            <ListItem
              disableGutters
              onClick={
                subItems.length
                  ? () => {
                      handleMenuClick(code);
                    }
                  : () => {
                      navigate(link);
                    }
              }
              sx={{ backgroundColor, py: 0 }}
            >
              <ListItemButton>
                <ListItemIcon className={isOpen ? 'primary-txt' : 'min-w-0 secondary-txt'}>
                  <WaveIcon code={`main-menu-${code}`} />
                </ListItemIcon>

                <ListItemText className={!isOpen ? 'd-none' : ''} primary={name} />

                {subItems.length > 0 &&
                  (!isOpen ? (
                    <WaveIcon className="secondary-txt" code="navigate-next" />
                  ) : menuOpen[code] ? (
                    <WaveIcon code="expand-less" />
                  ) : (
                    <WaveIcon code="expand-more" />
                  ))}
              </ListItemButton>
            </ListItem>

            {subItems.length > 0 && (
              <Collapse component="li" in={isOpen && menuOpen[code]} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {subItems.map(({ code, link, name }) => {
                    const backgroundColor = !isOpen
                      ? 'primary.main'
                      : link.length && currentPage.includes(link)
                      ? 'secondary.light'
                      : 'filler.light';

                    return (
                      <ListItem
                        disableGutters
                        key={code}
                        onClick={() => {
                          navigate(link);
                        }}
                        sx={{ backgroundColor, py: 0 }}
                      >
                        <ListItemButton>
                          <ListItemText className="no-item-icon" primary={name} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
}
