import { memo, useRef } from 'react';
import { Trans } from 'react-i18next';

import { Box, Button, ButtonGroup, Card, styled, TextField } from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';
import { StyledCloseIcon } from 'src/pages/FileSearch/FileSearchBar/styled';
import { ViewMode } from 'src/utilities/hooks/usePreferences';

const StyledButton = styled(Button)<{ selected: boolean }>`
  ${({ selected, theme }) => selected && `background-color: ${theme.palette.grey[400]};`}
  ${({ selected, theme }) =>
    selected && `&:hover { background-color: ${theme.palette.grey[400]}; }`}
  ${({ selected, theme }) => !selected && `color: ${theme.palette.grey[500]};`}
`;

type FileSearchBarProps = {
  viewMode: ViewMode;
  onViewModeChange: (viewMode: ViewMode) => void;
  searchTerm: string;
  onChangeSearchTerm: (searchTerm: string) => void;
  onToggleIsFilterOpen: () => void;
};

function FileSearchBar({
  onChangeSearchTerm,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onToggleIsFilterOpen,
  onViewModeChange,
  searchTerm,
  viewMode,
}: FileSearchBarProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  function handleViewModeChange(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const viewMode = event.currentTarget.value as ViewMode;

    onViewModeChange(viewMode);
  }

  function handleSearchTermChange(event: React.ChangeEvent<HTMLInputElement>) {
    const searchTerm = event.currentTarget.value;

    onChangeSearchTerm(searchTerm);
  }

  function handleClearSearchTerm(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();

    onChangeSearchTerm('');

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" margin={2}>
        <Box display="flex" width={378}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment:
                searchTerm !== '' ? (
                  <StyledCloseIcon code="close" onClick={handleClearSearchTerm} />
                ) : undefined,
              startAdornment: <WaveIcon code="search" />,
            }}
            inputRef={inputRef}
            label={<Trans i18nKey="file-ser.search">Search</Trans>}
            name="searchTerm"
            onChange={handleSearchTermChange}
            placeholder=""
            size="small"
            value={searchTerm}
          />

          {/* 
          @TODO: Implement filter functionality in phase 2
          <Box marginLeft={2}>
            <Button onClick={onToggleIsFilterOpen} startIcon={<WaveIcon code="filter-list" />}>
              Filter
            </Button>
          </Box>
          */}
        </Box>

        <ButtonGroup size="small" variant="outlined">
          <StyledButton
            onClick={handleViewModeChange}
            selected={viewMode === 'compact'}
            value="compact"
          >
            <WaveIcon code="view-compact" />
          </StyledButton>

          <StyledButton onClick={handleViewModeChange} selected={viewMode === 'grid'} value="grid">
            <WaveIcon code="view-module" />
          </StyledButton>

          <StyledButton onClick={handleViewModeChange} selected={viewMode === 'list'} value="list">
            <WaveIcon code="view-list" />
          </StyledButton>
        </ButtonGroup>
      </Box>
    </Card>
  );
}

const MemoizedFileSearchBar = memo(FileSearchBar);

export { MemoizedFileSearchBar as FileSearchBar };
