import { forwardRef, memo, Ref, RefAttributes } from 'react';

import { Unstable_Grid2 as Grid } from '@mui/material';

import { FieldMapper } from 'src/components/FieldMapper';
import { StyledGridContainer } from 'src/features/JobForm/components/TableFieldsRowSection/styled';
import { FieldsTransformed } from 'src/pages/Job/Job.service';
import { Table } from 'src/pages/Job/JobContext';

type TableFieldsRowSectionProps = {
  table: Table;
  fields?: FieldsTransformed;
  language: string;
  isFixedFields?: boolean;
  fieldValueIndex?: number;
  isNewBlockRow?: boolean;
} & RefAttributes<HTMLDivElement>;

function TableFieldsRowSection(
  {
    fieldValueIndex,
    fields,
    isFixedFields = false,
    isNewBlockRow,
    language,
    table,
  }: TableFieldsRowSectionProps,
  ref: Ref<HTMLDivElement>,
) {
  const translatedNameField = `name_${language}`;
  const translatedDescriptionField = `desc_${language}`;

  const fieldTypeProperty = isFixedFields ? 'fixedFields' : 'scrollableFields';

  return (
    <StyledGridContainer
      container
      isFixedFields={isFixedFields}
      isNewBlockRow={isNewBlockRow}
      ref={ref}
      xs
    >
      {table.rows.map((row) => (
        <Grid alignItems="center" container key={row.title} xs="auto">
          {row[fieldTypeProperty].map((fieldAlias) => {
            const numberRegex = /\s*\d+\s*(\S*)$/;
            const jobField = fields?.[fieldAlias];
            const name = jobField?.[translatedNameField as any] || '';
            const description = jobField?.[translatedDescriptionField as any] || '';
            const indexedName =
              name.replace(numberRegex, '').trim() + ` ${(fieldValueIndex ?? 0) + 1}`;

            if (jobField?.isHidden) return null;

            return (
              <Grid key={fieldAlias} margin={1} width="223px">
                <FieldMapper
                  alias={fieldAlias}
                  description={description}
                  fieldData={jobField?.fieldData}
                  fieldValueIndex={fieldValueIndex}
                  isDisabled={jobField?.isDisabled || false}
                  isError={jobField?.isError || false}
                  isRequired={jobField?.isRequired}
                  name={isNewBlockRow ? name : indexedName}
                  type={jobField?.type || 'string'}
                />
              </Grid>
            );
          })}
        </Grid>
      ))}
    </StyledGridContainer>
  );
}

const ForwardRefTableFieldsRowSection = forwardRef(TableFieldsRowSection);
const MemoizedTableFieldsRowSection = memo(ForwardRefTableFieldsRowSection);

export { MemoizedTableFieldsRowSection as TableFieldsRowSection };
