import { Avatar, Badge, IconButton, Typography } from '@mui/material';

import { Dialog } from 'src/features/EditableAvatar/components/Dialog';
import { useEditableAvatar } from 'src/features/EditableAvatar/useEditableAvatar';
import { WaveIcon } from 'src/features/WaveIcon';

const avatarDimensions = { height: 120, width: 120 };

export type EditableAvatarProps = {
  backgroundColor: string;
  iconCode: string;
  iconColor: string;
  isSubmitting?: boolean;
  newAvatar?: string;
  shouldDeleteAvatar?: boolean;
  onClick?: (isAvatarSaved: boolean) => void;
};

export function EditableAvatar({
  backgroundColor,
  iconCode,
  iconColor,
  isSubmitting,
  newAvatar,
  onClick,
  shouldDeleteAvatar,
}: EditableAvatarProps) {
  const {
    avatarUrl,
    contrastText,
    filler,
    handleClickAvatarIcon,
    handleCloseDialog,
    isDeleteIcon,
    isDialogOpen,
    userInitials,
  } = useEditableAvatar({ iconCode, onClick });

  return (
    <>
      <Badge
        {...((!isDeleteIcon ||
          (isDeleteIcon && ((!shouldDeleteAvatar && avatarUrl) || newAvatar))) && {
          badgeContent: (
            <IconButton
              disabled={isSubmitting}
              onClick={handleClickAvatarIcon}
              sx={{
                ':hover': { bgcolor: iconColor, color: backgroundColor },
                bgcolor: backgroundColor,
                color: iconColor,
              }}
            >
              <WaveIcon code={iconCode} fontSize="small" />
            </IconButton>
          ),
          overlap: 'circular',
        })}
      >
        <Avatar
          alt="User Avatar"
          src={
            shouldDeleteAvatar && !newAvatar
              ? ''
              : newAvatar || `${import.meta.env.VITE_API}${avatarUrl}`
          }
          sx={{ bgcolor: filler, ...avatarDimensions }}
        >
          <Typography color={contrastText} component="span" variant="h3">
            {userInitials}
          </Typography>
        </Avatar>
      </Badge>

      <Dialog isDialogOpen={isDialogOpen} onCloseDialog={handleCloseDialog} />
    </>
  );
}
