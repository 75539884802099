import { Trans } from 'react-i18next';

import { useLocation, useNavigate } from 'react-router-dom';

import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';
import { useGetRightsQuery } from 'src/store/user.service';

type SupportMenuProps = { isOpen: boolean };

const unprotectedMenuItem = 'hom-fil';
const menuItems = [
  {
    code: 'help',
    icon: <WaveIcon code="help" />,
    link: '/general-help',
    name: 'Get Help',
  },
  {
    code: 'hom-fil',
    icon: <WaveIcon code="library-books" />,
    link: '/general-files',
    name: 'General Files',
  },
  {
    code: 'opt',
    icon: <WaveIcon code="settings" />,
    link: '/general-settings',
    name: 'Settings',
  },
];

export function SupportMenu({ isOpen }: SupportMenuProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const readRights = useGetRightsQuery().data?.read;

  function handleClickSupportMenuItem(link: string, name: string, code: string) {
    navigate(link, { state: { code, name } });
  }

  return (
    <List>
      {menuItems.map(({ code, icon, link, name }) => {
        if (!readRights?.includes(code) && code !== unprotectedMenuItem) return null;

        const backgroundColor = !isOpen
          ? 'primary.main'
          : pathname === link
          ? 'secondary.main'
          : 'filler.main';

        return (
          <ListItemButton
            key={code}
            onClick={() => handleClickSupportMenuItem(link, name, code)}
            sx={{
              '&:hover': {
                bgcolor: backgroundColor,
              },
              bgcolor: backgroundColor,
            }}
          >
            <ListItemIcon
              sx={{
                color: isOpen ? 'primary.main' : 'secondary.main',
                ...(!isOpen && { minWidth: 0 }),
              }}
            >
              {icon}
            </ListItemIcon>

            <Trans>{isOpen ? <ListItemText primary={name} /> : null}</Trans>
          </ListItemButton>
        );
      })}
    </List>
  );
}
