import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { NavBar } from 'src/components/NavBar';
import { Spinner } from 'src/components/Spinner';
import { useAuthenticateExternalRevisorQuery } from 'src/features/ExternalRevisor/ExternalRevisor.service';
import { Job } from 'src/pages/Job';
import { Viewer } from 'src/pages/Viewer';
import { saveTokens } from 'src/utilities/auth';
import { useIsExternalRevisor, useUser } from 'src/utilities/hooks';

function hasQueryParams(search: string) {
  return new URLSearchParams(search).toString() !== '';
}

export function ExternalRevisor() {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const isExternalRevisor = useIsExternalRevisor();
  const age = isExternalRevisor ? path[3].split('-')[1] : path[1].split('-')[1];
  const jobId = isExternalRevisor ? path[4] : path[2];
  const jobType = isExternalRevisor ? path[3].split('-')[2] : path[1].split('-')[2];
  const tab = isExternalRevisor ? path[5] : path[3];
  const token = isExternalRevisor ? path[2] : '';

  const {
    data: tokens,
    isError,
    isSuccess,
  } = useAuthenticateExternalRevisorQuery(
    {
      age,
      jobId,
      jobType,
      token,
    },
    { skip: !isExternalRevisor && tab !== 'external-viewer' },
  );

  if (isError) navigate('/login');

  if (isSuccess) saveTokens(tokens);

  const userDetails = useUser({ skip: !isSuccess });

  return !userDetails ? (
    <Spinner />
  ) : (
    <Box height={'calc(100vh - 288px)'}>
      {hasQueryParams(location.search) ? (
        <Viewer />
      ) : (
        <>
          <NavBar />

          <Job />
        </>
      )}
    </Box>
  );
}
