import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldSelectorProps } from 'src/features/JobsTable/components/FieldSelector/FieldSelector';
import { FieldTransformed } from 'src/pages/Job/Job.service';
import { changeArrayItemPosition } from 'src/utilities/helperFunctions';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions2';
import { useFirstRender } from 'src/utilities/hooks';

export type Indexes = number[];
type UseFieldSelectorProps = Omit<FieldSelectorProps, 'isOpen' | 'type'>;

export function useFieldSelector({
  fieldPreference,
  onApplyFieldSelector,
  onCloseFieldSelector,
  savedAvailableFields,
  savedSelectedFields,
}: UseFieldSelectorProps) {
  const isFirstRender = useFirstRender();
  const [availableFields, setAvailableFields] = useState(savedAvailableFields || []);
  const [selectedFields, setSelectedFields] = useState(savedSelectedFields || []);
  const [checkedAvailableFields, setCheckedAvailableFields] = useState<FieldTransformed[]>([]);
  const [indexesOfCheckedSelectedFields, setIndexesOfCheckedSelectedFields] = useState<Indexes>([]);
  const [searchValue, setSearchValue] = useState('');

  const areAvailableFieldsChecked = !!checkedAvailableFields.length;
  const areSelectedFieldsChecked = !!indexesOfCheckedSelectedFields.length;
  const haveSelectedFieldsChanged =
    savedSelectedFields?.length !== selectedFields.length ||
    savedSelectedFields?.some(
      (savedField, index) => savedField.alias !== selectedFields?.[index]?.alias,
    );
  const filteredFields = availableFields.filter(
    (field) =>
      !selectedFields.includes(field) &&
      field.name.toLowerCase().includes(searchValue.toLowerCase()),
  );
  const { t: translate } = useTranslation();

  const codePrefix = 'jobs-field-selector' as const;

  function handleChangeSearchField({
    target: { value: searchValue },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchValue(searchValue);
  }

  function handleClickAdd() {
    // Remove checkedAvailableFields from availableFields
    // and add to selectedFields while remaining checked.
    setAvailableFields((previousAvailableFields) =>
      previousAvailableFields.filter((field) => !checkedAvailableFields.includes(field)),
    );
    setCheckedAvailableFields([]);
    setIndexesOfCheckedSelectedFields(
      checkedAvailableFields.map((_, index) => selectedFields.length + index),
    );
    setSelectedFields((previousSelectedFields) => [
      ...previousSelectedFields,
      ...checkedAvailableFields,
    ]);
  }

  function handleClickRemove() {
    // Remove checkedSelectedFields from selectedFields and add to availableFields.
    const checkedSelectedFields = indexesOfCheckedSelectedFields.map(
      (index) => selectedFields[index],
    );

    setAvailableFields((previousAvailableFields) =>
      sortArrayOfObjectsByKey([...previousAvailableFields, ...checkedSelectedFields], 'name'),
    );
    setSelectedFields((previousSelectedFields) =>
      previousSelectedFields.filter((field) => !checkedSelectedFields.includes(field)),
    );
    setIndexesOfCheckedSelectedFields([]);
  }

  function handleClickDown() {
    changeCheckedSelectedFieldsPositions('down');
  }

  function handleClickUp() {
    changeCheckedSelectedFieldsPositions('up');
  }

  function changeCheckedSelectedFieldsPositions(direction: 'down' | 'up') {
    const _indexesOfCheckedSelectedFields: Indexes = [];
    const _selectedFields = [...selectedFields];

    indexesOfCheckedSelectedFields.forEach((index) => {
      const newPosition = direction === 'down' ? index + 1 : index - 1;

      _indexesOfCheckedSelectedFields.push(newPosition);
      changeArrayItemPosition({
        array: _selectedFields,
        newPosition,
        oldPosition: index,
      });
    });

    setIndexesOfCheckedSelectedFields(_indexesOfCheckedSelectedFields);
    setSelectedFields(_selectedFields);
  }

  function handleReset() {
    setAvailableFields(savedAvailableFields || []);
    setSelectedFields(savedSelectedFields || []);
    setCheckedAvailableFields([]);
    setIndexesOfCheckedSelectedFields([]);
    setSearchValue('');
  }

  function handleClickApply() {
    onApplyFieldSelector(selectedFields);
  }

  function handleClose() {
    if (!fieldPreference.isUpdating) {
      handleReset();
      onCloseFieldSelector();
    }
  }

  useEffect(() => {
    if (!isFirstRender) handleClose();
  }, [fieldPreference.value]);

  useEffect(() => {
    setAvailableFields(savedAvailableFields || []);
    setSelectedFields(savedSelectedFields || []);
  }, [savedAvailableFields, savedSelectedFields]);

  return {
    areAvailableFieldsChecked,
    areSelectedFieldsChecked,
    availableFields: filteredFields,
    checkedAvailableFields,
    codePrefix,
    handleChangeSearchField,
    handleClickAdd,
    handleClickApply,
    handleClickDown,
    handleClickRemove,
    handleClickUp,
    handleClose,
    handleReset,
    haveSelectedFieldsChanged,
    indexesOfCheckedSelectedFields,
    searchValue,
    selectedFields,
    setCheckedAvailableFields,
    setIndexesOfCheckedSelectedFields,
    translate,
  };
}
