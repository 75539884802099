import { useEffect } from 'react';

import { isEqual } from 'lodash';

import { PropsOfActiveFiltersOfJobs } from 'src/components/ActiveFiltersOfJobs/ActiveFiltersOfJobs';
import { usePreferencePrefix, usePreferences } from 'src/utilities/hooks';
import { ActiveFilter, Preference } from 'src/utilities/hooks/usePreferences';

type PropsOfUseActiveFiltersOfJobs = Pick<PropsOfActiveFiltersOfJobs, 'setAreActiveFiltersVisible'>;

export function useActiveFiltersOfJobs(props?: PropsOfUseActiveFiltersOfJobs) {
  const { jobType, preferencePrefix } = usePreferencePrefix();
  const activeFiltersPreference = usePreferences({
    code: `${preferencePrefix}.search`,
    defaultValue: [],
  }) as Omit<Preference, 'value'> & { value: ActiveFilter[] };
  const pagePreference = usePreferences({
    code: `${preferencePrefix}.listPage`,
    defaultValue: '0',
  });

  function handleDeleteActiveFilter(deleteFilter: ActiveFilter) {
    const filteredActiveFilters = activeFiltersPreference.value.filter(
      (activeFilter) => !isEqual(activeFilter, deleteFilter),
    );

    activeFiltersPreference.set(filteredActiveFilters);
    pagePreference.set('0');
  }

  function handleDeleteAllActiveFilters() {
    activeFiltersPreference.set([]);
    pagePreference.set('0');
  }

  useEffect(() => {
    props?.setAreActiveFiltersVisible(true);
  }, [activeFiltersPreference.value, jobType]);

  return { activeFiltersPreference, handleDeleteActiveFilter, handleDeleteAllActiveFilters };
}
