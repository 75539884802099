import { Grid, Skeleton, useTheme } from '@mui/material';
import { pieArcLabelClasses, PieChart as MuiPieChart } from '@mui/x-charts';

import { JobTask } from 'src/features/JobsTable/utilities/hooks/useJobs';
import { ChartTitle } from '../ChartTitle';
import { ChartTooltip } from '../ChartTooltip';
import { NothingToShow } from '../NothingToShow';

type PieChartProps = {
  isLoading: boolean;
  pieces: JobTask[];
  title: string;
};

export function PieChart({ isLoading, pieces, title }: PieChartProps) {
  let colors: string[] = [];
  const theme = useTheme();
  const colorsMap: { [key: string]: string } = {
    due: theme.palette.warning.main,
    late: theme.palette.error.main,
    pending: theme.palette.success.main,
  };
  let labels: string[] = [];
  let total = 0;
  let totals: number[] = [];

  const data: any[] = [];

  pieces.forEach((piece: any, index: number) => {
    colors = [...colors, colorsMap[piece.code]];
    labels = [...labels, piece.label];
    total += piece.total;
    totals = [...totals, piece.total];
    data.push({
      color: colorsMap[piece.code],
      id: index + 1,
      label: piece.label,
      value: piece.total,
    });
  });

  if (isLoading) {
    return (
      <Grid item>
        <Skeleton className="m-auto" height={100} variant="circular" width={100} />
      </Grid>
    );
  }

  if (!pieces.length) {
    return <NothingToShow title={title} />;
  }

  return (
    <Grid alignItems="center" container direction="column" item wrap="nowrap">
      <ChartTitle title={`${title}: ${total}`} />

      <Grid item>
        <MuiPieChart
          height={150}
          margin={{ bottom: 42, left: 0, right: 0, top: 10 }}
          series={[
            {
              arcLabel: (item) => String(item.value),
              data,
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              itemGap: 10,
              itemMarkHeight: 11,
              itemMarkWidth: 15,
              labelStyle: {
                fontSize: '14px',
              },
              markGap: 7,
              position: {
                horizontal: 'middle',
                vertical: 'bottom',
              },
            },
          }}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontSize: '12px',
            },
          }}
          tooltip={{
            itemContent: (props) => <ChartTooltip items={data} {...props} />,
            trigger: 'item',
          }}
          width={300}
        />
      </Grid>
    </Grid>
  );
}
