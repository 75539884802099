import { useNavigate } from 'react-router-dom';

import { API } from 'src/utilities/api';
import { useIsExternalRevisor, usePreferences, useRouteParams } from 'src/utilities/hooks';

import { RowJobType } from 'src/features/JobsTable/types';

export function useViewer() {
  const { age, jobId, jobType, tab } = useRouteParams();

  const navigate = useNavigate();

  const isExternalRevisor = useIsExternalRevisor();
  const pathname = window.location.pathname;
  const token = pathname.split('/')[2];

  const viewerPreference = usePreferences({ code: 'pdf-viewer.open', defaultValue: '_blank' });

  type TProps = {
    fileId?: number | number[];
    fileJobID?: number | string;
    compareFile?: boolean;
    rowJobType?: RowJobType | string;
    type?: string;
  };

  async function openViewerPage({
    compareFile,
    fileId,
    fileJobID,
    rowJobType,
    type = 'cloudflow',
  }: TProps) {
    if (type !== 'cloudflow' && type !== 'dalim') {
      return;
    }

    const queryParams = new URLSearchParams({
      age: age === 'hom' ? 'dash' : '',
      compareFile: compareFile?.toString() || 'false',
      fileId: fileId?.toString() || '',
      tab: tab || '',
      type,
    });

    let url = '';

    if (isExternalRevisor) {
      url = `${window.location.origin}/external-revisor/${token}/jobs-${age}-${
        rowJobType || jobType
      }/${jobId ? jobId : fileJobID}?${queryParams}`;
    } else {
      url = `/jobs-${age}-${rowJobType || jobType}/${
        jobId ? jobId : fileJobID
      }/viewer?${queryParams}`;
    }

    if (type === 'dalim') {
      const queryString = generateQueryString(fileId, fileJobID, type);
      const baseUrl = compareFile ? '/jobs/files/compare' : '/jobs/files/view';
      const result = await API.get(`${baseUrl}?${queryString}`);

      const viewType = result?.data?.data?.view_type || 'self';

      if (viewType === 'self') {
        navigate(url);
      } else {
        window.open(url, `${viewType === 'window' ? 'popup' : '_blank'}`);
      }
    } else {
      if (viewerPreference.value === 'self') {
        navigate(url);
      } else {
        window.open(url, `${viewerPreference.value === 'window' ? 'popup' : '_blank'}`);
      }
    }
  }

  function generateQueryString(
    fileId: number | number[] | undefined,
    jobid: number | string | undefined,
    sub: string,
  ) {
    const params = {
      age,
      file_id: fileId,
      jobid,
      src: jobType,
      sub,
    };

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        value.forEach((val: number) => searchParams.append(`${key}[]`, String(val)));
      } else {
        searchParams.append(key, value as string);
      }
    }

    return searchParams.toString();
  }

  return {
    openViewerPage,
  };
}
