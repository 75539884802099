import { useMemo } from 'react';

import { t } from 'i18next';

import { Avatar, Box, Button, Typography } from '@mui/material';

import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { Option } from 'src/components/RHF/Dropdown/Dropdown';
import { useUserMenu } from 'src/features/Frame/components/UserMenu/useUserMenu';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';
import { workflowApi } from 'src/features/Workflow/Workflow.service';
import { userApi } from 'src/store/user.service';
import { saveTokens } from 'src/utilities/auth';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions2';

export function UserMenu() {
  const {
    anchorEl,
    anchorElWidth,
    avatarUrl,
    clientIdPreferenceValue,
    clients,
    control,
    department,
    dispatch,
    firstName,
    handleCloseSubMenu,
    handleLogout,
    handleOpenSubMenu,
    isClientsLoading,
    isDirty,
    isSubMenuOpen,
    lastName,
    navigate,
    reset,
    updateActiveClient,
  } = useUserMenu();
  const userItems = useMemo(() => {
    return [
      {
        code: 'profile',
        icon: <WaveIcon code="account-circle" color="primary" />,
        onClick: () => {
          handleCloseSubMenu();
          navigate('/user-profile', {
            state: { code: 'user_menu_profile_button', name: 'User Profile' },
          });
        },
        text: 'user_menu_profile_button',
      },
      {
        code: 'settings',
        icon: <WaveIcon code="settings" color="primary" />,
        onClick: () => {
          handleCloseSubMenu();
          navigate('/user-settings', {
            state: { code: 'user_menu_settings_button', name: 'User Settings' },
          });
        },
        text: 'user_menu_settings_button',
      },
      {
        code: 'switchClient',
        icon: <WaveIcon code="business" color="primary" />,
        onClick: () => {
          return;
        },
        text: (
          <Autocomplete
            control={control}
            disableClearable
            isFetching={isDirty}
            label={t('user_menu_active_client_button', 'Active Client')}
            loading={isClientsLoading}
            name={'activeClient'}
            onChange={async (e, value) => {
              const clientId = (value as Option).value;

              await updateActiveClient({ clientId })
                .unwrap()
                .then(async (data) => {
                  saveTokens(data);
                  dispatch(userApi.util.invalidateTags(['menu', 'preferences', 'rights', 'user']));
                  dispatch(workflowApi.util.invalidateTags(['Workflow']));
                  handleCloseSubMenu();
                  navigate('/jobs-hom-dash');
                  reset({
                    activeClient: {
                      label: clients[clientId],
                      value: clientId,
                    },
                  });
                })
                .catch(() => {
                  reset();
                });
            }}
            options={sortArrayOfObjectsByKey(
              Object.entries(clients).map(([clientId, clientName]) => ({
                label: clientName,
                value: clientId,
              })),
              'label',
            )}
            type="select"
          />
        ),
      },
      {
        code: 'logout',
        icon: <WaveIcon code="exit-app" color="primary" />,
        onClick: handleLogout,
        text: 'user_menu_logout_button',
      },
    ];
  }, [clientIdPreferenceValue, isDirty, isClientsLoading]);

  return (
    <>
      <Button onClick={handleOpenSubMenu} sx={{ display: 'flex', gap: 1 }}>
        <Avatar
          alt="User Avatar"
          src={`${import.meta.env.VITE_API}${avatarUrl}`}
          sx={{ bgcolor: 'filler.main' }}
        >
          <Typography color="filler.contrastText" variant="inherit">
            {`${firstName?.charAt(0)}${lastName?.charAt(0)}`}
          </Typography>
        </Avatar>

        <Box
          sx={{
            color: 'primary.contrastText',
            lineHeight: 1,
            textAlign: 'left',
            textTransform: 'none',
          }}
        >
          <Typography fontWeight="bold">{`${firstName} ${lastName}`}</Typography>

          <Typography variant="caption">{department}</Typography>
        </Box>

        <WaveIcon
          code="expand-more"
          fontSize="small"
          sx={{
            color: 'primary.contrastText',
          }}
        />
      </Button>

      <SubMenu
        anchorEl={anchorEl}
        anchorElMinWidth={anchorElWidth}
        className="filler-bg user-popover"
        items={userItems}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
      />
    </>
  );
}
