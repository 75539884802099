import {
  isCheckbox,
  isDateField,
  isNumber,
  isProgressBar,
  isSelectField,
  isUploadFiles,
} from 'src/components/FieldMapper/utilities/helperFunctions';
import { DEFAULT_VALUE } from 'src/components/RHF/DateField/utilities/constants';
import {
  Field,
  Fields,
  FieldsTransformed,
  FieldType,
  FieldValues,
  JobValues,
} from 'src/pages/Job/Job.service';
import { Block, FieldLayoutField, FieldLayouts, Section, Table } from 'src/pages/Job/JobContext';
import { isObject, sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions2';

export function isFieldValues(values: any): values is FieldValues {
  if (!isObject(values) || values === null) return false;

  const hasFlagsAndTasks = 'flags' in values && 'tasks' in values;

  return hasFlagsAndTasks;
}

function getTableRowDefaultValues(table: Table | undefined, values: FieldValues): FieldValues {
  const defaultValues: FieldValues = {};

  table?.rows.forEach(({ fixedFields, scrollableFields }) => {
    fixedFields.concat(scrollableFields).forEach((alias) => {
      defaultValues[alias] = values[alias];
    });
  });

  return defaultValues;
}

function getFieldDefaultValues(
  fields: FieldLayoutField[] | undefined,
  rtkQueryFields: FieldsTransformed,
  values: FieldValues,
): FieldValues {
  const defaultValues: FieldValues = {};

  fields?.forEach(({ alias }) => {
    const type = rtkQueryFields?.[alias]?.type ?? 'string';

    defaultValues[alias] = values[alias] ?? computeDefaultFieldValueByType(type);
  });

  return defaultValues;
}

function getBlockFieldDefaultValues(
  blocks: Array<Block> | undefined,
  values: FieldValues,
): FieldValues {
  const defaultValues: FieldValues = {};

  blocks?.forEach((block) => {
    block.fields?.forEach(({ alias }) => {
      defaultValues[alias] = values[alias];
    });
  });

  return defaultValues;
}

function getBlockTableRowDefaultValues(
  blocks: Array<Block> | undefined,
  values: FieldValues,
): FieldValues {
  const defaultValues: FieldValues = {};

  blocks?.forEach((block) => {
    block.table?.rows?.forEach(({ fixedFields, scrollableFields }) => {
      fixedFields.concat(scrollableFields).forEach((alias) => {
        defaultValues[alias] = values[alias];
      });
    });
  });

  return defaultValues;
}

export function getSectionValues(
  sections: Section[],
  rtkQueryFields: FieldsTransformed,
  values: JobValues,
): FieldValues {
  if (!isFieldValues(values)) return {};

  return sections.reduce((accumulatedValues, section) => {
    const { blocks, fields, sections: nestedSections, table } = section;

    const tableRowDefaults = getTableRowDefaultValues(table, values);
    const fieldDefaults = getFieldDefaultValues(fields, rtkQueryFields, values);
    const blockFieldDefaults = getBlockFieldDefaultValues(blocks, values);
    const blockTableRowDefaults = getBlockTableRowDefaultValues(section?.blocks, values);
    const nestedDefaults = nestedSections
      ? getSectionValues(nestedSections, rtkQueryFields, values)
      : {};

    return {
      ...accumulatedValues,
      ...tableRowDefaults,
      ...fieldDefaults,
      ...blockFieldDefaults,
      ...blockTableRowDefaults,
      ...nestedDefaults,
    };
  }, {});
}

export function getDefaultValues(
  fieldLayouts: FieldLayouts,
  rtkQueryFields: FieldsTransformed,
  values?: JobValues,
): FieldValues {
  if (!isFieldValues(values)) return {};

  return Object.values(fieldLayouts).reduce((accumulatedValues, fieldLayout) => {
    const sectionValues = getSectionValues(fieldLayout.sections, rtkQueryFields, values);

    return {
      ...accumulatedValues,
      ...sectionValues,
    };
  }, {});
}

export function computeDefaultFieldValueByType(fieldType: FieldType) {
  if (isSelectField(fieldType)) return null;

  if (isCheckbox(fieldType)) return false;

  if (isNumber(fieldType) || isProgressBar(fieldType)) return 0;

  if (isDateField(fieldType)) return DEFAULT_VALUE;

  return '';
}

export function transformFields(fields: Fields) {
  const fieldsTransformed = {} as FieldsTransformed;

  for (const alias in fields) {
    const field = fields[alias];

    if (field) fieldsTransformed[alias] = transformField(field);
  }

  return fieldsTransformed;
}

function transformField(field: Field) {
  const {
    attr: { class: classes },
    desc_en: description,
    feature: { SteerAlias: children, labelled_text: labelledText, parents, text },
    field_data: fieldData,
    is_disable: isDisabled,
    is_error: isError,
    is_hidden: isHidden,
    is_mandatory: isRequired,
    param,
    typ: type,
    ...restOfField
  } = field;

  return {
    attributes: { classes },
    description,
    fieldData:
      isUploadFiles(type) ||
      type === 'newpick' ||
      type === 'pickselect' ||
      Array.isArray(fieldData) ||
      !fieldData
        ? fieldData
        : sortArrayOfObjectsByKey(
            Object.entries(fieldData)
              .filter(([value]) => value !== '0')
              .map(([value, label]) => ({
                label,
                value,
              })),
            'label',
          ),
    isDisabled,
    isError,
    isHidden,
    isLabelledText: Boolean(labelledText),
    isRawText: Boolean(text),
    isRequired,
    param,
    steer: { children, parents },
    type,
    ...restOfField,
  };
}
