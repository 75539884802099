import { ChangeEvent, MouseEvent } from 'react';

import { ToolbarSharedProps } from 'src/features/JobsTable/components/Toolbar/Toolbar';
import {
  usePagePreference,
  usePreferenceAsNumber,
  usePreferencePrefix,
  usePreferences,
} from 'src/utilities/hooks';

type UseToolbarProps = ToolbarSharedProps;

export function useToolbar({ setPageHistory, setSelectedRows, totalRowCount }: UseToolbarProps) {
  const { preferencePrefix } = usePreferencePrefix();

  const activeFiltersPreference = usePreferences({
    code: `${preferencePrefix}.search`,
    defaultValue: [],
  });
  const { set: setPagePreference, value: page } = usePagePreference({
    preferencePrefix,
    totalRowCount,
  });
  const { set: setRowsPerPagePreference, value: rowsPerPage } = usePreferenceAsNumber({
    code: `${preferencePrefix}.rowsPerPage`,
    defaultValue: '25',
  });

  function handleChangePage(event: MouseEvent<HTMLButtonElement> | null, page: number) {
    setPagePreference(page.toString());

    if (setSelectedRows) setSelectedRows([]);

    if (!activeFiltersPreference.value.length) setPageHistory(page);
  }

  function handleChangeRowsPerPage({
    target: { value: rowsPerPage },
  }: ChangeEvent<HTMLInputElement>) {
    setRowsPerPagePreference(rowsPerPage);
    setPagePreference('0');

    if (setSelectedRows) setSelectedRows([]);

    if (!activeFiltersPreference.value.length) setPageHistory(0);
  }

  return { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage };
}
