import { CSSProperties } from 'react';

import i18n from 'i18next';

import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { FieldSelectorFieldWellProps } from 'src/features/JobsTable/components/FieldSelectorFieldWell/FieldSelectorFieldWell';
import { isNumber } from 'src/utilities/helperFunctions2';

type FieldSelectorField = {
  data: FieldSelectorFieldWellProps;
  index: number;
  style: CSSProperties;
};

export function FieldSelectorField({
  data: {
    checkedFields,
    fieldPreference,
    fields,
    setCheckedAvailableFields,
    setIndexesOfCheckedSelectedFields,
    type,
  },
  index,
  style,
}: FieldSelectorField) {
  const field = fields[index];
  const name = field[`name_${i18n.language}`] || field.name;
  const isChecked: boolean = checkedFields.some((checkedField) =>
    isNumber(checkedField) ? checkedField === index : checkedField.alias === field.alias,
  );

  function handleToggleField() {
    if (type === 'selected' && setIndexesOfCheckedSelectedFields) {
      setIndexesOfCheckedSelectedFields((previousCheckedFields) =>
        isChecked
          ? previousCheckedFields.filter((previousCheckedField) => previousCheckedField !== index)
          : [...previousCheckedFields, index],
      );
    } else if (type === 'available' && setCheckedAvailableFields) {
      setCheckedAvailableFields((previousCheckedFields) =>
        isChecked
          ? previousCheckedFields.filter(
              (previousCheckedField) => previousCheckedField.alias !== field.alias,
            )
          : [...previousCheckedFields, field],
      );
    }
  }

  return (
    <ListItem dense onClick={handleToggleField} style={style}>
      <ListItemIcon>
        <Checkbox checked={isChecked} disabled={fieldPreference.isUpdating} />
      </ListItemIcon>

      <ListItemText primary={name} />
    </ListItem>
  );
}
